import React from "react";
import "../Utils/assets/pageNotFound.scss";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const PageNotFound = () => {
  return (
    <>
      <div
        className="page-not-found"
        style={{ backgroundImage: "url('/assets/imgs/404/background.jpg')" }}
      >
        <div className="inner-content">
          <Card sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <CardMedia
                  component="img"
                  image="/assets/imgs/sgLogo.jpg"
                  alt="logo"
                  sx={{
                    width: 200,
                    display: "inline-flex",
                    verticalAlign: "middle",
                  }}
                  className="logo"
                />{" "}
                {/* Blue Ribbon below header */}
                <Typography component="div" className="project-name">
                  <Typography component="span">{"BIM LIBRARY"}</Typography>
                </Typography>
                <Typography component="h2" className="section-heading text-center">
                  Page Not Found
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  We are sorry, the page you requested could not be found.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Please go back to Homepage.
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: 1,
                  pb: 1,
                  justifyContent: "center",
                }}
              >
                <Link href="/">
                  <Button className="btn btn-primary">Go Home</Button>
                </Link>
              </Box>
            </Box>
            <CardMedia
              component="img"
              sx={{ width: 400 }}
              image="/assets/imgs/404/robot-pana.svg"
              alt="404"
            />
          </Card>
        </div>
      </div>
    </>
  );
};
