import { useState, useEffect, useContext } from "react";
import { sendMessageToContact } from "../../API/contact.api";
import { MyConfig } from "../../API/myConfig";
import { useTranslation } from "react-i18next";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import { encryptUserId } from "../../Utils/helper";
import { useParams, useSearchParams } from "react-router-dom";
import { GetRegionOid } from "../../API";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";
import React from "react";

export const useForm = (
  callback: any,
  contactId: string | null,
  validate: any
) => {
  const [values, setValues] = useState({
      fullName: "",
      email: "",
      phoneNumber: "",
      country: "",
      brand: "",
      company: "",
      subject: "",
      message: "",
      token: null,
    } as any);
  const [errors, setErrors] = useState({} as any);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n } = useTranslation("common");
  const [statusCode, setStatusCode] = useState(0);
  const [open, setOpen] = useState(false);
  const { locale, region } = useParams();
  const { regions } = useContext(CultureContext) as ICultureContextType;
  const queryParams = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const brId = queryParams.get("brandId");
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const coId = searchParams.get("coId");
  const brandId = searchParams.get("brandId");
  const regionId = searchParams.get("regionId");
  const disableBrandAndRegion =  brandId && brandId != 'undefined' && regionId && regionId != 'undefined';


  const resetValues = () => {
    setValues({
      //title: t("contact_page.mr"),
      fullName: "",
      email: "",
      phoneNumber: "",
      //address: "",
      //jobTitle: "",
      country:disableBrandAndRegion ? regionId : "",
      brand: disableBrandAndRegion ? brandId : "",
      company: "",
      subject: "",
      message: "",
      token: null,
    });
  };

  const handleSubmit = async (event: any, refg: any,brands:any) => {
    if (region) {
      const regId: any = await GetRegionOid(regions, region);
      let titleValue: any =
        values.title === undefined ? t("contact_page.mr") : values.title;
      if (event) event.preventDefault();
      let errorValue: any = {};
      errorValue = validate(values);
      setErrors(errorValue);
      setIsSubmitting(true);
      let brandData="";
      if(values.brand==="all"){
        brands=brands.filter((data:any)=>{
          return data.oid!="all"
        });
       brands.map((e:any)=>{
        brandData=brandData + e.oid+","
       })
       brandData=brandData.substring(0,brandData.length-1);
      }
      else{
        brandData=values.brand;
      }
      const mailData: any = {
        brandId: brandData,
        address: values.address,
        commercialContactId: "",
        company: values.company,
        countryId: values.country,
        emailAddress: values.email,
        fullName: values.fullName,
        jobTitle: values.jobTitle,
        message: values.message,
        phoneNumber: values.phoneNumber,
        subject: values.subject,
        title: titleValue,
        regionId: values.country,
      };

      //Message send to BIM
      // mailData.commercialContactId = "592d8be195fe130001aa4089";

      //Set commercial contact id inside mailData if exists
      // if (coId != null) {
      //   if (brId != null) {
      //     if (regId != null) {
      //       mailData.commercialContactId = coId;
      //       mailData.brandId = brId;
      //       mailData.regionId = regId;
      //     }
      //   }
      // }

      if (Object.keys(errorValue).length === 0) {
        const apiData: any = await sendMessageToContact(mailData);

        if (apiData.status === 200) {
          resetValues();
          setStatusCode(apiData.status);
          setOpen(true);
          let userId = encryptUserId(values.email);
          sendDataToGTM({
            event: "contact_sendMail",
            commercial_contact: contactId,
            region: region,
            locale: locale,
            user_id:
              localStorage.getItem("enableGA") === "true" ? userId : "NA",
          });
        } else {
          setStatusCode(apiData.status);
          setOpen(true);
        }
      } else {
      }
      refg.reset();
    }
  };

  const handleChange = (event: any) => {
    document.body.style.overflow = "auto";
    setValues((values: any) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };
  const handleToken = (token: any) => {
    setValues((values: any) => {
      return { ...values, token };
    });
  };
  const handleExpire = () => {
    setValues((values: any) => {
      return { ...values, token: null };
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };



  return {
    handleChange,
    handleSubmit,
    handleExpire,
    handleToken,
    handleClose,
    resetValues,
    open,
    values,
    errors,
    statusCode,
  };
};
