import axios from "axios";
import Cookies from "universal-cookie";
import { USER_BASKET } from "../Utils/const";
import { setCookieService } from "./cookie.api";
import { MyConfig } from "./myConfig";

export const createNewBasket = async (regionId: string) => {
  try {
    let config = await MyConfig();
    const response = await fetch(
      config.REACT_APP_API_URL +
        "/anonymous/basket/region/" +
        regionId +
        "/newId",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        return res.sessionId;
      })
      .catch((err: any) => {
        return null;
      });

    return response;
  } catch (ex: any) {
    return null;
  }
};

export const addAllProductsToMyBasket = async (
  basketToken: string,
  productId: string[],
  regionId: string
) => {
  let config = await MyConfig();
  if (productId.length > 0) {
    let productDto = { productIds: productId };
    const hostUrl =
      config.REACT_APP_API_URL +
      "/anonymous/basket/" +
      basketToken +
      "/region/" +
      regionId +
      "/addAll";
    return fetch(hostUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(productDto),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log("error is", err);
      });
  }
};

export const addAllProductFilesToMyBasket = async (
  basketToken: string,
  productIds: string[],
  regionId: string
) => {
  let config = await MyConfig();
  const hostUrl =
    config.REACT_APP_API_URL +
    "/anonymous/basket/" +
    basketToken +
    "/region/" +
    regionId +
    "/addAll";
  return fetch(hostUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ productIds: productIds }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log("error is", err);
    });
};

export const addProductToMyBasket = async (
  basketToken: string,
  productId: string,
  regionId: string
) => {
  try {
    let config = await MyConfig();
    if (productId.length > 0) {
      const response = await axios({
        method: "GET",
        url:
          config.REACT_APP_API_URL +
          "/anonymous/basket/" +
          basketToken +
          "/region/" +
          regionId +
          "/add/" +
          productId,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err: any) => {
          return null;
        });

      if (response) {
        if (response.sessionId) {
          return response.sessionId;
        }
      }
    }
    return null;
  } catch (ex: any) {
    return null;
  }
};

export const getMyDetailedBasket = async (
  basketToken: string,
  regionId: string
) => {
  const cookies = new Cookies();
  let config = await MyConfig();

  const response = await axios({
    method: "GET",
    url:
      config.REACT_APP_API_URL +
      "/anonymous/basket/" +
      basketToken +
      "/region/" +
      regionId +
      "/details",
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      return null;
    });

  if (response) {
    if (response.sessionId) {
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 100);
      cookies.set(USER_BASKET + regionId, response.sessionId, {
        path: "/",
        expires: date,
      });
      return response;
    }
  }
  return null;
};

/**
 * Remove product from basket and redux
 * @param basketToken basket token / sessionId
 * @param productId product id
 * @param regionId region id
 */
export const removeProductFromMyBasket = async (
  basketToken: string,
  productId: string,
  regionId: string
) => {
  try {
    let config = await MyConfig();

    const response = await axios({
      method: "GET",
      url:
        config.REACT_APP_API_URL +
        "/anonymous/basket/" +
        basketToken +
        "/region/" +
        regionId +
        "/remove/" +
        productId,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      return response.data;
    });
    return response;
  } catch (ex: any) {
    return null;
  }
};

export const removeAllProductFromMyBasket = async (
  basketToken: string,
  regionId: string
): Promise<string> => {
  try {
    let config = await MyConfig();

    const response = await axios({
      method: "GET",
      url:
        config.REACT_APP_API_URL +
        "/anonymous/basket/" +
        basketToken +
        "/region/" +
        regionId +
        "/removeAll",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      return response.data;
    });

    if (response) {
      if (response.sessionId) {
        setCookieService(
          `${USER_BASKET}_${regionId}`,
          24,
          JSON.stringify({
            sessionId: response.sessionId,
            products: [],
          })
        );
        return response.sessionId;
      }
    }

    return "";
  } catch (ex: any) {
    return "";
  }
};

export const removeFilesFromMyBasket = async (
  basketToken: string,
  regionId: string,
  reqBody: any
) => {
  try {
    let config = await MyConfig();

    const response = await axios({
      method: "DELETE",
      url:
        config.REACT_APP_API_URL +
        "/anonymous/basket/" +
        basketToken +
        "/region/" +
        regionId,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify(reqBody),
    }).then((response) => {
      return response.data;
    });
    return response;
  } catch (ex: any) {
    return null;
  }
};

export const removeMultiProductFromBasket = async (
  basketToken: string,
  regionId: string,
  productIds: string[]
): Promise<string> => {
  try {
    let config = await MyConfig();

    const response = await axios({
      method: "DELETE",
      url:
        config.REACT_APP_API_URL +
        "/anonymous/basket/" +
        basketToken +
        "/region/" +
        regionId +
        "/removeAll",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({ productIds: productIds }),
    }).then((response) => {
      return response.data;
    });

    if (response) {
      if (response.sessionId) {
        return response.sessionId;
      }
    }

    return "";
  } catch (ex: any) {
    return "";
  }
};
