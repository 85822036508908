import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, Typography, Link, Button } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import "../../Utils/assets/brandInfo.scss";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";
import { BrowseContext, IBrowseContextType } from "../../Context/browseContext";
import { useSearchParams } from "react-router-dom";

export const BrandInfo = () => {
  let [searchParams] = useSearchParams();
  const selectedBrand = searchParams.get("brand") || "all";
  const { loading, products } = useContext(BrowseContext) as IBrowseContextType;
  const { config } = useContext(CultureContext) as ICultureContextType;
  const [showMore, setShowMore] = useState<Boolean>(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const websiteClick = (link: string) => {
    window.open(link);
  };

  const addDefaultSrc = (ev: any) => {
    ev.target.src = "../assets/imgs/dummy-img.png";
  };

  if (loading) {
    return <></>;
  }

  if (products) {
    const brandInfo =
      products.brandList &&
      products.brandList.find((brn: any) => {
        return brn.label.toLowerCase() === selectedBrand.toLowerCase();
      });

    if (brandInfo && config) {
      if (brandInfo.pitch) {
        const convertedText: any = brandInfo.pitch.replace(/<[^>]+>/g, "");

        return (
          <React.Fragment>
            <Card
              className="brand-info"
              id="brandInfoCard"
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.12)",
                paddingBottom: "10px",
              }}
            >
              <CardContent className="text-left p-0">
                <div className="media">
                  <div className="media-left m-b-10 brandInfo-img-wrapper">
                    <img
                      src={`${config.REACT_APP_BIMDATABASE_API_URL}${brandInfo.thumbnailImage}`}
                      alt={brandInfo.label}
                      onError={addDefaultSrc}
                      loading="lazy"
                    />
                  </div>
                </div>
                <Typography
                  variant="body1"
                  component="div"
                  style={{ position: "relative" }}
                >
                  <span
                    style={{
                      color: " var(--color-grey) !important",
                      position: "relative",
                      marginBottom: "20px",
                      fontSize: ".8rem",
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    {showMore
                      ? convertedText
                      : `${convertedText?.substring(0, 320)}`}
                  </span>
                  {convertedText.length >= 300 ? (
                    <Button
                      id="showMoreBrandInfo"
                      onClick={handleShowMore}
                      style={{
                        position: "absolute",
                        bottom: "-15px",
                        right: "0px",
                        padding: "0",
                        margin: "0",
                        minWidth: "auto",
                        textAlign: "center",
                      }}
                    >
                      {showMore ? (
                        <DoNotDisturbOnIcon
                          style={{ margin: 0 }}
                          className="text-grey"
                        />
                      ) : (
                        <AddCircleIcon
                          style={{ margin: 0 }}
                          className="text-grey"
                        />
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                </Typography>
                <Link
                  onClick={() => websiteClick(brandInfo.webSiteUrl)}
                  className="text-secondary"
                  id="webClick"
                  sx={{ fontSize: ".8rem" }}
                >
                  {brandInfo.webSiteUrl.length > 0 ? (
                    <>
                      <LanguageIcon />
                      {brandInfo.webSiteUrl}{" "}
                    </>
                  ) : (
                    ""
                  )}
                </Link>
              </CardContent>
            </Card>
          </React.Fragment>
        );
      }
    }
  }
  return <></>;
};

export default BrandInfo;
