import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";

export const PrivacyPolicy = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <CanonicalStaticTag page="/privacy-policy" />
      <Box className="main-container legal-privacy-cookie-container">
        <Typography component="div" className="space-from-top"></Typography>
        <Typography component="h2" className="heading-two">
          {t("privacy_policy_page.title")}
        </Typography>
        <Typography component="p"></Typography>
        <Typography component="p">
          {t("privacy_policy_page.description")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          <strong> {t("privacy_policy_page.privacy_policy")}</strong>
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title1")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description1")}
        </Typography>
        <Box>
          <List className="cookiePolicyList">
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t("privacy_policy_page.collect_personaldata")}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("privacy_policy_page.use_info")} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("privacy_policy_page.disclose_data")} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t("privacy_policy_page.keep_securedata")}
              />
            </ListItem>
          </List>
        </Box>

        <Typography component="p">
          <strong> {t("privacy_policy_page.sub_title2")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description2")}
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title3")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description3")}
        </Typography>
        <Typography component="p">
          <strong> {t("privacy_policy_page.sub_title4")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description4")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description5")}
        </Typography>
        <Box>
          <List className="cookiePolicyList">
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("privacy_policy_page.our_website")} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t("privacy_policy_page.our_application")}
              />
            </ListItem>
          </List>
        </Box>
        <Typography component="p">
          {t("privacy_policy_page.description6")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description7")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description8")}
        </Typography>
        <Box>
          <List className="cookiePolicyList">
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t("privacy_policy_page.details_of_transactions")}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {t("privacy_policy_page.technical_info")}
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://bimlibrary.saint-gobain.com/en/France/cookie"
                >
                  &nbsp;{t("privacy_policy_page.further_info")}
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Typography component="p">
          <strong> {t("privacy_policy_page.sub_title5")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description9")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.purpose_of_handling")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.purpose_of_managing")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.specific_purpose")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.personaldata_principally")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.consent")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.legitimate_interests")}
            </ListItemText>
          </ListItem>
        </List>

        <Typography component="p">
          {t("privacy_policy_page.description10")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.marketing_activites")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.legal_obligation")}

              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {t("privacy_policy_page.to_assist")}
                  </ListItemText>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {t("privacy_policy_page.to_identify")}
                  </ListItemText>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {t("privacy_policy_page.to_verify")}
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.performance_of_contract")}
            </ListItemText>
          </ListItem>
        </List>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title6")}</strong>
        </Typography>
        <Typography component="p">
          <u>{t("privacy_policy_page.group_companies")}</u>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description11")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description12")}
        </Typography>
        <Typography component="p">
          <u>{t("privacy_policy_page.underline2")}</u>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description13")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.under_a_duty")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.enforce_applicable")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.consented")}</ListItemText>
          </ListItem>
        </List>
        <Typography component="p">
          {t("privacy_policy_page.description14")}
        </Typography>
        <Typography component="p">
          <strong> {t("privacy_policy_page.sub_title7")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description15")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description16")}
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title8")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description17")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description18")}
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title9")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description19")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("privacy_policy_page.to_access")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.to_correct")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.to_erase")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.to_withdraw")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.to_object")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.to_ask")}</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("privacy_policy_page.to_lodge")}</ListItemText>
          </ListItem>
        </List>

        <Typography component="p">
          {t("privacy_policy_page.description20")}
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title10")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description21")}
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title11")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description22")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description23")}
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description24")}
        </Typography>
        <Typography component="p">
          <strong>{t("privacy_policy_page.sub_title12")}</strong>
        </Typography>
        <Typography component="p">
          {t("privacy_policy_page.description25")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("privacy_policy_page.by_email")}
              <Link
                href="mailto:PrivacyContact.CSG.FR@saint-gobain.com"
                target="_blank"
              >
                &nbsp; {t("privacy_policy_page.csg")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="address">
              {t("privacy_policy_page.postalAddress.by_postal_mail")}
              <Typography component="div" className="m-t-5">
                {t("privacy_policy_page.postalAddress.protection_officer")}
              </Typography>
              <Typography component="div">
                {t("privacy_policy_page.postalAddress.company")}
              </Typography>
              <Typography component="div">
                {t("privacy_policy_page.postalAddress.address")}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </>
  );
};
