import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { useRelocate } from "../../../relocate";
import { Filter } from "./Filter/filter";
import { Products } from "./Product/products";
import { destructParams, searchProduct } from "../../Api/Products.api";
import { ProfileIndex } from "../../Component/profileIndex";
import { Grid, Typography, List, Link, Skeleton } from "@mui/material";
import {
  ICultureContextType,
  CultureContext,
} from "../../../Context/cultureContext";
import BrowseProvider, {
  BrowseContext,
  IBrowseContextType,
} from "../../../Context/browseContext";

import { ProductCardSkeleton } from "./Product/productCardSkeleton";

export const EmbedEcophon = () => {
  return (
    <BrowseProvider>
      <EmbedEcophonBody />
    </BrowseProvider>
  );
};

export const EmbedEcophonBody = () => {
  const { locale = "en", region = "France" } = useParams();
  const { i18n } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const params = destructParams(searchParams);
  const { brand, page, format, criteria, sort, search } = params;
  const { setParams, config } = useContext(
    CultureContext
  ) as ICultureContextType;
  const { loading, products } = useContext(BrowseContext) as IBrowseContextType;

  useEffect(() => {
    i18n.changeLanguage(locale);
    setParams({ region, locale });
  }, [region, locale, setParams]);

  const changePage = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const changeOrder = (sortBy: string) => {
    let sortorder = "ASC";

    const currentBy = sort.split("_")[0];
    let currentOrder = sort.split("_")[1];

    const sorttobe =
      sortBy === "TITLEA" || sortBy === "TITLED" ? "TITLE" : sortBy;

    if (currentBy === sorttobe) {
      if (currentOrder === "ASC" || sortBy === "TITLED") {
        sortorder = "DESC";
      }
    }

    if (sortBy === "TITLEA") {
      sortorder = "ASC";
    }

    if (sortBy === "TITLED") {
      sortorder = "DESC";
    }

    searchParams.set("sort", `${sorttobe}_${sortorder}`);
    setSearchParams(searchParams);
  };

  const deleteParamsEntry = (
    params: URLSearchParams,
    key: string,
    value: string
  ) => {
    const newEntries = Array.from(params.entries()).filter(
      ([k, v]: any) => !(k === key && v === value)
    );
    return new URLSearchParams(newEntries);
  };

  const toggleFilter = (
    key: string,
    criteriaId: string,
    tagLabel: string,
    filterType: string,
    criteriaType: string,
    from: string,
    to: string
  ) => {
    searchParams.set("page", "1");
    /// adjustment for Range filter
    if (filterType === "range") {
      const tags = searchParams
        .getAll("fr[]")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("fr[]");
      for (const tag of tags) searchParams.append("fr[]", tag);
      searchParams.append("fr[]", `${criteriaId}+${tagLabel}+${from}+${to}`);
      setSearchParams(searchParams);
    }
    if (filterType === "list") {
      if (criteriaType === "format") {
        searchParams.append("ff[]", `${criteriaId}+${tagLabel}`);
      } else {
        searchParams.append("fl[]", `${criteriaId}+${tagLabel}`);
      }
      setSearchParams(searchParams);
    }
  };

  const deleteFilter = (filterType: string, criteriaId: string) => {
    searchParams.set("page", "1");
    if (filterType === "list") {
      const tags = searchParams
        .getAll("fl[]")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("fl[]");
      for (const tag of tags) searchParams.append("fl[]", tag);
    } else if (filterType === "format") {
      const tags = searchParams
        .getAll("ff[]")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("ff[]");
      for (const tag of tags) searchParams.append("ff[]", tag);
    }
    if (filterType === "range") {
      const tags = searchParams
        .getAll("fr[]")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("fr[]");
      for (const tag of tags) searchParams.append("fr[]", tag);
    }
    setSearchParams(searchParams);
  };

  const prdtNo = 12;
  const productCardSkeleton = [...Array(prdtNo)].map((i, key) => {
    return <ProductCardSkeleton skey={i} key={i} />;
  });

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: "25px",
        marginTop: "0",
        marginLeft: "0",
        width: "100%",
      }}
    >
      <Grid item xs={11}>
        <Filter
          toggleFilter={toggleFilter}
          deleteFilter={deleteFilter}
          selectedFormat={format}
          selectedCriteria={criteria}
          categoryOptions={products?.criteriaCategoryList}
          formatOptions={products?.formatCriteria}
          locale={locale}
          region={region}
          selectedBrand={brand}
          brands={products?.brandList}
          loading={loading}
        />
      </Grid>
      <Grid item xs={1}>
        <ProfileIndex />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <>
            <Typography component="div" className="browse-skeleton">
              <Skeleton
                width="15%"
                height={40}
                sx={{ mb: "5px", display: "inline-block", mt: "14px" }}
              />
              <Skeleton
                width="15%"
                height={40}
                sx={{
                  mb: "5px",
                  display: "inline-block",
                  float: "right",
                  mt: "14px",
                }}
              />

              <Typography component="div" className="product-card-skeleton">
                <List className={"list-products"}>{productCardSkeleton}</List>
              </Typography>
            </Typography>
          </>
        ) : (
          <Products
            products={products?.productList}
            page={parseInt(page)}
            changePage={changePage}
            sort={sort}
            changeOrder={changeOrder}
            brands={products?.brandList}
            loading={loading}
            config={config}
            totalPages={products?.totalPages}
            totalCount={products?.totalCount}
            brand={brand}
          />
        )}

        <Typography className="powered-text">
          Powered by{" "}
          <Link
            href="https://bimlibrary.saint-gobain.com/en/France"
            target="_blank"
          >
            Saint-Gobain BIM Library
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
