import { Slider, Typography, Tooltip } from "@mui/material";
import React from "react";
import { FilterCriteria } from "../../Types";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import { useSearchParams } from "react-router-dom";
import { IToggleRangeFilter, IToggleListFilter } from "./util/filterType";

interface Props {
  category_name: string;
  criteria: FilterCriteria;
  type: string;
  selected: string;
  region: string;
  locale: string;
  toggleRangeFilter: (props: IToggleRangeFilter) => void;
  toggleListFilter: (props: IToggleListFilter) => void;
}

export const RangeFilter = ({
  category_name,
  criteria,
  type,
  selected,
  region,
  locale,
  toggleRangeFilter,
}: Props) => {

  const [rangeVal, setRangeVal] = React.useState<number[]>([criteria.minValue, criteria.maxValue]);
  let [searchParams] = useSearchParams();
  let selValue = [criteria.minValue, criteria.maxValue];

  if (selected.includes(criteria.id)) {
    const searchParameter = new URLSearchParams(selected);
    const selectedRangeValues = searchParameter
      .getAll("fr[]")
      .filter((tag) => !tag.includes(criteria.id));

    if (selectedRangeValues.length > 0) {
      const splitedSelectedRangeValues = selectedRangeValues[0].split("+");
      selValue = [
        parseInt(splitedSelectedRangeValues[2]),
        parseInt(splitedSelectedRangeValues[3]),
      ];
      setRangeVal(selValue);
    }
  }

  const valuetext = (value: any) => {
    return `${value}${criteria.unit}`;
  };

  // update range filter on slider click again
  React.useEffect(() => {
    const selectedRangeCriteria = searchParams.getAll("fr[]") || [];
    selectedRangeCriteria &&
      selectedRangeCriteria.map((filter: string) => {
        const rangeValueFrom: string = `${filter.split("+")[2]}}`;
        const rangeValueTo: string = `${filter.split("+")[3]}}`;
        const updateRangeVal = [parseInt(rangeValueFrom), parseInt(rangeValueTo)];
        setRangeVal(updateRangeVal);
      });
    
  }, [rangeVal]);

  // Reset Range Filter
   React.useEffect(() => {
      selValue = [criteria.minValue, criteria.maxValue];
      setRangeVal(selValue);    
   }, [!(searchParams.has("fr[]"))]);
  
  return (
    <React.Fragment key={criteria.id}>
      <Typography component="div" style={{ padding: "0 15px" }}>
        {type.toLowerCase() === "advanced" && <h4>{criteria.label}</h4>}
        <Typography component="div" className="filter-slider-values">
          <Typography>{criteria.minValue}</Typography>
          <Tooltip title="Unit(s)" placement="top" arrow>
            <Typography>({criteria.unit})</Typography>
          </Tooltip>
          <Typography>{criteria.maxValue}</Typography>
        </Typography>
        <Slider
          id="range-slider"
          min={criteria.minValue}
          disableSwap
          max={criteria.maxValue}
          name="range-slider"
          defaultValue={[criteria.minValue, criteria.maxValue]}
          step={1}
          marks={true}
          value={rangeVal}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          onChange={(event: Event, newValue: number | number[]) => {
            if (typeof newValue !== "number") {
              toggleRangeFilter({
                filterType: criteria.filterType,
                criteriaId: criteria.id,
                tagLabel: criteria.label,
                from: newValue[0].toString(),
                to: newValue[1].toString(),
              });

              sendDataToGTM({
                event: "filters",
                category_name: category_name,
                criteria_name: criteria.label,
                criteria_max_value: newValue[1],
                criteria_min_value: newValue[0],
                criteria_type: criteria.filterType,
                region: region,
                locale: locale,
              });
            }
          }}
        />
      </Typography>
    </React.Fragment>
  );
};
