import "../../Utils/assets/profileButton.scss";
import { ProfileMenu } from "./profileMenu";
import { Login } from "./login";
import { isExpired } from "react-jwt";
import { useEffect, useState } from "react";
import { refreshTokenSliently } from "../../API/authenticate.api";

export const ProfileIndex = () => {
  const [loading, setLoding] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(true);

  useEffect(() => {
    setLoding(true);
    async function checkUserLogin() {
      const token = localStorage.getItem("token");
      if (token) {
        const istokenExpired = isExpired(token);
        if (istokenExpired) {
          const isMyRefreshTokenExpired = await refreshTokenSliently();
          setSessionExpired(isMyRefreshTokenExpired);
        } else {
          setSessionExpired(false);
        }
      }
      setLoding(false);
    }

    checkUserLogin();
  }, [localStorage.getItem("token")] );

  if (loading) {
    return <>..</>;
  }

  if (sessionExpired) {
    return <Login />;
  }
  else{
  return <ProfileMenu />;
  }
};
