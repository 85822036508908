import * as React from "react";
import { useEffect,useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { isExpired } from "react-jwt";
import { getRefreshAccessToken } from "../../API/authenticate.api";

export const SessionTime = () => {
  const [open, setOpen] = useState(false);
  const token: any = localStorage.getItem("token");

  let idleEvent :any; 
  const events = [
    'mousemove',
    'click',
    'keypress'
  ];

  useEffect(() => 
    {
      const sessionTimeout = () => {
        const refreshtoken: any = localStorage.getItem("refresh_token");
        if (refreshtoken) {
          const isMyTokenExpired = isExpired(refreshtoken);
          let idleTimeout: any = isMyTokenExpired;
          if (isMyTokenExpired) {
            if (!!idleEvent) clearTimeout(idleEvent); // !!- converts a nonbolean value to a boolean value
            // idleEvent = setTimeout(() => setOpen(true), idleTimeout); // show session warning modal.
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token");
            localStorage.removeItem("username");
          }
        }
      
      };
      for (let e in events) 
      {
        window.addEventListener(events[e], sessionTimeout);
      }
  
      return () => 
      {
        for(let e in events)
        {
          window.removeEventListener(events[e], sessionTimeout);
        }
      }
    },[]);

  const handleClose = () => {
    if(open === true){
    setOpen(false);
    }
  };

  return (
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }} id="sessionCloseAlert">
          Your session expired, Please login again!
        </Alert>
      </Snackbar>
    </div>
  );
};
