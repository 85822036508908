import { Box, Typography, Link, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRelocate } from "../relocate";
import { getSitemapProducts, ListRegionByCounter } from "../API";
import { forEach } from "lodash";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";
import { CultureContext, ICultureContextType } from "../Context/cultureContext";

export const Sitemap = () => {
  const { locale, region } = useParams();
  const [hostUrl, setUrl] = useState<string>("");
  const { relocate } = useRelocate();
  const { t } = useTranslation("common");
  const { regions, config } = useContext(CultureContext) as ICultureContextType;

  const { allProducts } = useContext(CultureContext) as ICultureContextType;

  useEffect(() => {
    // to create sitemap
    async function createSitemap() {
      let sitemapindex = 1;
      const languageObject: string[] = [
        "en",
        "de",
        "it",
        "nl",
        "pt",
        "ru",
        "fr",
        "sq",
        "es",
        "zh",
      ];
      const regionObject: any = await ListRegionByCounter();
      let staticSites = [
        "",
        "/browse?brand=all&amp;sort=DOWNLOADS_DESC",
        "/contactus",
        "/legal-policy",
        "/cookie-policy",
        "/privacy-policy",
      ];
      const productObject = await getSitemapProducts();

      forEach(regionObject, (reg: any) => {
        const regenglish = reg.translations.find(
          (element: any) => element.languageId === "en"
        );
        if (regenglish) {
          var doc = document.implementation.createDocument("", "", null);
          let url: any = "";
          let changefreq: any = "";
          let loc: any = "";
          let lastmod: any = "";
          let urlset = doc.createElement("urlset");
          urlset.setAttribute(
            "xmlns",
            "http://www.sitemaps.org/schemas/sitemap/0.9"
          );
          urlset.setAttribute("xmlns:xhtml", "http://www.w3.org/1999/xhtml");

          let urlcount = 0;

          for (var i = 0; i < staticSites.length; i++) {
            // eslint-disable-next-line no-loop-func
            forEach(languageObject, (parentlang: string) => {
              url = doc.createElement("url");
              loc = doc.createElement("loc");
              loc.innerHTML = encodeURI(
                `https://bimlibrary.saint-gobain.com/${parentlang}/${regenglish.label}${staticSites[i]}`.toLowerCase()
              );
              url.appendChild(loc);
              urlcount = urlcount + 1;
              // eslint-disable-next-line no-loop-func
              forEach(languageObject, (lang: string) => {
                let xhtmllink = doc.createElement("xhtml:link");
                xhtmllink.setAttribute("rel", "alternate");
                xhtmllink.setAttribute(
                  "hreflang",
                  `${lang}-${reg.technicalName.slice(0, -1)}`.toLowerCase()
                );
                xhtmllink.setAttribute(
                  "href",
                  encodeURI(
                    `https://bimlibrary.saint-gobain.com/${lang}/${regenglish.label}${staticSites[i]}`.toLowerCase()
                  )
                );
                url.appendChild(xhtmllink);
                urlcount = urlcount + 1;
              });
              changefreq = doc.createElement("changefreq");
              changefreq.innerHTML = "daily";
              lastmod = doc.createElement("changefreq");
              const currentDate = new Date();
              lastmod.innerHTML = `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`;
              url.appendChild(changefreq);
              url.appendChild(lastmod);
              urlset.appendChild(url);
            });
          }

          const productArray = productObject[reg.technicalName];
          forEach(productArray, (prodctName: any, key: string) => {
            forEach(languageObject, (parentlang: string) => {
              url = doc.createElement("url");
              loc = doc.createElement("loc");
              loc.innerHTML = encodeURI(
                `https://bimlibrary.saint-gobain.com/${parentlang}/${regenglish.label}/product/${prodctName}`.toLowerCase()
              );
              url.appendChild(loc);
              urlcount = urlcount + 1;
              forEach(languageObject, (lang: string) => {
                let xhtmllink = doc.createElement("xhtml:link");
                xhtmllink.setAttribute("rel", "alternate");
                xhtmllink.setAttribute(
                  "hreflang",
                  `${lang}-${reg.technicalName.slice(0, -1)}`.toLowerCase()
                );
                xhtmllink.setAttribute(
                  "href",
                  encodeURI(
                    `https://bimlibrary.saint-gobain.com/${lang}/${regenglish.label}/product/${prodctName}`.toLowerCase()
                  )
                );
                url.appendChild(xhtmllink);
                urlcount = urlcount + 1;
              });
              changefreq = doc.createElement("changefreq");
              changefreq.innerHTML = "daily";
              lastmod = doc.createElement("changefreq");
              const currentDate = new Date();
              lastmod.innerHTML = `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`;
              url.appendChild(changefreq);
              url.appendChild(lastmod);
              urlset.appendChild(url);
            });
          });

          doc.appendChild(urlset);
          var oSerializer = new XMLSerializer();
          var xmltext = oSerializer.serializeToString(doc);
          xmltext = '<?xml version="1.0" encoding="UTF-8"?>' + xmltext;
          //download the file
          var pom = document.createElement("a");
          var filename = `sitemap${sitemapindex}.xml`;
          var bb = new Blob([xmltext], { type: "text/plain" });
          pom.setAttribute("href", window.URL.createObjectURL(bb));
          pom.setAttribute("download", filename);
          pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
          );
          pom.draggable = true;
          pom.classList.add("dragout");
          pom.click();
          sitemapindex = sitemapindex + 1;
        }
      });
    }
  }, [region, locale, regions]);

  if (allProducts && config) {
    const filteredProducts = allProducts.filter(
      (alpr: any) => alpr.code === "Product"
    );
    return (
      <>
        <CanonicalStaticTag page="/sitemap" />
        <Box className="main-container sitemap-container">
          <Typography component="div" className="space-from-top"></Typography>
          <Grid container>
            <Grid item sm={12} lg={5}>
              <Typography variant="h2" className="m-t-20 section-heading">
                {" "}
                {t("sitemap_page.header.by_pages.title")}
              </Typography>
              <ul className="sitemap-list">
                <li>
                  <Link href={`/${locale}/${region}`}>
                    {" "}
                    {t("sitemap_page.header.by_pages.bim_obj_lib")}
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${locale}/${region}/browse?brand=all&sort=downloads&order=desc`}
                  >
                    {t("home_page.home_banner_Page.browse_btn")}
                  </Link>
                </li>
                <li>
                  <Link href={`/${locale}/${region}/downloads`}>
                    {" "}
                    {t("header.downloads")}
                  </Link>
                </li>
                <li>
                  <Link
                    href={`${config.REACT_APP_AUTHENTICATIONURL}/user/updateAccount`}
                  >
                    {" "}
                    {t("header.profile_menu.myaccount")}
                  </Link>
                </li>
                <li>
                  <Link
                    href={`${config.REACT_APP_AUTHENTICATIONURL}/createNewAccount`}
                  >
                    {" "}
                    {t("sitemap_page.header.by_pages.create_new_acc")}
                  </Link>
                </li>
                <li>
                  <Link href={`${config.REACT_APP_AUTHENTICATIONURL}/login`}>
                    {" "}
                    {t("header.login")}
                  </Link>
                </li>
                <li>
                  <Link
                    href={`${config.REACT_APP_AUTHENTICATIONURL}/forgottenPassword`}
                  >
                    {t("sitemap_page.header.by_pages.forgot_password")}
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item sm={12} lg={5}>
              <Typography variant="h2" className="m-t-20 section-heading">
                {t("sitemap_page.header.by_products")}
              </Typography>
              <ul className="sitemap-list">
                {filteredProducts.map((product: any) => {
                  return (
                    <li key={product.oid}>
                      <Link
                        href={`/${locale}/${region}/product/${product.name}`}
                      >
                        {product.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box className="main-container sitemap-container">
        <div style={{ width: "0px", margin: "50px auto" }}>
          <CircularProgress style={{ margin: "0px auto" }} />
        </div>
      </Box>
    </>
  );
};
