import "../../Utils/assets/profileButton.scss";
import { Avatar, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";
import { MyConfig } from "../../API/myConfig";

export const Login = () => {
  const [config, setConfig] = useState<any>(null);

  useEffect(() => {
    async function ReadConfig() {
      let config = await MyConfig();
      setConfig(config);
    }
    ReadConfig();
  });

  if (config) {
    const loginurl =
      config.REACT_APP_AUTHENTICATIONURL +
      "/oauth/authorize?response_type=code&grant_type=authorization_code&client_id=" +
      encodeURIComponent(config.REACT_APP_AUTH_CLIENT_ID) +
      "&redirect_uri=" +
      encodeURIComponent(config.REACT_APP_URL + "/authenticate") +
      "&scope=" +
      encodeURIComponent("user:projects user:downloads");
    return (
      <Button
        className="f-w-500 login font-primary"
        id="loginBtn"
        style={{ textTransform: "capitalize" }}
        onClick={(e: any) => {
          const from = window.location.pathname + "" + window.location.search;
          localStorage.setItem("loginstate", from);
          window.location.href = loginurl;
        }}
      >
        <Avatar style={{ marginLeft: "5px" }}>
          <PersonIcon />
        </Avatar>
      </Button>
    );
  }

  return <></>;
};
