
import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
import { CanonicalStaticTagWthoutRegion } from "../Components/Header/canonicaltags";
import { useParams } from "react-router-dom";

const tableData = (
  name: string,
  value: string,
  domain: string,
  expires: string
) => {
  return { name, value, domain, expires };
};
const rows = [
  tableData(
    "incap_ses_707_1838544",
    "f7gRObu9H3ano5OKGcXPCdU8SmAAAAAA4vFa/3CSBgXHJU6x+w43jg==",
    ".saint-gobain.com",
    "Session"
  ),
  tableData(
    "___utmvmFyuoEczB",
    "WUIBLIFVDis",
    "bimlibrary.saint-gobain.com",
    "2021-03-11T16:07:54.322Z"
  ),
  tableData(
    "visid_incap_1838544",
    "IjXkys5sQwywex+8JxQxOH0ESmAAAAAAQUIPAAAAAAA1O/Ru1KKIyoK83XARFb2u",
    ".saint-gobain.com",
    "2022-03-11T09:11:18.326Z"
  ),
  tableData(
    "___utmvbFyuoEczB",
    "NZw XRrOxalh: ctp",
    "bimlibrary.saint-gobain.com",
    "2021-03-11T16:07:54.323Z"
  ),
  tableData(
    "incap_ses_707_1469129",
    "3I70FAu3C0+1UoqKGcXPCaYESmAAAAAAPDOoW8KhZH9991TvwqEUaQ==",
    ".saint-gobain.com",
    "Session"
  ),
  tableData(
    "visid_incap_1469129",
    "A9gE84fWQYqHrknuZ+bC3aYESmAAAAAAQUIPAAAAAACpuMhOW4W0xNRZq/Tg15Bs",
    ".saint-gobain.com",
    "2022-03-11T09:11:17.990Z"
  ),
];

export const CookiePolicy = () => {
  const { t } = useTranslation("common");

  const { locale, region = "France" } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <>
      <CanonicalStaticTagWthoutRegion page="/cookie-policy" />
      <Box className="main-container legal-privacy-cookie-container">
        <Typography component="div" className="space-from-top"></Typography>
        <Typography component="h2" className="heading-two">
          {t("cookie_policy_page.title")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("cookie_policy_page.question1")}
        </Typography>
        <Typography component="p">{t("cookie_policy_page.answer1")}</Typography>
        <Typography component="div" variant="h3">
          {t("cookie_policy_page.question2")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.answer2.answer_line1")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.answer2.answer_line2")}
        </Typography>
        <Typography component="div" variant="h3">
          {t("cookie_policy_page.question3")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.answer3.answer_line1")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.answer3.answer_line2")}
        </Typography>
        <Typography component="div" variant="h3">
          {t("cookie_policy_page.headers.by_cookielist.title")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.headers.by_cookielist.description1")}
        </Typography>
        <Typography component="p">
          <strong>
            {" "}
            {t("cookie_policy_page.headers.by_cookielist.subtitle1")}
          </strong>
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.headers.by_cookielist.description2")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.headers.by_cookielist.description3")}
          <Link
            rel="noopener"
            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
            target="_blank"
          >
            &nbsp; {t("cookie_policy_page.headers.by_cookielist.here")}
          </Link>
          .
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.headers.by_cookielist.description4")}
          <Link
            rel="noopener"
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
          >
            &nbsp; {t("cookie_policy_page.headers.by_cookielist.plugin")}
          </Link>{" "}
          {t("cookie_policy_page.headers.by_cookielist.description5")}
        </Typography>
        <Typography component="p">
          <strong>
            {t("cookie_policy_page.headers.by_cookielist.subtitle2")}
          </strong>
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.headers.by_cookielist.description6")}
        </Typography>

        <Box className="responsive-table">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="width-15">
                    {" "}
                    {t("cookie_policy_page.headers.by_cookielist.table.name")}
                  </TableCell>
                  <TableCell className="width-55">
                    {" "}
                    {t("cookie_policy_page.headers.by_cookielist.table.value")}
                  </TableCell>
                  <TableCell className="width-15">
                    {" "}
                    {t("cookie_policy_page.headers.by_cookielist.table.domain")}
                  </TableCell>
                  <TableCell className="width-15">
                    {" "}
                    {t(
                      "cookie_policy_page.headers.by_cookielist.table.expires"
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell className="width-15">{row.name}</TableCell>
                    <TableCell className="width-55">{row.value}</TableCell>
                    <TableCell className="width-15">{row.domain}</TableCell>
                    <TableCell className="width-15">{row.expires}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography component="p">&nbsp;</Typography>

        <Typography component="div" variant="h3">
          {t("cookie_policy_page.question4")}
        </Typography>
        <Typography component="p">
          <strong>{t("cookie_policy_page.sub_title")}</strong>
        </Typography>
        <Typography component="p">{t("cookie_policy_page.answer4")}</Typography>
        <Typography component="div" variant="h3">
          {t("cookie_policy_page.headers.by_the_browser.title")}
        </Typography>
        <Typography component="p">
          {t("cookie_policy_page.headers.by_the_browser.description1")}
        </Typography>
        <Box>
          <List className="cookiePolicyList">
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.delete_cookies"
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.block_cookies"
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.allow_cookies"
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.third_party_cookies"
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.clear_cookies"
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.private_browsing_session"
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "cookie_policy_page.headers.by_the_browser.install_and_plugins"
                )}
              />
            </ListItem>
          </List>
        </Box>

        <Typography component="p">
          {t("cookie_policy_page.headers.by_the_browser.description2")}
        </Typography>

        <Typography component="div" variant="h3">
          {t("cookie_policy_page.headers.by_information.title")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("cookie_policy_page.headers.by_information.sentence1")}
              <Link
                rel="noopener"
                href="http://www.aboutcookies.org"
                target="_blank"
              >
                {" "}
                &nbsp; AboutCookies.org
              </Link>{" "}
              {t("common.and")}
              <Link
                rel="noopener"
                href="http://www.allaboutcookies.org"
                target="_blank"
              >
                &nbsp; AllAboutCookies.org
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("cookie_policy_page.headers.by_information.sentence2")}
              <Link
                rel="noopener"
                href="http://www.youronlinechoices.com/uk"
                target="_blank"
              >
                &nbsp;{" "}
                {t(
                  "cookie_policy_page.headers.by_information.Your_Online_Choices"
                )}
              </Link>{" "}
              {t("cookie_policy_page.headers.by_information.sentence3")}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("cookie_policy_page.headers.by_information.sentence4")}
              <Link
                rel="noopener"
                href="http://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                &nbsp;{" "}
                {t("cookie_policy_page.headers.by_information.ga_optout")}
              </Link>{" "}
              {t("cookie_policy_page.headers.by_information.sentence5")}
              <Link
                rel="noopener"
                href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en"
                target="_blank"
              >
                &nbsp;{" "}
                {t("cookie_policy_page.headers.by_information.sentence6")}
              </Link>
              .
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("cookie_policy_page.headers.by_information.sentence7")}
              <Link
                rel="noopener"
                href="https://support.microsoft.com/fr-fr/windows/microsoft-edge-donn%C3%A9es-de-navigation-et-confidentialit%C3%A9-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                target="_blank"
              >
                &nbsp;{" "}
                {t("cookie_policy_page.headers.by_information.microsoft_edge")}
              </Link>
              ,{" "}
              <Link
                rel="noopener"
                href="http://windows.microsoft.com/en-gb/windows7/block-enable-or-allow-cookies"
                target="_blank"
              >
                {t(
                  "cookie_policy_page.headers.by_information.internet_explorer"
                )}
              </Link>
              ,{" "}
              <Link
                rel="noopener"
                href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en"
                target="_blank"
              >
                {t("cookie_policy_page.headers.by_information.chrome_browse")}
              </Link>
              ,{" "}
              <Link
                rel="noopener"
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
              >
                {t("cookie_policy_page.headers.by_information.firefox_rowse")}
              </Link>
              ,{" "}
              <Link
                rel="noopener"
                href="http://www.apple.com/support/?path=Safari/3.0/fr/9277.html"
                target="_blank"
              >
                {t("cookie_policy_page.headers.by_information.safari_browse")}
              </Link>{" "}
              {t("and ")}
              <Link
                rel="noopener"
                href="https://help.opera.com/en/latest/security-and-privacy/#tracking"
                target="_blank"
              >
                {t("cookie_policy_page.headers.by_information.opera_browse")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t(
                "cookie_policy_page.headers.by_the_browser.clear_cookies"
              )}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("cookie_policy_page.headers.by_information.sentence8")}
              <Link
                rel="noopener"
                href="https://www.microsoft.com/en-us/iegallery"
                target="_blank"
              >
                &nbsp;{" "}
                {t(
                  "cookie_policy_page.headers.by_information.trackingProtectionLists"
                )}
              </Link>{" "}
              {t("cookie_policy_page.headers.by_information.sentence9")}
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </>
  );
};
