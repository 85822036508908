import { SyntheticEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import { map } from "lodash";
import "../../../Resources/filterElement.scss";
import "../../../Resources/rangeFilter.scss";
import { ListFilter } from "./filterList";
import { RangeFilter } from "./filterRange";
import { FilterCategory, FilterCriteria } from "../../../../Types";
export const FilterCategoryEle = (props: {
  category: FilterCategory;
  selectedCriteria: string[];
  toggleFilter: any;
  selectedFormat: string[];
  region: string;
  locale: string;
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClickAway = () => {
    setExpanded(false);
  };


  return (
    <ClickAwayListener onClickAway={handleClickAway}>

      <Accordion
        variant="outlined"
        className={`filter-element filter-element-poc ${props.category.principal === true ? "global-filter" : ""
          }`}
        key={props.category.id}
        expanded={expanded === props.category.label}
        onChange={(event: any, isExpanded: any) =>
          handleChange(isExpanded, props.category.label)
        }
      // expanded={expanded}
      // onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={props.category.label}
        >
          <ListIcon />
          <Typography>{props.category.label}</Typography>
        </AccordionSummary>
        {expanded ? (<AccordionDetails className="filter-content">
          {map(props.category.criterias, (criteria: FilterCriteria) => {
            return (
              <FilterCriteriaEle
                criteria={criteria}
                key={criteria.id}
                selected={[...props.selectedCriteria, ...props.selectedFormat]}
                toggleFilter={props.toggleFilter}
                type={props.category.type}
                region={props.region}
                locale={props.locale}
              />
            );
          })}
        </AccordionDetails>) : null}

      </Accordion>


    </ClickAwayListener>
  );
};

const FilterCriteriaEle = (props: {
  criteria: FilterCriteria;
  selected: string[];
  toggleFilter: any;
  type: string;
  region: string;
  locale: string;
}) => {
  switch (props.criteria.filterType) {
    case "RANGE":
      return <RangeFilter {...props} />;
    case "LIST":
      return <ListFilter {...props} />;
    default:
      return <ListFilter {...props} />;
  }
};
