import { MyConfig } from "./myConfig";
import axios from "axios";
import { GetRegionOid } from "./region.api";
import { addAllProductsToMyBasket, createNewBasket } from "./basket.api";
import JSZip from "jszip";
import saveAs from "file-saver";
import JSZipUtils from "../Components/Common/jsZipUtils";

// yogesh

type ICallback = () => void;

export const downloadFilesFromBim = async (
  callBack: ICallback,
  errorCallback: ICallback,
  region: string,
  locale: string,
  files: { [Key: string]: string[] },
  controllerref: any,
  regions: any,
  token: string | null = ""
) => {
  if (!token) {
    token = localStorage.getItem("token");
  }
  const config = await MyConfig();
  const preparedFile = await prepareZipFile(
    region,
    locale,
    files,
    controllerref,
    errorCallback,
    regions,
    token
  );
  if (preparedFile.length > 1) {
    var zip = new JSZip();
    const zipCallBack = () => {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${new Date().toJSON().slice(0, 10)}.zip`);
        callBack();
      });
    };

    var count = 0;
    preparedFile.forEach(function (url: string) {
      var filename = url.split("/")[1];
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(
        `${config.REACT_APP_FILEMANAGEMENT_API_URL}/anonymous/downloadPrepared/${url}`,
        function (err: any, data: any) {
          if (err) {
            console.log("error in binary content", err);
            errorCallback();
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === preparedFile.length) {
            zipCallBack();
          }
        }
      );
    });
  } else {
    downloadPreparedFile(
      preparedFile[0],
      () => {
        callBack();
      },
      errorCallback,
      controllerref
    );
  }
};

export const prepareZipFile = async (
  region: string,
  locale: string,
  fileData: any,
  controllerRef: any,
  errorCallback: ICallback,
  regions: any,
  token: string | null
) => {
  if (!token) {
    token = localStorage.getItem("token");
  }
  let config = await MyConfig();
  const regionId = await GetRegionOid(regions, region);
  if (regionId) {
    const basketToken = await createNewBasket(regionId);
    if (basketToken) {
      const productAddedTobasket = await addAllProductsToMyBasket(
        basketToken,
        Object.keys(fileData),
        regionId
      );
      if (productAddedTobasket) {
        const productcount = Object.keys(fileData).length;
        const filesCountOfFirstProduct =
          fileData[Object.keys(fileData)[0]].length;
        const controller = new AbortController();
        controllerRef.current = controller;
        if (productcount === 1 && filesCountOfFirstProduct === 1) {
          /// for a single file
          const response = await fetch(
            `${
              config.REACT_APP_FILEMANAGEMENT_API_URL
            }/bimlibrary/prepare/single/${basketToken}/${regionId}/download/${
              Object.keys(fileData)[0]
            }/${fileData[Object.keys(fileData)[0]][0]}`,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              signal: controllerRef.current?.signal,
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              return response;
            })
            .catch((error) => {
              console.log(error);
              errorCallback();

              return null;
            });

          return response;
        } else {
          /// for an multiple files

          const response = await fetch(
            `${config.REACT_APP_FILEMANAGEMENT_API_URL}/bimlibrary/prepare/zip/${basketToken}/${regionId}/download`,
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify(fileData),
              signal: controllerRef.current?.signal,
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              return response;
            })
            .catch((error) => {
              console.log(error);
              return null;
            });

          return response;
        }
      }
    }
  }
  return null;
};

export const downloadPreparedFile = async (
  filePath: string,
  callback: any,
  errorCallback: ICallback,
  controllerRef: any
) => {
  let config = await MyConfig();

  const controller = new AbortController();
  controllerRef.current = controller;
  const reqConfig: any = {
    method: "GET",
    url: `${config.REACT_APP_FILEMANAGEMENT_API_URL}/anonymous/downloadPrepared/${filePath}`,
    responseType: "blob",
    signal: controller.signal,
  };

  await axios(reqConfig)
    .then((response) => {
      let fileName: any = filePath.split("/")[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (callback) callback();
    })
    .catch((error) => {
      console.log(error);
      errorCallback();
    });
};
