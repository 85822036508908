import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Select,
  FormControl,
  Skeleton,
  Typography,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { FilterCategoryEle } from "./filterCategory";
import "../../../Resources/filters.scss";
import { FilterTags } from "./filterTags";
import { ListBrand } from "./filterBrand";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createFilters } from "../../../../Utils/filter.util";
import { FilterCategory } from "../../../../Types";
import { useRelocate } from "../../../../relocate";

interface Props {
  toggleFilter: any;
  selectedFormat: string[];
  categoryOptions: any;
  formatOptions: any;
  selectedCriteria: string[];
  locale: string;
  region: string;
  selectedBrand: string | undefined;
  brands: any;
  loading: boolean;
  deleteFilter: any;
}

export const Filter = ({
  toggleFilter,
  deleteFilter,
  selectedFormat,
  selectedCriteria,
  selectedBrand,
  categoryOptions,
  formatOptions,
  locale,
  region,
  brands,
  loading,
}: Props) => {
  const { redirect } = useRelocate();
  const [displayFilters, setDisplayFilters] = useState<FilterCategory[]>();
  const { t } = useTranslation("common");
  useEffect(() => {
    if (selectedBrand) {
      const filters = createFilters(
        categoryOptions,
        formatOptions,
        selectedBrand
      );
      filters.length > 0 && setDisplayFilters(filters);
    }
  }, [categoryOptions, formatOptions, selectedBrand]);

  // while filter is loading, this skeleton will be shown
  if (loading) {
    return (
      <>
        <Skeleton width="10%" height={40} sx={{ mb: "5px", mt: "0" }} />

        <Skeleton
          variant="rectangular"
          height={30}
          sx={{
            mb: "15px",
            mr: "15px",
            minWidth: "208px",
            display: "inline-block",
          }}
        />
        <Skeleton
          variant="rectangular"
          height={30}
          sx={{
            mb: "15px",
            mr: "15px",
            minWidth: "208px",
            display: "inline-block",
          }}
        />
        <Skeleton
          variant="rectangular"
          height={30}
          sx={{
            mb: "15px",
            mr: "15px",
            minWidth: "208px",
            display: "inline-block",
          }}
        />
        <Skeleton
          variant="rectangular"
          height={30}
          sx={{ mb: "15px", minWidth: "208px", display: "inline-block" }}
        />
      </>
    );
  }

  return (
    <React.Fragment>
      <Box className="filterCont" sx={{ pl: "16px" }}>
        <Box className="filter-header">
          <Button
            className="filter-reset"
            onClick={() => {
              redirect(`/${locale}/${region}/embed/${selectedBrand}`);
            }}
          >
            {t("browse_page.reset_all")}
          </Button>
        </Box>
        <FilterTags deleteFilter={deleteFilter} />
        <Box className="filter-list-element">
          <Accordion
            variant="outlined"
            className="filter-element"
            defaultExpanded={true}
            style={{ display: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              style={{ alignItems: "center" }}
            >
              {/* <ListIcon /> */}
              <Typography>{t("download.brand")}</Typography>
            </AccordionSummary>
            <AccordionDetails className="filter-content">
              <ListBrand
                brands={brands}
                selectedBrand={selectedBrand}
                region={region}
                locale={locale}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="filter-list-element filter-list-element-poc">
          {map(displayFilters, (dspCetegory: FilterCategory) => {
            return (
              <FilterCategoryEle
                category={dspCetegory}
                key={dspCetegory.id}
                selectedCriteria={selectedCriteria}
                toggleFilter={toggleFilter}
                selectedFormat={selectedFormat}
                region={region}
                locale={locale}
              />
            );
          })}
        </Box>
      </Box>
    </React.Fragment>
  );
};
