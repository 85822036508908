import { MyConfig } from "./myConfig";

export const ListRegionByCounter = (function () {
  var regionCache: any = {};
  let cancelToken: any;
  return async function () {
    if (!regionCache["region"]) {
      if (typeof cancelToken != typeof undefined) {
        // cancelToken.abort();
      }

      let controller = new AbortController();
      cancelToken = controller.signal;

      let config = await MyConfig();
      const hostUrl = `${config.REACT_APP_API_URL}/anonymous/product/counterByRegion?excludeEmptyRegions=true&externalModule=false`;
      regionCache["region"] = await fetch(hostUrl, {
        method: "GET",
        signal: cancelToken,
      })
        .then((response) => {
          return response.json();
        })
        .catch((err: any) => {
          return null;
        });
    }
    return regionCache["region"];
  };
})();

export const ListRegions = (function () {
  var regionCache: any = {};
  return async function (locale: string = "en", oids?: any[]) {
    // hour added here to refresh region cache after 1 hour
    const d = new Date();
    let hour = d.getHours();
    const brandKey: string = `${locale}_${oids?.toString()}_${hour}`;
    if (!regionCache[brandKey]) {
      let config = await MyConfig();
      const hostUrl =
        config.REACT_APP_BIMDATABASE_API_URL +
        "/anonymous/objects/class/Region/locale/" +
        locale;

      regionCache[brandKey] = fetch(hostUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: oids ? JSON.stringify({ oids: oids }) : JSON.stringify({}),
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          return res.objects;
        })
        .catch((err: any) => {
          return null;
        });
    }
    return regionCache[brandKey];
  };
})();

export const GetRegionOid = (regions: any, region: string) => {
  if( regions == null) return  null;
  const selectedRegionObject = regions.find((reg: any) => {
    return reg.label.toLowerCase() === region.toLowerCase();
  });
  if (selectedRegionObject.oid) {
    return selectedRegionObject.oid;
  }
  return null;
};

export const GetRegionId = (regions: any, region: string) => {
  const selectedRegionObject = regions.find((reg: any) => {
    return reg.label.toLowerCase() === region.toLowerCase();
  });

  if (selectedRegionObject.id) {
    return selectedRegionObject.id;
  }
  return null;
};

export const sortRegions = (regionByCounter: any, locale: any) => {
  if (regionByCounter) {
    let regionList: any = regionByCounter.map((element: any) => {
      let translation = element.translations.find(
        (trn: any) => trn.locale === locale
      );
      let entranslation = element.translations.find(
        (trn: any) => trn.locale === "en"
      );
      if (translation || entranslation) {
        return {
          label: entranslation ? entranslation.label : element.regionName,
          oid: element.regionId,
          productCount: element.nbitems,
          translation: translation ? translation.label : entranslation.label,
        };
      } else { //if there is no english transaltion or a particular language translation mentioned in that region
        return {
          label: element.regionName,
          oid: element.regionId,
          productCount: element.nbitems,
          translation: element.regionName,
        };
      }
    });
    return regionList.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  return null;
};
