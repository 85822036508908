import React, { useEffect, useState, useContext } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { GreyContainer } from "../Common/greyContainer";
import "../../Utils/assets/byApplication.scss";
import { useTranslation } from "react-i18next";
import {
  Criteria,
  CriteriaCategoryDisplay,
} from "../../API/Types/criteriaCategoryDisplay.type";
import { getCriteriaCategoryByName } from "../../API/criteriaCategory.api";
import { createSearchParams, useParams, useNavigate } from "react-router-dom";
import { useRelocate } from "../../relocate";
import SkeletonComponent from "../Common/SkeletonComponent";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

export const ByApplication = () => {
  const { locale, region } = useParams();
  const { t } = useTranslation("common");
  const { relocate } = useRelocate();
  const { config } = useContext(CultureContext) as ICultureContextType;
  const [applicationCategory, setApplicationCategory] =
    useState<CriteriaCategoryDisplay>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (config) {
      getCriteriaCategoryByName("global-applications", config).then(
        (response) => {
          setApplicationCategory(response);
          setLoading(false);
        }
      );
    }
  }, [config]);

  const getLabel = (criteria: Criteria) => {
    for (const trans of criteria.translations) {
      if (trans.languageId === locale || trans.locale === locale) {
        return trans.label;
      }
    }
    return criteria.criteriaName;
  };

  // Loop through 12 times
  const n = 12;
  if (loading) {
    const barndsSkeleton = [...Array(n)].map((e, i) => (
      <SkeletonComponent width="16.66%" height="90px" key={i} />
    ));
    return <ByApplicationBar t={t}>{barndsSkeleton}</ByApplicationBar>;
  }
  if (applicationCategory) {
    return (
      <ByApplicationBar t={t}>
        <Grid container spacing={2} columns={5}>
          {applicationCategory?.principal &&
            applicationCategory?.criterias.map((appFilter: any, i: any) => (
              <Grid
                item
                xs={1}
                key={`${appFilter.label} - ${appFilter.labelId} - ${i}`}
                style={{ cursor: "pointer" }}
              >
                <GreyContainer
                  onClick={() => {
                    const appFilterLang: any = getLabel(appFilter);
                    navigate(
                      `/${locale}/${region}/browse?${createSearchParams({
                        brand: "all",
                        sort: "downloads",
                        order: "desc",
                        "fl[]": `${appFilter.criteriaId}+${appFilterLang}`,
                      })}`
                    );
                  }}
                  label={getLabel(appFilter)}
                />
              </Grid>
            ))}
          {applicationCategory !== undefined &&
            applicationCategory?.criterias.length > 11 && (
              <Grid
                item
                xs={1}
                className="whiteBackground"
                style={{ cursor: "pointer" }}
              >
                <GreyContainer
                  label="More..."
                  onClick={() =>
                    navigate(
                      `/${locale}/${region}/browse?${createSearchParams({
                        brand: "all",
                        sort: "downloads",
                        order: "desc",
                      })}`
                    )
                  }
                />
              </Grid>
            )}
        </Grid>
      </ByApplicationBar>
    );
  }

  return <ByApplicationBar t={t}>No Applications</ByApplicationBar>;
};

const ByApplicationBar = ({ children, t }: any) => {
  return (
    <React.Fragment>
      <Box className="ApplicationFilter">
        <Typography variant="h2" className="section-heading" gutterBottom>
          {t("home_page.home_page_headings.by_application")}
        </Typography>
        {children}
      </Box>
    </React.Fragment>
  );
};
