import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Link as LinkR, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

export const ContactUSFloating = () => {
  const { locale, region } = useParams();
  const { t } = useTranslation("common");
  const cookies = new Cookies();
  const contactIdRemove = () => {
    if (cookies.get("commercialContactId")) {
      cookies.remove("commercialContactId");
    }
  };
  return (
    <LinkR
      to={`/${locale}/${region}/contactus`}
      // target="_blank"
      className="floating-container floating-contact"
      id="contactId"
      onClick={() => contactIdRemove()}
    >
      <span className="floating-icon">
        <EmailIcon />
      </span>
      <span className="floating-text">{t("contact_page.title")}</span>
    </LinkR>
  );
};
