import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { MyConfig } from "./API/myConfig";

type pathOptions = {
  pathname: string;
  search: string;
};

interface replaceOptions {
  replace?: boolean;
}

/// This hooks rewirte urls with locale and regions
export const useRelocate = () => {
  const { i18n } = useTranslation("common");
  let navigate = useNavigate();

  function relocate(
    to: string | pathOptions | number,
    options?: replaceOptions
  ) {
    const cookie = new Cookies();
    const regionname = cookie.get("regionname") || "France";

    if (typeof to === "number") {
      navigate(to);
    } else {
      var urlto: string | pathOptions = `/${i18n.language}/${regionname}`;
      if (typeof to === "string") {
        urlto =
          to === "/"
            ? `/${i18n.language}/${regionname}`
            : `/${i18n.language}/${regionname}${to}`;
      } else {
        to.pathname =
          to.pathname === "/"
            ? `/${i18n.language}/${regionname}`
            : `/${i18n.language}/${regionname}${to.pathname}`;
        urlto = to;
      }

      if (options) {
        if (options.replace) {
          navigate(urlto, { replace: true });
        } else {
          navigate(urlto);
        }
      } else {
        navigate(urlto);
      }
    }
  }

  function urlWithoutLocalParam(locationObject: Location) {
    var arr = locationObject.pathname.split("/");
    arr.splice(0, 3);
    const from = `/${arr.join("/")}${locationObject.search}`;
    return from;
  }

  async function login(locationObject: Location) {
    const from = locationObject.pathname + locationObject.search; //urlWithoutLocalParam(locationObject);
    localStorage.setItem("loginstate", from);
    let config = await MyConfig();
    const hostUrl =
      config.REACT_APP_AUTHENTICATIONURL +
      "/oauth/authorize?response_type=code&grant_type=authorization_code&client_id=" +
      encodeURIComponent(config.REACT_APP_AUTH_CLIENT_ID) +
      "&redirect_uri=" +
      encodeURIComponent(config.REACT_APP_URL + "/authenticate") +
      "&scope=" +
      encodeURIComponent("user:projects user:downloads");
    window.location.href = hostUrl;
  }

  function redirect(url: string | number, replace: boolean = false) {
    if (typeof url === "number") {
      navigate(url);
    } else {
      navigate(url, { replace: replace });
    }
  }

  return { relocate, login, urlWithoutLocalParam, redirect };
};

interface IRelocateLink {
  children: any;
  href: string;
  className: string;
}

export const RelocateLink = ({ href, className, children }: IRelocateLink) => {
  const { i18n } = useTranslation("common");
  const cookie = new Cookies();
  const regionname = cookie.get("regionname") || "France";
  return (
    <a href={`/${i18n.language}/${regionname}${href}`} className={className}>
      {children}
    </a>
  );
};
