import { Box, Button, Grid } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../Utils/assets/product.scss";
import { createSearchParams, Navigate, useParams } from "react-router-dom";
import { getProductDetails, updateProductViewCount } from "../API/Products.api";
import { useTranslation } from "react-i18next";
import { useRelocate } from "../relocate";
import { ProductSkeleton } from "../Components/Product/productSkeleton";
import { ProductCarousel } from "../Components/Product/productCarousel";
import { ProductDescription } from "../Components/Product/productDescription";
import { ProductContact } from "../Components/Product/productContact";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";
import React from "react";
import { CultureContext, ICultureContextType } from "../Context/cultureContext";
import { IProductContextType, ProductContext } from "../Context/productContext";
import { ModalPopup } from "../Components/Browse/modalPopup";
import BrowseProvider from "../Context/browseContext";

interface IProduct {
  brands: any;
  criteriaCategories: any;
  fileDescriptors: any;
  imageIds: string[];
  lastUpdated: any;
  principalRegion: string;
  productDescription: string;
  productExternalUrls: any;
  productId: string;
  productReference: any;
  productShortDescription: any;
  productSubTitle: any;
  productTitle: any;
  regions: any;
  technicalName: string;
}

interface IState {
  productData: null | IProduct;
  loading: boolean;
  imgUrls: string[];
}

const initialState = { productData: null, loading: true, imgUrls: [] };

export const Product = () => {
  const { locale, name, region } = useParams();
  const { relocate } = useRelocate();
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState<IState>(initialState);

  const { regions, config } = useContext(CultureContext) as ICultureContextType;

  useEffect(() => {
    i18n.changeLanguage(locale);

    if (name && region && locale && regions && config) {
      getProductDetails(name, region, regions, config, locale).then(
        ({ product, regionId }) => {
          if (product) {
            if (!product.message) {
              updateProductViewCount(product.productId, regionId, locale);
              setData({
                productData: product,
                loading: false,
                imgUrls: product.imageIds,
              });
              return;
            }
            setData({ productData: null, loading: false, imgUrls: [] });
          }
        }
      );
    }
  }, [locale, name, region, i18n, regions, config]);

  const productOfBrand = async () => {
    if (data.productData) {
      const storedCurrrentBrand =  localStorage.getItem("currentBrand");
      let displayBrand =  data.productData.brands[0];
      
      if( storedCurrrentBrand != null) {
        const matchingBrand = data.productData.brands
        .find((brand: {id: string}) => brand.id === storedCurrrentBrand);
        if(matchingBrand !== undefined) displayBrand = matchingBrand;
      }
  
      localStorage.setItem("currentBrand", displayBrand.id);
      relocate({
        pathname: "/browse",
        search: `?${createSearchParams({
          brand: displayBrand.label,
          sort: "downloads",
          order: "desc",
        })}`,
      });
    }
  };

  //when product is loading, then this skeleton will be shown
  if (data.loading) {
    return <ProductSkeleton />;
  }

  if (!data.productData) {
    return (
      <>
        <BrowseProvider>
          <ModalPopup />
        </BrowseProvider>
      </>
    );
  }

  return (
    <>
      <CanonicalStaticTag page={`/product/${name}`} />
      <Box className="main-container product-container">
        <Box className="product-nav-btn">
          <div
            className="space-from-top btn-goback-container"
            style={{ display: "inline-flex" }}
          >
            <Button
              variant="contained"
              className="btn btn-primary btn-normal btn-goback"
              id="productGoBack"
              onClick={() => relocate(-1)}
            >
              {t("common.goback_btn")} <ArrowBackIosNewIcon />
            </Button>
          </div>
          <Button
            variant="contained"
            className="btn btn-default btn-normal btn-more-product"
            id="prodBrands"
            onClick={productOfBrand}
            sx={{ borderRadius: "6px !important" }}
          >
            {t("common.moreproduct_forbrand")}
          </Button>
        </Box>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item lg={3} md={5} sm={12} xs={12} className="p-r-20">
            <ProductCarousel imageIds={data.imgUrls} />
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className="p-r-10">
            <ProductDescription product={data.productData} />
          </Grid>
          <Grid item lg={2} md={5} sm={12} xs={12} className="p-l-10">
            <ProductContact product={data.productData} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
