import { Slider, Typography } from "@mui/material";
import React from "react";
import { FilterCriteria } from "../../../../Types";

interface Props {
  criteria: FilterCriteria;
  type: string;
  selected: string[];
  toggleFilter: any;
}

export const RangeFilter = ({
  criteria,
  type,
  selected,
  toggleFilter,
}: Props) => {
  let selValue = [criteria.minValue, criteria.maxValue];
  if (selected) {
    const valuestring = selected.find((element: any) =>
      element.startsWith(`${criteria.id}$range`)
    );
    if (valuestring) {
      const valuewithrange = valuestring.split("$bim")[0];
      const rangevalue = valuewithrange.split("$range")[1].split("-to-");
      selValue = [parseInt(rangevalue[0]), parseInt(rangevalue[1])];
    }
  }

  const valuetext = (value: any) => {
    return `${value}${criteria.unit}`;
  };

  return (
    <React.Fragment key={criteria.id}>
      <Typography component="div" style={{ padding: "0 15px" }}>
        {type.toLowerCase() === "advanced" && <h4>{criteria.label}</h4>}
        <Typography component="div" className="filter-slider-values">
          <Typography>{criteria.minValue}</Typography>
          <Typography>({criteria.unit})</Typography>
          <Typography>{criteria.maxValue}</Typography>
        </Typography>
        <Slider
          min={criteria.minValue}
          max={criteria.maxValue}
          step={1}
          marks
          value={selValue}
          getAriaValueText={valuetext}
          onChange={(event: Event, newValue: number | number[]) => {
            if (typeof newValue !== "number") {
              toggleFilter(
                criteria.type,
                criteria.id,
                criteria.label,
                "range",
                criteria.type,
                newValue[0].toString(),
                newValue[1].toString(),
                false
              );

              toggleFilter(
                criteria.type,
                `${
                  criteria.id
                }$range${newValue[0].toString()}-to-${newValue[1].toString()}$bim${
                  criteria.id
                }_${criteria.label}`
              );
            }
          }}
        />{" "}
      </Typography>
    </React.Fragment>
  );
};
