import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";

export const LegalPolicy = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <CanonicalStaticTag page="/legal-policy" />
      <Box className="main-container legal-privacy-cookie-container">
        <Typography component="div" className="space-from-top"></Typography>
        <Typography component="h2" className="heading-two">
          {" "}
          {t("legal_notice_page.title")}{" "}
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          <strong>
            1) {t("legal_notice_page.heading.by_publisher.title")}{" "}
          </strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.description1")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.description2")}
        </Typography>

        <Typography component="p">
          <strong>
            {" "}
            {t("legal_notice_page.heading.by_publisher.sub_title")}{" "}
          </strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.company_form")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.address")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.capital")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.registration_number")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.vat_number")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.publication_director")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.by_publisher.credits")}
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          <strong>
            2)
            {t(
              "legal_notice_page.heading.responsibility_and_liability.title"
            )}{" "}
          </strong>
        </Typography>

        <Typography component="p">
          {t(
            "legal_notice_page.heading.responsibility_and_liability.description1"
          )}
        </Typography>

        <Typography component="p">
          {t(
            "legal_notice_page.heading.responsibility_and_liability.description2"
          )}
        </Typography>

        <Typography component="p">
          {t(
            "legal_notice_page.heading.responsibility_and_liability.description3"
          )}
        </Typography>

        <Typography component="p">
          {t(
            "legal_notice_page.heading.responsibility_and_liability.description4"
          )}
        </Typography>

        <Typography component="p">
          {t(
            "legal_notice_page.heading.responsibility_and_liability.description5"
          )}
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          <strong>
            3) {t("legal_notice_page.heading.intellectual_property.title")}{" "}
          </strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.intellectual_property.description1")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.intellectual_property.description2")}

          <Link href="/fr/France/licence" rel="noopener" target="_blank">
            &nbsp; https://bimlibrary.saint-gobain.com/en/France/licence
          </Link>
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          <strong>4) {t("legal_notice_page.heading.cookies.title")} </strong>
        </Typography>

        <Typography component="p">
          <strong>{t("legal_notice_page.heading.cookies.subtitle1")}</strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.cookies.description1")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.cookies.description2")}
          <Link
            rel="noopener"
            href="https://bimlibrary.saint-gobain.com/en/France/cookie"
            target="_blank"
          >
            &nbsp; {t("legal_notice_page.heading.cookies.page")}
          </Link>{" "}
          {t("legal_notice_page.heading.cookies.description3")}
          <Link
            rel="noopener"
            href="https://bimlibrary.saint-gobain.com"
            target="_blank"
          >
            &nbsp; https://bimlibrary.saint-gobain.com
          </Link>{" "}
          {t("legal_notice_page.heading.cookies.description4")}
        </Typography>

        <Typography component="p">
          <strong>{t("legal_notice_page.heading.cookies.subtitle2")}</strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.cookies.description5")}
        </Typography>
        <List className="cookiePolicyList">
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {" "}
              <Link
                rel="noopener"
                href="http://windows.microsoft.com/en-gb/windows7/block-enable-or-allow-cookies"
                target="_blank"
              >
                {" "}
                {t("legal_notice_page.heading.cookies.internetExp")}
              </Link>{" "}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {" "}
              <Link
                rel="noopener"
                href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en"
                target="_blank"
              >
                {t("legal_notice_page.heading.cookies.Chrome")}
              </Link>{" "}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {" "}
              <Link
                rel="noopener"
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
              >
                {t("legal_notice_page.heading.cookies.firefox")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {" "}
              <Link
                rel="noopener"
                href="http://help.opera.com/Windows/10.20/fr/cookies.html"
                target="_blank"
              >
                {t("legal_notice_page.heading.cookies.opera")}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {" "}
              <Link
                rel="noopener"
                href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html"
                target="_blank"
              >
                {t("legal_notice_page.heading.cookies.safari")}
              </Link>
            </ListItemText>
          </ListItem>
        </List>

        <Typography component="p">
          {t("legal_notice_page.heading.cookies.description6")}
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          <strong>
            5) {t("legal_notice_page.heading.data_collected.title")}
          </strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.data_collected.description1")}
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.data_collected.description2.sentence1")}
          <u>
            &nbsp;
            {t(
              "legal_notice_page.heading.data_collected.description2.yourData"
            )}
            &nbsp;
          </u>
          {t("legal_notice_page.heading.data_collected.description2.sentence2")}
        </Typography>

        <Typography variant="h3" gutterBottom component="div">
          <strong>
            6){t("legal_notice_page.heading.licence_agreement.title")}
          </strong>
        </Typography>

        <Typography component="p">
          {t("legal_notice_page.heading.licence_agreement.description")}
          <Link rel="noopener" target="_blank" href="/fr/France/licence">
            &nbsp; {t("legal_notice_page.heading.licence_agreement.licence")}
          </Link>
          .
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          <strong>7) {t("legal_notice_page.heading.credits.title")}</strong>
        </Typography>
        <Typography component="p">
          {t("legal_notice_page.heading.credits.description1")}
        </Typography>
        <Typography component="p">
          {t("legal_notice_page.heading.credits.description2")}
        </Typography>
      </Box>
    </>
  );
};
