export const validate = (values: any) => {
  let errors: any = {};
  if (!values.fullName) {
    errors.fullName = "This field is required.";
  }

  if (!values.email) {
    errors.email = "Email address is required.";
  } else if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = "Email address is invalid.";
  }

  // phone number not required
  // if (!values.phoneNumber) {
  //   errors.phoneNumber = "Phone Number is required.";
  // } else if (isNaN(values.phoneNumber)) {
  //   errors.phoneNumber = "Please enter Number.";
  // }

  // if (!values.jobTitle) {
  //   errors.jobTitle = "This field is required.";
  // }

  if (!values.country || values.country == '') {
    errors.country = "This field is required.";
  }

  if (!values.company) {
    errors.company = "This field is required.";
  }

  if (!values.subject) {
    errors.subject = "This field is required.";
  }

  if (!values.message) {
    errors.message = "This field is required.";
  }

  if (!values.token) {
    errors.token = "This field is required.";
  }

  if (
    !values.fullName ||
    !values.email ||    
    !values.country ||
    !values.company ||
    !values.subject ||
    !values.message ||
    !values.brand ||
    !values.token
  ) {
    errors.token = "This field is required.";
  }

  if (!values.brand || values.brand == '') {
    errors.brand = "This field is required.";
  }

  return errors;
};
