import { MyConfig } from "../../API/myConfig";

export const LoginIframe = async (callBack: any) => {
  const config = await MyConfig();
  const loginurl =
    config.REACT_APP_AUTHENTICATIONURL +
    "/oauth/authorize?response_type=code&grant_type=authorization_code&client_id=" +
    encodeURIComponent(config.REACT_APP_AUTH_CLIENT_ID) +
    "&redirect_uri=" +
    encodeURIComponent(config.REACT_APP_URL + "/authenticate") +
    "&scope=" +
    encodeURIComponent("user:projects user:downloads");

  window.open(loginurl, "embedpopup", "left=300,top=100,width=520,height=420");

  const messageListener = (event: any) => {
    if (event.origin !== config.REACT_APP_URL) {
      return callBack("");
    }

    if (event.data.isToken) {
      callBack(event.data.token);
      window.removeEventListener("message", messageListener);
    }
  };
  window.addEventListener("message", messageListener, false);
};
