import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../Resources/productCard.scss";
import { MyConfig } from "../../../../API/myConfig";
import { useRelocate } from "../../../../relocate";
import { useParams } from "react-router-dom";
import { sendDataToGTM } from "../../../../Utils/gtmConfig";

interface Props {
  product: any;
  brands: any;
  config: any;
  brand: string;
}

export const ProductCard = ({ product, brands, config, brand }: Props) => {
  const { locale, region } = useParams();
  const { redirect } = useRelocate();

  const setBrokenImg = (event: any) => {
    return (event.target.src = `${config.REACT_APP_URL}/assets/imgs/dummy-img.png`);
  };

  const brandO = brands.find(
    (br: any) => br.label.toLowerCase() === brand.toLowerCase()
  );

  return (
    <React.Fragment>
      <ListItem className="product-card-spacing">
        <div className="custom-grid-item">
          <Card className={`product-card`}>
            <Tooltip title={product.label} placement="bottom" arrow>
              <CardHeader subheader={product.label}>
                <Typography gutterBottom>{product.label}</Typography>
              </CardHeader>
            </Tooltip>
            <div className="img-container">
              <img
                src={`${config.REACT_APP_API_URL}/anonymous/image/hq/${product.imageIds[0]}`}
                height="194"
                className="img-responsive"
                alt="Product"
                loading="lazy"
                onClick={() => {
                  redirect(
                    `/${locale}/${region}/embed/${brand}/product/${product.name}`
                  );
                }}
              />
            </div>
            <CardContent>
              <Tooltip title={brandO["label"]} placement="top" arrow>
                <img
                  src={
                    brandO &&
                    `${config.REACT_APP_BIMDATABASE_API_URL}${brandO["thumbnailImage"]}`
                  }
                  alt={brandO["label"]}
                  className="img-responsive"
                  onError={setBrokenImg}
                />
              </Tooltip>
            </CardContent>{" "}
            <Divider className="product-card-divider" />
          </Card>
        </div>
      </ListItem>
    </React.Fragment>
  );
};

export default ProductCard;
