import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";

interface Props {
  productCount: number;
}

export const SortBar = ({ productCount }: Props) => {
  const { t } = useTranslation("common");
  let [searchParams, setSearchParams] = useSearchParams();
  const order: string = searchParams.get("order") || "desc";
  const sort: string = searchParams.get("sort") || "downloads";

  const handleFilterChange = (event: SelectChangeEvent<any>) => {
    if (event.target.value === "TITLEA") {
      searchParams.set("sort", `title`);
      searchParams.set("order", `asc`);
    } else if (event.target.value === "TITLED") {
      searchParams.set("sort", `title`);
      searchParams.set("order", `desc`);
    } else {
      searchParams.set("sort", event.target.value.toLowerCase());
      searchParams.set("order", `desc`);
    }

    setSearchParams(searchParams);
  };
  let sortBy = sort.toUpperCase();

  if (sortBy === "TITLE") {
    if (order.toUpperCase() === "ASC") {
      sortBy = "TITLEA";
    } else {
      sortBy = "TITLED";
    }
  }

  return (
    <Typography
      component="div"
      className={"product-indicators"}
      style={{ paddingTop: "20px" }}
    >
      <Typography>
        {t("browse_page.total_products")} {productCount}
      </Typography>
      <Typography component="div">
        <FormControl className="filterDropdown">
          <Select
            id="sortBarFilter"
            value={sortBy}
            onChange={(e) => handleFilterChange(e)}
            IconComponent={() => (
              <KeyboardArrowDownIcon className="sort-icon" />
            )}
          >
            {/* <MenuItem disabled value="">
              <em> {t("browse_page.product_filter.filter")} </em>
            </MenuItem> */}

            <MenuItem key={"Most Downloaded"} value={"DOWNLOADS"}>
              <TrendingUpIcon />
              {t("browse_page.product_filter.mostDownload")}
            </MenuItem>
            <MenuItem key={"Most Recent"} value="LAST_UPDATE">
              <AccessTimeIcon />
              {t("browse_page.product_filter.mostRecent")}
            </MenuItem>
            <MenuItem key="From A to Z" value="TITLEA">
              <ArrowDownwardIcon />
              {t("browse_page.product_filter.ascending_alphabetical_order")}
            </MenuItem>
            <MenuItem key="From Z to A" value="TITLED">
              <ArrowUpwardIcon />
              {t("browse_page.product_filter.descending_alphabetical_order")}
            </MenuItem>
          </Select>
        </FormControl>
      </Typography>
    </Typography>
  );
};
