import "../../Utils/assets/homeButton.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

export const HomeButton = () => {
  let { locale, region } = useParams();
  const { t } = useTranslation("common");

  if (!region) {
    const cookie = new Cookies();
    region = cookie.get("regionname") || "France";
  }

  return (
    <>
      <Typography component="div" className="logo-placeholder">
        <Typography component="div" style={{ cursor: "pointer",marginRight:"40px" }}>
          <Link to={`/${locale}/${region}`} className="logo-image">
            <img
              src="/assets/imgs/sgLogo.jpg"
              alt="Saint Gobain Logo"
              className="logo"
              id="saintGobainLogo"
            />
          </Link>
        </Typography>

        {/* Blue Ribbon below header */}
        <Typography component="div" className="project-name">
          {/* <Typography component="span">{t("header.title")}</Typography> */}
          <Typography component="h1" id="bimLibHeader">
            {t("header.title")}
          </Typography>
        </Typography>
      </Typography>
    </>
  );
};
