import React from "react";
import { Button } from "@mui/material";
import "../../Utils/assets/greyContainer.scss";

export const GreyContainer = (props: { label: string; onClick: any }) => {
  return (
    <>
      <div className="GreyContainer">
        <div className="GreyContainerText">
          <Button onClick={props.onClick} id="byApplication">{props.label}</Button>
        </div>
      </div>
    </>
  );
};
