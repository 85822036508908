import {
  Box,
  Grid,
  CircularProgress,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MyConfig } from "../../../../API/myConfig";
import { useSanitizedHtml } from "../../../../Utils/hooks/useSanitizedHtml";
import "../../../Resources/productDescription.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ padding: "30px 0px 30px 0px" }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const ProductDescription = (props: any) => {
  const [value, setValue] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [config, setConfig] = useState<any>("");
  const sanitizedProductDescription: string = useSanitizedHtml(props.product.productDescription);

  const product = props.product;
  const { t } = useTranslation("common");

  useEffect(() => {
    const fetchConfig = async () => {
      let config = await MyConfig();
      setConfig(config);
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    if (product) {
      setLoading(false);
    }
  }, [props, product]);

  const allyProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Add default image when there is no img returing from an API
  const addDefaultSrc = (ev: any) => {
    ev.target.src = "../assets/imgs/dummy-img.png";
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress
          color="primary"
          style={{ margin: "auto", display: "block" }}
        />
      ) : (
        <Box className="product-description-container">
          <Box
            className="description-tab-container"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="product description tab"
              indicatorColor="primary"
              variant="scrollable"
            >
              <Tab
                label={t("product_description_page.description")}
                {...allyProps(0)}
              />
              <Tab
                label={t("product_description_page.categories")}
                {...allyProps(1)}
              />
              <Tab
                label={t("product_description_page.links")}
                {...allyProps(2)}
              />
              <Tab
                label={t("product_description_page.market")}
                {...allyProps(3)}
              />
              <Tab
                label={t("product_description_page.files")}
                {...allyProps(4)}
              />
            </Tabs>
          </Box>

          {/*Description panel*/}
          <TabPanel value={value} index={0}>
            <div className="product-description">
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedProductDescription }}
              />
            </div>
          </TabPanel>

          {/*Categories panel*/}
          <TabPanel value={value} index={1}>
            {product.criteriaCategories.length > 0 ? (
              <div className="categories-table">
                <Grid container className="grid-table">
                  {product.criteriaCategories?.map((category: any) => {
                    return (
                      <Grid
                        container
                        item
                        key={category.criteriaCategoryId}
                        className="item"
                        xs={12}
                      >
                        <Grid item xs={4}>
                          <Typography className="f-w-400">
                            {category.label} :
                          </Typography>
                        </Grid>
                        <Grid container item xs={8}>
                          {category.criterias?.map((subCategoryValue: any) => {
                            return (
                              <Grid
                                container
                                item
                                key={subCategoryValue.criteriaId}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {subCategoryValue.criteriaLabel}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  {subCategoryValue.criteriaValues?.map(
                                    (criteriaValues: any) => {
                                      return (
                                        <Typography key={criteriaValues.label}>
                                          {criteriaValues.label}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            ) : (
              <Typography className="no-content">
                {" "}
                {t("product_description_page.no_category_forproduct")}
              </Typography>
            )}
          </TabPanel>

          {/*Links panel*/}
          <TabPanel value={value} index={2}>
            {product.productExternalUrls.length > 0 ? (
              <div className="links-table">
                <table>
                  <tbody>
                    {product.productExternalUrls?.map((url: any) => {
                      return (
                        <tr key={url.label}>
                          <td>{url.label}</td>
                          <td>
                            <Link href={url.productUrl} target="_blank">
                              {url.productUrl}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <Typography className="no-content">
                {" "}
                {t("product_description_page.nolinks_product")}
              </Typography>
            )}
          </TabPanel>

          {/*Region panel*/}
          <TabPanel value={value} index={3}>
            {product.regions.length > 0 ? (
              <>
                <Typography>
                  {" "}
                  {t("product_description_page.product_is_available")}{" "}
                </Typography>
                <ul>
                  {product.regions?.map((region: any) => {
                    return <li key={region.id}>{region.label}</li>;
                  })}
                </ul>
              </>
            ) : (
              <Typography className="no-content">
                {t("product_description_page.available_product_region")}{" "}
              </Typography>
            )}
          </TabPanel>

          {/*Files panel*/}
          <TabPanel value={value} index={4}>
            {product.fileDescriptors.length > 0 ? (
              <>
                <Typography>
                  {t("product_description_page.bim_obj_available")}
                </Typography>
                <div className="files-table">
                  <table>
                    <tbody>
                      {product.fileDescriptors?.map((file: any) => {
                        return (
                          <tr key={file.fileId}>
                            <td>{file.fileName}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`${config.REACT_APP_BIMDATABASE_API_URL}/image/${file.fileFormatImage}?filename=${file.fileFormatImage}&size=thumbnail`}
                                  alt={file.fileFormat + "Logo"}
                                  onError={addDefaultSrc}
                                  loading="lazy"
                                />
                                {file.fileFormatLabel}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <Typography className="no-content">
                {t("product_description_page.no_associated_file")}
              </Typography>
            )}
          </TabPanel>
        </Box>
      )}
    </>
  );
};
