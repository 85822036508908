import { forEach } from "lodash";
import Cookies from "universal-cookie";
import {en, fr, de, es, hi,id, it, nl, pt, ru, sq, zh} from "./i18next/language.model";


export const readCookie = (cookieName: string) => {
  const cookies = new Cookies();
  const ckooie = cookies.get(cookieName);

  if (ckooie) {
    return ckooie;
  } else {
    return "";
  }
};

export const languageStaticObject: string[] = [
  "en",
  "de",
  "it",
  "nl",
  "pt",
  "ru",
  "fr",
  "sq",
  "es",
  "zh",
];

export const regionStaticObjectHavingProduct: string[] = [
  "DN",
  "CH",
  "ME",
  "SR",
  "CH",
  "NL",
  "CH",
  "MD",
  "HR",
  "ID",
  "GB",
  "HU",
  "BI",
  "SW",
  "CO",
  "US",
  "ES",
  "HK",
  "MY",
  "MK",
  "ME",
  "BE",
  "FI",
  "PO",
  "BG",
  "KO",
  "SE",
  "LU",
  "CZ",
  "KO",
  "NO",
  "TU",
  "BR",
  "CA",
  "RU",
  "PO",
  "FR",
  "DE",
  "AR",
  "IT",
  "AL",
  "RO",
  "PE",
  "UK",
  "IN",
  "ZA",
  "MA",
];

export const getLanguageId = (languageName: string) => {
  switch (languageName) {
    case "English":
      return "en";
      break;

    case "German":
      return "de";
      break;

    case "French":
      return "fr";
      break;

    case "Russian":
      return "ru";
      break;

    case "Italian":
      return "it";
      break;

    case "Dutch":
      return "nl";
      break;

    case "Portuguese":
      return "pt";
      break;

    case "Albanian":
      return "sq";
      break;

    case "Spanish":
      return "es";
      break;

    case "Indonesian":
      return "id";
      break;

    default:
      return "en";
  }
};

export const getLanguageFlag = (languageId: string) => {
  switch (languageId) {
    case "en":
      return "English";
    case "de":
      return "German";
    case "fr":
      return "French";
    case "ru":
      return "Russian";
    case "it":
      return "Italian";
    case "nl":
      return "Dutch";
    case "pt":
      return "Portuguese";
    case "sq":
      return "Albanian";
    case "es":
      return "Spanish";
    case "id":
      return "Indonesian";
    default:
      return "en";
  }
};

export const getLanguageCode = (languageName: string) => {
  switch(languageName) {
    case "en":
      return en;
    case "de":
      return de;
    case "fr":
      return fr;
    case "ru":
      return ru;
    case "it":
      return it;
    case "nl":
      return nl;
    case "pt":
      return pt;
    case "sq":
      return sq;
    case "es":
      return es;
    case "id":
      return id;
    case "zh":
      return zh;
    case "hi":
      return hi;
    default:
      return en; 
  }
}
 
export const t = (key: string, languageName: string) => {
  let keyans = "";
  if (key) {
    let lengobject: any = getLanguageCode(languageName);
    const keySplit = key.split(".");
    keyans = key;
    if (keySplit.length > 0) {
      forEach(keySplit, (ks: any) => {
        if (keyans === key) {
          keyans = lengobject[ks];
        } else {
          keyans = keyans[ks];
        }
      });
    }
  }
  return keyans;
};

export const regionStaticObject = [
  {
    className: "Region",
    longName: "Europe",
    oid: "15",
    parentOids: ["0"],
    technicalName: "EUR",
    translation: "Europe",
  },
  {
    className: "Region",
    longName: "Asia Pacific",
    oid: "16",
    parentOids: ["0"],
    technicalName: "ASIAP",
    translation: "Asia Pacific",
  },
  {
    className: "Region",
    longName: "United Kingdom",
    oid: "17",
    parentOids: ["15"],
    technicalName: "GBR",
    translation: "United Kingdom",
  },
  {
    className: "Region",
    longName: "France",
    oid: "18",
    parentOids: ["15"],
    technicalName: "FRA",
    translation: "France",
  },
  {
    className: "Region",
    longName: "Italy",
    oid: "19",
    parentOids: ["15"],
    technicalName: "ITA",
    translation: "Italy",
  },
  {
    className: "Region",
    longName: "Switzerland",
    oid: "20",
    parentOids: ["15"],
    technicalName: "CHE",
    translation: "Switzerland",
  },
  {
    className: "Region",
    longName: "North America",
    oid: "43",
    parentOids: ["0"],
    technicalName: "NA",
    translation: "North America",
  },
  {
    className: "Region",
    longName: "United States",
    oid: "44",
    parentOids: ["43"],
    technicalName: "USA",
    translation: "United States",
  },
  {
    className: "Region",
    longName: "Portugal",
    oid: "74106",
    parentOids: ["15"],
    technicalName: "POR",
    translation: "Portugal",
  },
  {
    className: "Region",
    longName: "Belgium",
    oid: "74107",
    parentOids: ["15"],
    technicalName: "BEL",
    translation: "Belgium",
  },
  {
    className: "Region",
    longName: "Denmark",
    oid: "74108",
    parentOids: ["15"],
    technicalName: "DNK",
    translation: "Denmark",
  },
  {
    className: "Region",
    longName: "Luxembourg",
    oid: "74109",
    parentOids: ["15"],
    technicalName: "LUX",
    translation: "Luxembourg",
  },
  {
    className: "Region",
    longName: "Bulgaria",
    oid: "84701",
    parentOids: ["15"],
    technicalName: "BGR",
    translation: "Bulgaria",
  },
  {
    className: "Region",
    longName: "Albania",
    oid: "84702",
    parentOids: ["15"],
    technicalName: "ALB",
    translation: "Albania",
  },
  {
    className: "Region",
    longName: "Argentina",
    oid: "84703",
    parentOids: ["15"],
    technicalName: "ARG",
    translation: "Argentina",
  },
  {
    className: "Region",
    longName: "Bosnia and Herzegovina",
    oid: "84704",
    parentOids: ["15"],
    technicalName: "BIH",
    translation: "Bosnia and Herzegovina",
  },
  {
    className: "Region",
    longName: "Brazil",
    oid: "84705",
    parentOids: ["15"],
    technicalName: "BRA",
    translation: "Brazil",
  },
  {
    className: "Region",
    longName: "China",
    oid: "84706",
    parentOids: ["15"],
    technicalName: "CHN",
    translation: "China",
  },
  {
    className: "Region",
    longName: "Mexico",
    oid: "84708",
    parentOids: ["15"],
    technicalName: "MEX",
    translation: "Mexico",
  },
  {
    className: "Region",
    longName: "HongKong",
    oid: "84709",
    parentOids: ["15"],
    technicalName: "HKG",
    translation: "Hong Kong",
  },
  {
    className: "Region",
    longName: "Czech Republic",
    oid: "84710",
    parentOids: ["15"],
    technicalName: "CZE",
    translation: "Czech Republic",
  },
  {
    className: "Region",
    longName: "Spain",
    oid: "84711",
    parentOids: ["15"],
    technicalName: "ESP",
    translation: "Spain",
  },
  {
    className: "Region",
    longName: "Finland",
    oid: "84712",
    parentOids: ["15"],
    technicalName: "FIN",
    translation: "Finland",
  },
  {
    className: "Region",
    longName: "Croatia",
    oid: "84713",
    parentOids: ["15"],
    technicalName: "HRV",
    translation: "Croatia",
  },
  {
    className: "Region",
    longName: "Hungary",
    oid: "84714",
    parentOids: ["15"],
    technicalName: "HUN",
    translation: "Hungary",
  },
  {
    className: "Region",
    longName: "India",
    oid: "84715",
    parentOids: ["15"],
    technicalName: "IND",
    translation: "India",
  },
  {
    className: "Region",
    longName: "Morocco",
    oid: "84716",
    parentOids: ["96123"],
    technicalName: "MAR",
    translation: "Morocco",
  },
  {
    className: "Region",
    longName: "Moldova",
    oid: "84717",
    parentOids: ["15"],
    technicalName: "MDA",
    translation: "Moldova",
  },
  {
    className: "Region",
    longName: "North Macedonia",
    oid: "84718",
    parentOids: ["15"],
    technicalName: "MKD",
    translation: "North Macedonia",
  },
  {
    className: "Region",
    longName: "Netherlands",
    oid: "84719",
    parentOids: ["15"],
    technicalName: "NLD",
    translation: "Netherlands",
  },
  {
    className: "Region",
    longName: "Norway",
    oid: "84720",
    parentOids: ["15"],
    technicalName: "NOR",
    translation: "Norway",
  },
  {
    className: "Region",
    longName: "Peru",
    oid: "84721",
    parentOids: ["15"],
    technicalName: "PER",
    translation: "Peru",
  },
  {
    className: "Region",
    longName: "Poland",
    oid: "84722",
    parentOids: ["15"],
    technicalName: "POL",
    translation: "Poland",
  },
  {
    className: "Region",
    longName: "Romania",
    oid: "84723",
    parentOids: ["15"],
    technicalName: "ROU",
    translation: "Romania",
  },
  {
    className: "Region",
    longName: "Russia",
    oid: "84724",
    parentOids: ["15"],
    technicalName: "RUS",
    translation: "Russia",
  },
  {
    className: "Region",
    longName: "Serbia",
    oid: "84725",
    parentOids: ["15"],
    technicalName: "SRB",
    translation: "Serbia",
  },
  {
    className: "Region",
    longName: "Sweden",
    oid: "84726",
    parentOids: ["15"],
    technicalName: "SWE",
    translation: "Sweden",
  },
  {
    className: "Region",
    longName: "Turkey",
    oid: "84727",
    parentOids: ["96123"],
    technicalName: "TUR",
    translation: "Turkey",
  },
  {
    className: "Region",
    longName: "Canada",
    oid: "84728",
    parentOids: ["15"],
    technicalName: "CAN",
    translation: "Canada",
  },
  {
    className: "Region",
    longName: "South Africa",
    oid: "84729",
    parentOids: ["15"],
    technicalName: "ZAF",
    translation: "South Africa",
  },
  {
    className: "Region",
    longName: "Chile",
    oid: "84730",
    parentOids: ["15"],
    technicalName: "CHL",
    translation: "Chile",
  },
  {
    className: "Region",
    longName: "Indonesia",
    oid: "89396",
    parentOids: ["15"],
    technicalName: "IDN",
    translation: "Indonesia",
  },
  {
    className: "Region",
    longName: "International",
    oid: "90150",
    parentOids: ["0"],
    technicalName: "INT",
    translation: "International",
  },
  {
    className: "Region",
    longName: "South Korea",
    oid: "90154",
    parentOids: ["15"],
    technicalName: "KOR",
    translation: "South Korea",
  },
  {
    className: "Region",
    longName: "Malaysia",
    oid: "95953",
    parentOids: ["15"],
    technicalName: "MYS",
    translation: "Malaysia",
  },
  {
    className: "Region",
    longName: "Germany",
    oid: "96118",
    parentOids: ["15"],
    technicalName: "DEU",
    translation: "Germany",
  },
  {
    className: "Region",
    longName: "Kosovo",
    oid: "96119",
    parentOids: ["15"],
    technicalName: "KOS",
    translation: "Kosovo",
  },
  {
    className: "Region",
    longName: "Colombia",
    oid: "96120",
    parentOids: ["43"],
    technicalName: "COL",
    translation: "Colombia",
  },
  {
    className: "Region",
    longName: "Ukraine",
    oid: "96121",
    parentOids: ["15"],
    technicalName: "UKR",
    translation: "Ukraine",
  },
  {
    className: "Region",
    longName: "South East Asia",
    oid: "96122",
    parentOids: ["89396"],
    technicalName: "SEA",
    translation: "South East Asia",
  },
  {
    className: "Region",
    longName: "Middle East",
    oid: "96123",
    parentOids: ["0"],
    technicalName: "MEA",
    translation: "Middle East",
  },
];

export const encryptUserId = (email: string | null) => {
  const CryptoJS = require("crypto-js");
  // Encrypt
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(email),
    "bimlib user email"
  ).toString();
  return ciphertext;
  // To Decrypt use below code
  // let bytes = CryptoJS.AES.decrypt(ciphertext, "bimlib user email");
  // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
