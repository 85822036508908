import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { map } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { FilterCategoryEle } from "./filterCategory";
import "../../Utils/assets/filters.scss";
import { FilterTags } from "./filterTags";
import { ListBrand } from "./filterBrand";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createSearchParams,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRelocate } from "../../relocate";
import { FilterCategory } from "../../Types";
import { createFilters } from "../../Utils/filter.util";
import { BrowseContext, IBrowseContextType } from "../../Context/browseContext";
import { IToggleListFilter, IToggleRangeFilter } from "./util/filterType";

export const Filter = () => {
  const { locale = "en", region = "France" } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedBrand = searchParams.get("brand") || "all";
  const selectedFormat = searchParams.getAll("format") || [];
  const selectedSearch = searchParams.getAll("search") || [];
  const [expanded, setExpanded] = useState<string | false>();
  const { loading, products } = useContext(BrowseContext) as IBrowseContextType;
  const { relocate } = useRelocate();
  const [displayFilters, setDisplayFilters] = useState<FilterCategory[]>();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (products && selectedBrand) {
      const filters = createFilters(
        products.criteriaCategoryList,
        products.formatCriteria,
        selectedBrand
      );
      filters.length > 0 &&
        setDisplayFilters(filters);
    }
  }, [products, selectedBrand]);

  // while filter is loading, this skeleton will be shown
  if (loading) {
    return (
      <>
        <Skeleton
          width="25%"
          sx={{ mb: "20px", display: "inline-block", mt: "14px" }}
        />
        <Skeleton
          width="25%"
          sx={{
            mb: "20px",
            display: "inline-block",
            float: "right",
            mt: "14px",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={30}
          sx={{ mb: "15px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={30}
          sx={{ mb: "15px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={30}
          sx={{ mb: "15px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={30}
          sx={{ mb: "15px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={30}
          sx={{ mb: "15px" }}
        />
      </>
    );
  }

  const toggleRangeFilter = ({
    filterType,
    criteriaId,
    tagLabel,
    from,
    to,
  }: IToggleRangeFilter) => {
    const tags = searchParams
      .getAll("fr[]")
      .filter((tag) => !tag.includes(criteriaId));
    searchParams.delete("fr[]");
    for (const tag of tags) searchParams.append("fr[]", tag);
    searchParams.append("fr[]", `${criteriaId}+${tagLabel}+${from}+${to}`);
    setSearchParams(searchParams);
  };

  const toggleListFilter = ({
    filterType,
    criteriaId,
    tagLabel,
    criteriaType,
     id,
    type,
  }: IToggleListFilter) => {
    if (criteriaType === "format") {
      searchParams.append("ff[]", `${criteriaId}+${tagLabel}`);
     } else if (type === "ADVANCED") {
      searchParams.append("fa[]", `${id}+${criteriaId}+${tagLabel}`);
    } else {
      searchParams.append("fl[]", `${criteriaId}+${tagLabel}`);
    }
    setSearchParams(searchParams);
  };

  const deleteFilter = (filterType: string, criteriaId: string, type?: string) => {
    if (filterType === "list") {
      if (type === "ADVANCED") {
        const tags = searchParams
        .getAll("fa[]")
        .filter((tag) => !tag.includes(criteriaId));
        searchParams.delete("fa[]");
        for (const tag of tags) searchParams.append("fa[]", tag);
      } else {
        const tags = searchParams
        .getAll("fl[]")
        .filter((tag) => !tag.includes(criteriaId));
        searchParams.delete("fl[]");
        for (const tag of tags) searchParams.append("fl[]", tag);
      }     
    } else if (filterType === "format") {
      const tags = searchParams
        .getAll("ff[]")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("ff[]");
      for (const tag of tags) searchParams.append("ff[]", tag);
    } else if (filterType === "search") {
      const tags = searchParams
        .getAll("search")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("search");
      for (const tag of tags) searchParams.append("search", tag);      
    } else if (filterType === "range") {
      const tags = searchParams
        .getAll("fr[]")
        .filter((tag) => !tag.includes(criteriaId));
      searchParams.delete("fr[]");
      for (const tag of tags) searchParams.append("fr[]", tag);
    }
    setSearchParams(searchParams);
    setExpanded(false);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Box className="filterCont">
        <Box className="filter-header">
          <Typography className="filter-title">
            {" "}
            {t("browse_page.filters")}
          </Typography>
          <Button
            className="filter-reset"
            id="resetButton"
            onClick={() => {
              relocate({
                pathname: "/browse",
                search: `?${createSearchParams({
                  brand: "all",
                  sort: "downloads",
                  order: "desc",
                })}`,
              });
            }}
          >
            {t("browse_page.reset_all")}
          </Button>
        </Box>
        <FilterTags
          selectedFormat={selectedFormat}
          selectedBrand={selectedBrand}
          deleteFilter={deleteFilter}
          region={region}
          locale={locale}
          selectedSearch={selectedSearch}
          category={displayFilters}
        />
        <Box className="filter-list-element">
          <Accordion
            variant="outlined"
            className="filter-element"
            defaultExpanded={true}
            style={{ display: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              style={{ alignItems: "center" }}
            >
              {/* <ListIcon /> */}
              <Typography>{t("download.brand")}</Typography>
            </AccordionSummary>
            <AccordionDetails className="filter-content">
              <ListBrand
                brands={products?.brandList}
                selectedBrand={selectedBrand}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="filter-list-element">
          {map(displayFilters, (dspCetegory: FilterCategory) => {
            return (
              <FilterCategoryEle
                category={dspCetegory}
                key={dspCetegory.id}
                selectedCriteria={searchParams.toString()}
                toggleRangeFilter={toggleRangeFilter}
                toggleListFilter={toggleListFilter}
                selectedFormat={selectedFormat}
                region={region}
                locale={locale}
                expanded={expanded}
                setExpanded={setExpanded}
                handleChange={handleChange}
              />
            );
          })}
        </Box>
      </Box>
    </React.Fragment>
  );
};
