import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { MyConfig } from "./API/myConfig";
import { HomePage } from "./Pages/homePage";
import { Browse } from "./Pages/browse";
import { Product } from "./Pages/product";
import { Download } from "./Pages/download";
import { CookiePolicy } from "./Pages/cookiePolicy";
import { CookiePreference } from "./Components/CookieSetting/cookiePreference";
import ScrollToTop from "./Components/Common/ScrollToTop";
import { SessionTime } from "./Components/Common/SessionTime";
import { Authenticate } from "./Pages/authenticate";
import { ContactUs } from "./Pages/contactUs";
import { Sitemap } from "./Pages/sitemap";
import { PrivacyPolicy } from "./Pages/privacyPolicy";
import { LegalPolicy } from "./Pages/legalPolicy";
import { Logout } from "./Components/Header/logout";
import { PageNotFound } from "./Pages/pageNotFound";
import { Maintenance } from "./Pages/maintenance";
import { EmbedEcophon } from "./Embed/Pages/Ecophon";
import { EmbedEcophonProduct } from "./Embed/Pages/Ecophon/product";
import { EmbedCatalog } from "./Embed/Pages/Catalog";
import { EmbedCatalogProduct } from "./Embed/Pages/Catalog/product";
import { Layout } from "./Pages/layout";
import { Licence } from "./Pages/licence";
import AuthProvider from "./Embed/Context/AuthContext";

export const Root = () => {
  const { i18n } = useTranslation("common");
  const cookie = new Cookies();
  const regionname = cookie.get("regionname") || "France";

  const OrgRedirect = () => {
    const { region, locale, brand } = useParams();
    return (
      <Navigate
        replace
        to={`/${locale}/${region}/embed?brand=${brand}&sort=downloads&order=desc`}
      />
    );
  };

  const router = createBrowserRouter(
    [
      {
        element: <AuthProvider />,
        children: [
          {
            path: "/",
            element: (
              <Navigate replace to={`/${i18n.language}/${regionname}`} />
            ),
          },
          {
            element: <Layout />,
            children: [
              {
                path: "/:locale/:region",
                element: <HomePage />,
              },
              {
                path: "/logout",
                element: <Logout />,
              },
              {
                path: "/certainteed",
                element: (
                  <Navigate
                    to={
                      "/en/United%20States/browse?brand=CertainTeed&sort=downloads&order=desc"
                    }
                  />
                ),
              },
              {
                path: "/:locale/:region/browse",
                element: <Browse />,
              },
              { path: "/:locale/:region/product/:name", element: <Product /> },
              { path: "/:locale/:region/contactus", element: <ContactUs /> },
              { path: "/:locale/:region/downloads", element: <Download /> },
              { path: "/:locale/:region/sitemap", element: <Sitemap /> },
              {
                path: "/:locale/:region/cookie-policy",
                element: <CookiePolicy />,
              },
              {
                path: "/:locale/:region/privacy-policy",
                element: <PrivacyPolicy />,
              },
              {
                path: "/:locale/:region/legal-policy",
                element: <LegalPolicy />,
              },
              {
                path: "/:locale/:region/licence",
                element: <Licence />,
              },
              {
                path: "/:locale/:region/maintenance",
                element: <Maintenance />,
              },
              { path: "*", element: <PageNotFound /> },
              {
                path: "/authenticate",
                element: <Authenticate />,
              },
            ],
          },
          {
            path: "/:locale/:region/embed/:brand",
            element: <OrgRedirect />,
          },
          {
            path: "/:locale/:region/embed",
            element: <EmbedEcophon />,
          },
          {
            path: "/:locale/:region/embed/:brand/product/:name",
            element: <EmbedEcophonProduct />,
          },
        ],
      },
    ]

    // PIPELINE-POC
    //, {basename: "/user-ui"}
  );

  return <RouterProvider router={router} />;
};
