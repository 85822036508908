import React, { useContext, useEffect, useMemo } from "react";
import { CartButton } from "./cartButton";
import "../../Utils/assets/header.scss";
import { HomeButton } from "./homeButton";
import { LanguageMenu } from "./languageMenu";
import { ProfileIndex } from "./profileIndex";
import { RegionMenu } from "./regionMenu";
import { Typography, Box } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import CloseIcon from "@mui/icons-material/Close";
import { SearchBar } from "./searchBar";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

export const Header = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsDrawerOpen(!isDrawerOpen);
  };

  const drawer = (
    <>
      <Typography component="div" className="box-container">
        <Typography component="div" className="search-region-container">
          <RegionMenu />
        </Typography>

        <Typography component="div" className="search-container ">
          <SearchBar />
        </Typography>

        <Typography component="div" className="login-lang-container">
          <CartButton />
          <ProfileIndex />
          <LanguageMenu />
        </Typography>
      </Typography>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <header className="app-header">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => handleDrawerToggle()}
          className="responsive-nav-container"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          anchor="top"
        >
          {mobileOpen && drawer}
        </Drawer>
        <HomeButton />

        <Typography component="span" className="responsiveNav">
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleDrawerToggle()}
                id="headerDrawer"
                sx={{ ml: "0", mt: 1, display: { lg: "none" } }}
              >
                {isDrawerOpen === true ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Toolbar>
          </Box>
        </Typography>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
          style={{ width: "75%", float: "right" }}
        >
          {drawer}
        </Box>
      </header>
    </>
  );
};
