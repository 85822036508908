import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import "../../Utils/assets/productCard.scss";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { getProductDetails } from "../../API";
import { DownloadPopup } from "../Common/downloadPopup";
import { useRelocate } from "../../relocate";
import { BimlibContext, BimlibContextType } from "../../Context/context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";
import { ProductCardSkeleton } from "../Browse/productCardSkeleton";
import { createDatabase } from "../../Context/db";

interface Props {
  product: any;
  brands: any;
  selectedBrand?: string | null;
}

export const ProductCard = ({
  product,
  brands,
  selectedBrand = "all",
}: Props) => {
  const { locale, region } = useParams();
  const { productsDetail, addRemoveProduct, openDB } = useContext(
    BimlibContext
  ) as BimlibContextType;
  const [isDownload, setDownload] = useState(false);
  const [selected, setSelected] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [productDetails, setProductDetails] = useState<any>([]);
  const { t } = useTranslation("common");
  const { redirect } = useRelocate();
  const idb = window.indexedDB;
  const { config, regions, cultureLoading } = useContext(
    CultureContext
  ) as ICultureContextType;

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = async () => {
    const db = await openDB();
    const transaction = db.transaction("Products", "readonly");
    const productData = transaction.objectStore("Products");
    const products = productData.getAll();
    products.onsuccess = (query: any) => {
      setProductDetails(query.target?.result);
    };
    products.onerror = (event: any) => {
      console.log("error occured");
    };
    transaction.oncomplete = () => {
      db.close();
    };
  };

  const handleOpenDropdown = async (rowData: any) => {
    if (region && regions && config) {
      setDownload(true);
      const { product } = await getProductDetails(
        rowData.name,
        region,
        regions,
        config,
        locale
      );
      setDownload(false);
      setProductDetails(product);
      setOpenDownload(true);
    }
  };

  const handleCloseDropdown = () => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0";
    setOpenDownload(false);
  };

  const setBrokenImg = (event: any) =>
    (event.currentTarget.src = `${config.REACT_APP_URL}/assets/imgs/dummy-img.png`);

  if (cultureLoading) {
    return <ProductCardSkeleton skey={product.oid} />;
  }

  const brand = brands.find(
    (br: any) =>
      br.label === selectedBrand || br.label === product.brandNames[0]
  );

  return (
    <React.Fragment>
      <ListItem className="product-card-spacing">
        <div className="custom-grid-item">
          <Card className={`product-card ${selected === true ? "active" : ""}`}>
            <div
              className="cursor-pointer"
              id={`view-products-${product.productId}`}
              onClick={() => {
                redirect(`/${locale}/${region}/product/${product.name}`);

                sendDataToGTM({
                  event: "view_product",
                  product_id: product.oid,
                  product_name: product.label,
                  brand: product.brandNames,
                  region: region,
                  locale: locale,
                });
              }}
            >
              <Tooltip
                title={product.label}
                placement="top"
                arrow
                disableTouchListener={true}
                disableFocusListener={true}
              >
                <CardHeader subheader={product.label}>
                  <Typography gutterBottom>{product.label}</Typography>
                </CardHeader>
              </Tooltip>
              <div className="img-container">
                <img
                  src={`${config.REACT_APP_API_URL}/anonymous/image/hq/${product.imageIds[0]}`}
                  height="194"
                  className="img-responsive"
                  id={`browseProducts -${product.imageIds[0]}`}
                  alt="Product"
                  loading="lazy"
                  onError={(e: any) => setBrokenImg(e)}
                />
              </div>
              <CardContent>
                <img
                  src={
                    brand &&
                    `${config.REACT_APP_BIMDATABASE_API_URL}${brand.thumbnailImage}`
                  }
                  alt={brand !== undefined ? product.brandNames : "Brand"}
                  className="img-responsive"
                  style={{ maxHeight: "34px" }}
                />
              </CardContent>{" "}
            </div>
            <Divider />
            <CardActions>
              <Tooltip
                title={
                  // basket && basket.products.includes(product.productId)
                  productsDetail &&
                  //(includes(map(productsDetail, "productId"), product.productId) && includes(map(productsDetail, "region"), region))
                  productsDetail.some(
                    (p: any) =>
                      p.productId === product.oid && p.region === region
                  )
                    ? t("product_contact_page.remove_bim_obj")
                    : t("product_contact_page.add_bim_obj")
                }
                placement="top"
                arrow
              >
                <Button
                  size="small"
                  startIcon={
                    //basket && basket.products.includes(product.productId)
                    productsDetail &&
                    //(includes(map(productsDetail, "productId"), product.productId) && includes(map(productsDetail, "region"), region))
                    productsDetail.some(
                      (p: any) =>
                        p.productId === product.oid && p.region === region
                    ) ? (
                      <RemoveCircleOutlineRoundedIcon
                        className="text-danger"
                        id={`remove-products${product.productId}`}
                      />
                    ) : (
                      <ControlPointIcon
                        id={`add-products${product.productId}`}
                      />
                    )
                  }
                  id={`add-remove-products${product.productId}`}
                  onClick={() => {
                    addRemoveProduct(product.oid, region);

                    if (
                      //basket &&
                      // !basket.products.includes(product.productId)
                      productsDetail &&
                      //!includes(map(productsDetail, "productId"), product.productId)
                      productsDetail.some(
                        (p: any) =>
                          p.productId === product.oid && p.region === region
                      )
                    ) {
                      sendDataToGTM({
                        event: "addToCart",
                        product_id: product.oid,
                        product_name: product.label,
                        brand: product.brandNames,
                        region: region,
                        locale: locale,
                      });
                    } else {
                      sendDataToGTM({
                        event: "remove_from_cart",
                        product_id: product.oid,
                        product_name: product.label,
                        file_name: "NA",
                        file_format: "NA",
                        region: region,
                        brand: product.brandNames,
                        locale: locale,
                      });
                    }
                  }}
                ></Button>
              </Tooltip>
              <Tooltip title={t("download.downloadbtn")} placement="top" arrow>
                <LoadingButton
                  loading={isDownload}
                  size="small"
                  startIcon={<ArrowCircleDownIcon />}
                  id="productDropdown"
                  onClick={() => handleOpenDropdown(product)}
                ></LoadingButton>
              </Tooltip>
            </CardActions>
          </Card>
        </div>
      </ListItem>

      <DownloadPopup
        openDownload={openDownload}
        handleCloseDropdown={handleCloseDropdown}
        productDetails={productDetails}
      />
    </React.Fragment>
  );
};

export default ProductCard;
