import { Grid, Skeleton, Typography } from "@mui/material";

export const ProductSkeleton = () => {
  return (
    <Typography
      component="div"
      className="product-skeleton main-container product-container"
    >
      <Typography component="div" className="left-button-skeleton">
        <Skeleton
          width="91px"
          height="60px"
          sx={{ mb: "10px", display: "inline-block", mr: "10px" }}
        />

      </Typography>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12}>
          <Skeleton variant="text" width="100%" sx={{ m: "0px" }} />
          <Skeleton variant="text" width="35%" sx={{ m: "0px" }} />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="330px"
            sx={{ mb: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block", ml: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block", ml: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block", ml: "10px" }}
          />
          <Typography component="div" className="left-button-skeleton">
            <Skeleton
              width="137px"
              height="60px"
              sx={{ mb: "10px", display: "inline-block", mr: "10px" }}
            />
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>

          <Skeleton
            variant="rectangular"
            width="100%"
            height="50px"
            sx={{ mb: "0px", mt: "20px" }}
          />
          <Skeleton variant="text" width="100%" sx={{ mt: "10px" }} />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="35%" />
        </Grid>
      </Grid>
    </Typography>
  );
};
