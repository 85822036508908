import "../Resources/profileButton.scss";
import { Login } from "./login";
import { isExpired } from "react-jwt";
import { useContext, useEffect, useState } from "react";
import { refreshTokenSliently } from "../../API/authenticate.api";
import { MyConfig } from "../../API/myConfig";
import { Avatar, Button } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useParams } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

export const ProfileIndex = () => {
  const { locale, region } = useParams();
  const [loading, setLoding] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(true);
  const [config, setConfig] = useState<any>(null);
  const { token, setToken } = useContext(AuthContext);

  useEffect(() => {
    async function ReadConfig() {
      let config = await MyConfig();
      setConfig(config);
    }
    ReadConfig();
  }, []);

  const onLogoutClick = async () => {
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    let redirectlocation = `${window.location.pathname
      .replace(`/${locale}`, "")
      .replace(`/${region}`, "")}${window.location.search}`;
    window.location.replace(
      `${config.REACT_APP_AUTHENTICATIONURL}${
        config.REACT_APP_LOGOUT_REDIRECT
      }?logout_redirect=${encodeURIComponent(redirectlocation)}`
    );
  };

  useEffect(() => {
    setLoding(true);
    async function checkUserLogin() {
      if (token) {
        const istokenExpired = isExpired(token);
        if (istokenExpired) {
          // const isMyRefreshTokenExpired = await refreshTokenSliently();
          setSessionExpired(true);
        } else {
          setSessionExpired(false);
        }
      }
      setLoding(false);
    }

    checkUserLogin();
  }, [token]);

  if (loading) {
    return <>..</>;
  }

  if (sessionExpired) {
    return <Login config={config} />;
  }

  return (
    <Button
      className="f-w-500 login font-primary poc-profile-button"
      id="profileLogoutMenu"
      style={{ textTransform: "capitalize", float: "right" }}
      onClick={() => {
        onLogoutClick();
        return false;
      }}
    >
      <Avatar style={{ marginLeft: "5px" }}>
        <ExitToAppIcon />
      </Avatar>
    </Button>
  );
};
