import React from "react";
import "../Utils/assets/pageNotFound.scss";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const Maintenance = () => {
    return (
        <>
            <div
                className="page-not-found"
            // style={{ backgroundImage: "url('/assets/imgs/404/background.jpg')" }}
            >
                <div className="inner-content">
                    <Card sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <CardContent sx={{ flex: "1 0 auto" }}>
                                <CardMedia
                                    component="img"
                                    image="/assets/imgs/logo.png"
                                    alt="logo"
                                    sx={{
                                        width: 200,
                                        display: "inline-flex",
                                        verticalAlign: "middle",
                                    }}
                                    className="logo"
                                />{" "}
                                {/* Blue Ribbon below header */}
                                <Typography component="div" className="project-name">
                                    <Typography component="span">{"BIM LIBRARY"}</Typography>
                                </Typography>
                                <Typography component="div" variant="h2">
                                    We’ll be back soon!
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Sorry for the inconvenience.
                                    We’re performing some maintenance at the moment.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Please go back to Homepage.
                                </Typography>
                            </CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    pl: 1,
                                    pb: 1,
                                    justifyContent: "center",
                                }}
                            >
                                <Link href="/">
                                    <Button className="btn btn-primary">Go Home</Button>
                                </Link>
                            </Box>
                        </Box>
                        <CardMedia
                            component="img"
                            sx={{ width: 400 }}
                            image="/assets/imgs/maintenance/Maintenance-pana.svg"
                            alt="404"
                        />
                    </Card>
                </div>
            </div>
        </>
    );
};
