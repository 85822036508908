import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";

export const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    window.pageYOffset > 180 ? setIsVisible(true) : setIsVisible(false);
  };
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      <Typography
        component="div"
        className={`floating-container smooth-transition ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <Typography component="div" className="floating-icon" onClick={goToTop} id="scrollTop">
          <ArrowForwardIosIcon />
        </Typography>
      </Typography>
    </>
  );
};
