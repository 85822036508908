import { axioGet } from "../lib/axio";
import { MyConfig } from "./myConfig";
import { CriteriaCategoryDisplay } from "./Types/criteriaCategoryDisplay.type";

export const getCriteriaCategoryByName = async (
  name: string,
  config: any
): Promise<CriteriaCategoryDisplay> => {
  return await axioGet(
    `${config.REACT_APP_API_URL}/anonymous/criteria-category/by/name?name=${name}`,
    "criteriacategoryname"
  );
};
