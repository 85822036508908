import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";

export const Footer = () => {
  let { locale, region } = useParams();
  const cookies = new Cookies();
  const { t } = useTranslation("common");
  const contactIdRemove = () => {
    if (cookies.get("commercialContactId")) {
      cookies.remove("commercialContactId");
    }
  };
  if (!region) {
    region = cookies.get("regionname");
  }
  const date = new Date();

  return (
    <footer>
      <Typography component="div" className="footer-content">
        <Typography component="div" className="footer-logo-copyrights">
          <span style={{ width: "75px" }}>
            <img
              src="/assets/imgs/sgFooterLogo.png"
              className="img-responsive footer-logo"
              alt="Saint Gobain Footer Logo"
            />
          </span>
          <p className="copyright">{"© " + date.getFullYear() + " "}{t("footer.copyright")}</p>
        </Typography>
        <Typography component="div" className="footer-nav text-center">
          <Link
            href={`/${locale}/${region}/contactus`}
            underline="none"
            id="contactFooter"
            onClick={() => contactIdRemove()}
          >
            {t("footer.contact")}
          </Link>
          <Link
            id="sitemapFooter"
            href={`/${locale}/${region}/sitemap`}
            underline="none"
            target="_blank"
          >
            {t("footer.sitemap")}
          </Link>
          <Link
            id="legalFooter"
            href={`/${locale}/${region}/legal-policy`}
            underline="none"
            target="_blank"
          >
            {t("footer.Legal")}
          </Link>
          <Link
            id="cookeFooter"
            href={`/${locale}/${region}/cookie-policy`}
            underline="none"
            target="_blank"
          >
            {t("footer.cookie")}
          </Link>
          <Link
            id="privacyFooter"
            href={`/${locale}/${region}/privacy-policy`}
            underline="none"
            target="_blank"
          >
            {t("footer.privacy")}
          </Link>
          <Link
            id="licenseFooter"
            href={`/${locale}/${region}/licence`}
            underline="none"
            target="_blank"
          >
            {t("footer.licence")}
          </Link>
        </Typography>

        <Typography component="div" className="social-link-container">
          <Link
            id="fbIcon"
            href="https://www.facebook.com/saintgobaingroup"
            target="_blank"
            underline="none"
          >
            <FacebookOutlinedIcon />
          </Link>
          <Link
            id="twitterIcon"
            href="https://twitter.com/saintgobain"
            target="_blank"
            underline="none"
          >
            <TwitterIcon />
          </Link>
          <Link
            id="youtubeIcon"
            href="https://www.youtube.com/user/SaintGobainTV"
            target="_blank"
            underline="none"
          >
            <YouTubeIcon />
          </Link>
          <Link
            id="linkedinIcon"
            href="https://www.linkedin.com/company/saint-gobain "
            target="_blank"
            underline="none"
          >
            <LinkedInIcon />
          </Link>
        </Typography>

        <Typography component="div" style={{ fontSize: ".7rem" }}>
          3.2.0
        </Typography>
      </Typography>
    </footer>
  );
};
