import { List, ListItemButton, Typography, MenuItem } from "@mui/material";
import React from "react";
import { createSearchParams } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import { useRelocate } from "../../../../relocate";

interface Props {
  brands: any;
  selectedBrand: string | undefined;
  locale: string;
  region: string;
}

export const ListBrand = ({ selectedBrand, brands, region, locale }: Props) => {
  const { relocate } = useRelocate();
  brands && brands.sort((a: any, b: any) => a.label.localeCompare(b.label)); // sorting alphabetically

  return (
    <React.Fragment>
      <div>
        <SimpleBarReact style={{ maxHeight: "83vh" }}>
          {brands?.map((values: any, index: number) => {
            return (
              <MenuItem key={index}>
                <List key={index}>
                  <ListItemButton
                    selected={selectedBrand === values.label}
                    onClick={() => {
                      relocate({
                        pathname: "/browse",
                        search: `?${createSearchParams({
                          brand: values.label,
                          sort: "DOWNLOADS_DESC",
                        })}`,
                      });
                    }}
                  >
                    <Typography>{values.label}</Typography>
                    <Typography className="text-right"></Typography>
                  </ListItemButton>
                </List>
              </MenuItem>
            );
          })}
        </SimpleBarReact>
      </div>
    </React.Fragment>
  );
};
