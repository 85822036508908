import _, { findIndex, forEach, includes, map } from "lodash";
import * as React from "react";
import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { getAllProductDetails, ListBrands } from "../API";

interface IBimlibType {
  products: string[];
  region: string;
  downloadedFiles: { productId: string; fileId: string }[];
}

const inititialState: IBimlibType = {
  products: [],
  region: "",
  downloadedFiles: [],
};

interface countType {
  products: string[];
}

const initialState: countType = {
  products: [],
};

export type BimlibContextType = {
  basket: IBimlibType;
  productsDetail: any;
  brands: any;
  allDownloadedFiles: any;
  toggleBasketProduct: (productid: string) => void;
  addRemoveProduct: (productid: string, region: any) => void;
  initBasket: (regionname: string) => Promise<void>;
  addDownloadFiles: (fileid: { productId: string; fileId: string }[]) => void;
  removeBasketProduct: (productId: string[]) => void;
  getAllProduct: (type: any) => Promise<string[] | undefined>;
  addDownloadedFiles: (
    productId: string,
    fileId: string,
    region: any
  ) => Promise<any>;
  removeProduct: (db: any, productId: string[], region: any) => Promise<any>;
  count: countType;
  updateCount: (region: any) => Promise<void>;
  openDB: () => Promise<any>;
  getAllDownloadedFiles: (db: any) => Promise<any[] | undefined>;
};

export const BimlibContext = createContext<BimlibContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const BasketProvider: React.FC<Props> = ({ children }) => {
  const [basket, setBasketProducts] = useState<IBimlibType>(inititialState);
  const [count, setCount] = useState<countType>(initialState);
  const [brands, setBrands] = useState<any>(null);
  const [productsDetail, setProductsDetail] = useState<any>([]);
  const [allDownloadedFiles, setAllDownloadedFiles] = useState<any[]>([]);
  const [allFiles, setAllFiles] = useState<any[]>([]);
  const { locale } = useParams();

  useEffect(() => {
    if (basket.region) {
      const cokkies = new Cookies();
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 100);
      cokkies.set(`basket_${basket.region}`, basket, {
        path: "/",
        expires: date,
      });
    }
  }, [basket]);

  useEffect(() => {
    updateCount();
  }, []);

  const initBasket = async (regionname: string) => {
    if (regionname) {
      const cokkies = new Cookies();
      const productsFromCookie = cokkies.get(`basket_${regionname}`);
      if (productsFromCookie) {
        setBasketProducts(productsFromCookie);
      } else {
        setBasketProducts({
          products: [],
          region: regionname,
          downloadedFiles: [],
        });
        try {
          await clearObjectStore();
        } catch (e) {
          console.log(e);
        }

        //to be discuss with yogesh for cookie
        // clearProducts();
        // clearFiles();
      }
    }
  };

  const clearObjectStore = async () => {
    const db: any = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction("DownloadedFiles", "readwrite");
      const store = transaction.objectStore("DownloadedFiles");
      store.clear();
      resolve("Data cleared successfully");
    });
  };

  const openDB = async () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open("BimLibrary", 2);

      request.onsuccess = function (event: any) {
        resolve(event.target.result);
      };

      request.onerror = function (event: any) {
        reject("Error opening database");
      };

      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;

        db.createObjectStore("Products", {
          keyPath: ["productId", "region"],
        });

        db.createObjectStore("DownloadedFiles", {
          keyPath: ["fileId", "region"],
        });

        db.createObjectStore("Files", {
          keyPath: ["productId", "region"],
        });
      };
    });
  };

  // newly crafted
  const getAllProduct = async (db: any): Promise<string[] | undefined> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("Products", "readonly");
      const store = transaction.objectStore("Products");
      const request = store.getAll();

      request.onsuccess = function (query: any) {
        resolve(query.target?.result);
      };

      request.onerror = function (event: any) {
        reject(undefined);
      };
    });
  };

  // newly crafted
  const getAllFiles = async (db: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("Files", "readonly");
      const store = transaction.objectStore("Files");
      const request = store.getAll();

      request.onsuccess = function (query: any) {
        resolve(query.target?.result);
      };

      request.onerror = function (event: any) {
        reject(undefined);
      };
    });
  };

  // newly crafted
  const getAllDownloadedFiles = async (db: any): Promise<any[] | undefined> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("DownloadedFiles", "readonly");
      const store = transaction.objectStore("DownloadedFiles");
      const request = store.getAll();

      request.onsuccess = function (query: any) {
        resolve(query.target?.result);
      };

      request.onerror = function (event: any) {
        reject(undefined);
      };
    });
  };

  const toggleBasketProduct = (productid: string) => {
    if (basket.products.includes(productid)) {
      setBasketProducts((prevBasketProducts) => {
        return {
          ...prevBasketProducts,
          products: prevBasketProducts.products.filter(
            (product: string) => product !== productid
          ),
        };
      });
    } else {
      setBasketProducts((prevBasketProducts) => {
        return {
          ...prevBasketProducts,
          products: [...prevBasketProducts.products, productid],
          downloadedFiles: prevBasketProducts.downloadedFiles.filter(
            (dwdfiles: any) => productid !== dwdfiles.productId
          ),
        };
      });
    }
  };

  // newly crafted
  const addFilesObject = async (db: any, productId: any, region: any) => {
    try {
      const response = await getAllProductDetails(productId, locale);

      return new Promise((resolve, reject) => {
        const transaction = db.transaction("Files", "readwrite");
        const store = transaction.objectStore("Files");

        let pendingRequests = response.length;
        if (pendingRequests === 0) {
          resolve("No data to add");
          return;
        }

        response.forEach((res: any) => {
          const request = store.put({
            productId: res.productId,
            fileLength: res.fileDescriptors.length,
            region: region,
          });

          request.onsuccess = () => {
            pendingRequests--;
            if (pendingRequests === 0) {
              resolve("Data added successfully");
            }
          };

          request.onerror = (event: any) => {
            reject(`Error adding data: ${event.target.error}`);
          };
        });
      });
    } catch (error) {
      throw new Error(undefined);
    }
  };

  // newly crafted
  // const removeProduct = async (db: any, id: any, region: any) => {
  //   return new Promise((resolve, reject) => {
  //     const transaction = db.transaction("Products", "readwrite");
  //     const store = transaction.objectStore("Products");
  //     const request = store.delete([id, region]);

  //     request.onsuccess = (query: any) => {
  //       resolve("success");
  //     };

  //     request.onerror = function (event: any) {
  //       reject(`Error adding data: ${event.target.error}`);
  //     };
  //   });
  // };

  const removeProduct = async (db: any, ids: string[], region: any) => {
    return Promise.all(
      ids.map(
        (id) =>
          new Promise((resolve, reject) => {
            const transaction = db.transaction("Products", "readwrite");
            const store = transaction.objectStore("Products");
            const request = store.delete([id, region]);

            request.onsuccess = () => {
              resolve(
                `Product with id: ${id} and region: ${region} deleted successfully.`
              );
            };

            request.onerror = (event: any) => {
              reject(
                `Error deleting product with id: ${id} and region: ${region} - ${event.target.error}`
              );
            };
          })
      )
    );
  };

  // newly crafted
  const removeFiles = async (db: any, id: any, region: any) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("Files", "readwrite");
      const store = transaction.objectStore("Files");
      const request = store.delete([id, region]);

      request.onsuccess = function (query: any) {
        resolve("Succes");
      };

      request.onerror = function (event: any) {
        reject(`Error adding data: ${event.target.error}`);
      };
    });

    // const dbPromise = idb.open("BimLibrary", 2);
    // dbPromise.onsuccess = () => {
    //   const db = dbPromise.result;
    //   const transaction = db.transaction("Files", "readwrite");
    //   const productData = transaction.objectStore("Files");
    //   const products = productData.delete([id, region]);
    //   products.onsuccess = (query: any) => {
    //     setAllFiles(query.target?.result);
    //   };
    //   products.onerror = (event) => {
    //     console.log("error occured");
    //   };
    //   transaction.oncomplete = () => {
    //     db.close();
    //     getAllFiles();
    //   };
    // };
  };

  // newly crafted
  const addProduct = async (db: any, productid: any, region: any) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("Products", "readwrite");
      const store = transaction.objectStore("Products");

      const products = store.put({
        // id: productsDetail?.length + 1,
        productId: productid,
        region: region,
      });

      products.onsuccess = function (query: any) {
        resolve("success");
      };

      products.onerror = function (event: any) {
        reject(undefined);
      };
    });
  };

  // newly crafted
  const addRemoveProduct = async (productid: string, region: any) => {
    try {
      debugger;
      const db = await openDB();
      let allProducts: string[] | undefined = await getAllProduct(db);
      let allDownloadedFiless: any = await getAllDownloadedFiles(db);

      const isProductAlreadyExistinCart = allProducts?.some(
        (p: any) => p.productId === productid && p.region === region
      );

      if (isProductAlreadyExistinCart) {
        // remove product from cart
        // 1. remove files of perticular product from DownloadedFiles Table
        allDownloadedFiless?.map(async (prod: any) => {
          if (prod.productId === productid) {
            await removeDeletedFiles(db, prod.fileId, region);
          }
        });

        // 2. remove files of perticular product from Files Table
        await removeFiles(db, productid, region);

        //3. remove product from Products Table
        await removeProduct(db, [productid], region);
      } else {
        // add product to cart
        const productAdded = await addProduct(db, productid, region);
        if (productAdded === "success") {
          await addFilesObject(db, [productid], region);
        }
      }

      allProducts = await getAllProduct(db);
      allDownloadedFiless = await getAllDownloadedFiles(db);
      const files = await getAllFiles(db);

      setAllDownloadedFiles(allDownloadedFiless || []);
      setCount({
        products: allProducts || [],
      });
      setProductsDetail(allProducts || []);
      setAllFiles(files || []);
    } catch (error) {
      console.error(error);
    }
  };

  // newly carfted
  const updateCount = async () => {
    const db = await openDB();
    const allProducts: string[] | undefined = await getAllProduct(db);
    setCount({
      products: allProducts || [],
    });
    setProductsDetail(allProducts || []);
  };

  const removeBasketProduct = (productid: string[]) => {
    setBasketProducts((prevBasketProducts) => {
      return {
        ...prevBasketProducts,
        products: prevBasketProducts.products.filter(
          (product: string) => !productid.includes(product)
        ),
      };
    });
  };

  const addDownloadFiles = (
    fileid: { productId: string; fileId: string }[]
  ) => {
    setBasketProducts((prevBasketProducts) => {
      return {
        ...prevBasketProducts,
        downloadedFiles: [...prevBasketProducts.downloadedFiles, ...fileid],
      };
    });
  };

  // newlyCrafted
  const addDownloadedFiles = async (
    productId: string,
    fileId: string,
    region: any
  ): Promise<any> => {
    const db: any = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("DownloadedFiles", "readwrite");
      const store = transaction.objectStore("DownloadedFiles");
      const request = store.put({
        productId: productId,
        fileId: fileId,
        region: region,
      });

      request.onsuccess = function () {
        resolve("Data added successfully");
      };

      request.onerror = function (event: any) {
        reject(`Error adding data: ${event.target.error}`);
      };
    });
  };

  // newly crafted
  const removeDeletedFiles = async (
    db: any,
    fileId: string,
    region: string
  ) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("DownloadedFiles", "readwrite");
      const store = transaction.objectStore("DownloadedFiles");
      const request = store.delete([fileId, region]);

      request.onsuccess = function (query: any) {
        resolve("success");
      };

      request.onerror = function (event: any) {
        reject(`Error adding data: ${event.target.error}`);
      };
    });
  };

  const getBrands = async (region: string, locale: string) => {
    const brandsres = await ListBrands(region, locale);
    setBrands(brandsres);
  };

  return (
    <BimlibContext.Provider
      value={{
        basket,
        productsDetail,
        brands,
        allDownloadedFiles,
        toggleBasketProduct,
        addRemoveProduct,
        initBasket,
        addDownloadFiles,
        removeBasketProduct,
        getAllProduct,
        addDownloadedFiles,
        removeProduct,
        count,
        updateCount,
        openDB,
        getAllDownloadedFiles,
      }}
    >
      {children}
    </BimlibContext.Provider>
  );
};

export default BasketProvider;
