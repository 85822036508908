import { Button, Box, Typography, Link, Paper } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import "../../Utils/assets/productContact.scss";
import Cookies from "universal-cookie";
import { getCommercialContact } from "../../API/Products.api";
import { Link as LinkR, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useJwt } from "react-jwt";
import { MyConfig } from "../../API/myConfig";
import { BimlibContext, BimlibContextType } from "../../Context/context";
import { DownloadPopup } from "../Common/downloadPopup";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import { includes, map } from "lodash";
import { GetRegionOid } from "../../API";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

interface CommercialContact {
  brandIds: string[];
  commercialContactId: string;
  email: string;
  fullName: string;
  geoLocation: string;
  imageId: string;
  phoneNumber: string;
  regionIds: string[];
}

export const ProductContact = (props: any) => {
  const { locale, region }: any = useParams();
  const { productsDetail, addRemoveProduct } = React.useContext(
    BimlibContext
  ) as BimlibContextType;
  const cookies = new Cookies();
  const [openDownload, setOpenDownload] = useState(false);
  const [commercialContact, setCommercialContact] =
    useState<CommercialContact | null>();
  const { t } = useTranslation("common");
  const { isExpired } = useJwt(localStorage.getItem("token") || "");
  const [config, setConfig] = useState<any>();
  const {regions } = React.useContext(CultureContext) as ICultureContextType;
  
  useEffect(() => {
    async function loadConfig() {
      const config = await MyConfig();
      setConfig(config);
    }
    loadConfig();
  }, []);

  useEffect(() => {
    if (props.product && props.product.brands[0] != null) {
      getContact(props.product.productId);
    }
  }, [props.product]);

  const currentBrand = useMemo(() => {
    const storedCurrrentBrand =  localStorage.getItem("currentBrand");
    let displayBrand = props?.product.brands[0];
    
    if( storedCurrrentBrand != null) {
      const matchingBrand = props?.product.brands
      .find((brand: {id: string}) => brand.id === storedCurrrentBrand);
      if(matchingBrand !== undefined) displayBrand = matchingBrand;
    }

       
    return displayBrand;
   
  }, [props.product]);

  //Gets the brand image url from brand id

  //Gets contact details from productId
  const getContact = async (productId: string) => {
    let contact: CommercialContact | null = null;
    
    await getCommercialContact(productId).then((data: any) => {
    
      data.forEach((item : any, index : number) => {
        const hasMatchingRegion = item.regionIds.some((regId: any) => regId === regionId);
        const hasMatchingBrand = item.brandIds.some((element: any) => element === currentBrand.id);

        if (hasMatchingRegion && hasMatchingBrand) {
          contact =item;
        }      
      });

    });
    setCommercialContact(contact);
  };
  
  const regionId = GetRegionOid(regions, region);
  
  // to close download popup
  const handleCloseDropdown = () => {
    setOpenDownload(false);
  };
  // to open download popup
  const handleOpenDropdown = async (product: any) => {
    if (region) {
      setOpenDownload(true);
    }
  };

  const setButtonVisibility = () => {
    //localStorage.setItem('goBackVisibility', 'true')
    if (commercialContact?.commercialContactId || props?.product.brands) {
      cookies.set(
        "commercialContactId",
        commercialContact?.commercialContactId
      );
      cookies.set("brandOid", props?.product.brands[0].id);
    }
  };

  if (props && config) {
    const loginurl =
    config.REACT_APP_AUTHENTICATIONURL +
    "/oauth/authorize?response_type=code&grant_type=authorization_code&client_id=" +
    encodeURIComponent(config.REACT_APP_AUTH_CLIENT_ID) +
    "&redirect_uri=" +
    encodeURIComponent(config.REACT_APP_URL + "/authenticate") +
    "&scope=" +
    encodeURIComponent("user:projects user:downloads");
    
    if (props.product) {
      const brandImageUrl = `${config.REACT_APP_BIMDATABASE_API_URL}${currentBrand["thumbnailImage"]}`;
      return (
        <React.Fragment>
          <Box className="product-contact-container">
            <Link
              href={currentBrand.webSiteUrl}
              target="_blank"
              underline="none"
            >
              <img src={brandImageUrl} alt="BrandLogo" loading="lazy" />
            </Link>
            <Button
              variant="contained"
              className="btn btn-primary btn-normal btn-product-contact"
              id="removeProdContact"
              startIcon={<ShoppingCartIcon sx={{ fontSize: 30 }} />}
              onClick={() => {
                // toggleBasketProduct(props.product.productId);
                addRemoveProduct(props.product.productId, region);

                if (
                  // basket &&
                  // !basket.products.includes(props.product.productId)
                  productsDetail &&
                  !includes(
                    map(productsDetail, "productId"),
                    props.product.productId
                  )
                ) {
                  sendDataToGTM({
                    event: "addToCart",
                    product_id: props.product.productId,
                    product_name: props.product.productTitle,
                    brand: props.product.brandNames,
                    region: region,
                    locale: locale,
                  });
                } else {
                  sendDataToGTM({
                    event: "remove_from_cart",
                    product_id: props.product.productId,
                    product_name: props.product.productTitle,
                    file_name: "NA",
                    file_format: "NA",
                    region: region,
                    brand: props.product.brandNames,
                    locale: locale,
                  });
                }
              }}
            >
              {
                //basket && basket.products.includes(props.product.productId)
                productsDetail &&
                includes(
                  map(productsDetail, "productId"),
                  props.product.productId
                )
                  ? t("product_contact_page.remove_bim_obj")
                  : t("product_contact_page.add_bim_obj")
              }
            </Button>
            <Button
              variant="contained"
              className="btn btn-primary btn-normal btn-product-contact"
              id="prodContactDropDown"
              startIcon={<DownloadIcon sx={{ fontSize: 40 }} />}
              onClick={() => handleOpenDropdown(props.product)}
            >
              {t("product_contact_page.download_bim_obj")}
            </Button>
            <Paper
              variant="outlined"
              className="blue-box border-radius-13 full-width contact-box"
            >
              <Typography variant="h5">{t("footer.contact")}</Typography>

              {!isExpired && (
                <>
                  <Typography className="f-w-500 text-center">
                    {commercialContact?.fullName}
                  </Typography>
                  <Typography className="f-w-400 text-center">
                    {commercialContact?.phoneNumber}
                  </Typography>
                  <LinkR
                    to={`/${locale}/${region}/contactus?brandId=${currentBrand.id}&coId=${commercialContact?.commercialContactId}&regionId=${regionId}`}
                    state={commercialContact?.commercialContactId}
                    // onClick={() => setButtonVisibility()}
                    // target="_blank"
                  >
                    <Button
                      variant="contained"
                      className="btn btn-primary btn-normal"
                      id="contactEmail"
                      startIcon={<EmailIcon style={{ marginRight: "5px" }} />}
                      style={{ marginBottom: 0 }}
                    >
                      {t("contact_page.email")}
                    </Button>
                  </LinkR>
                </>
              )}

              <Button
                variant="contained"
                className="btn btn-primary btn-normal"
                id="prodContactWebsite"
                startIcon={<LanguageIcon sx={{ marginRight: 2 }} />}
                href={currentBrand.webSiteUrl}
                target="_blank"
              >
                {t("product_contact_page.website")}
              </Button>
        <>
        {isExpired && (
        <Typography className = "login-contact">
         <Link
            id="productPageContact"
            onClick={(e: any) => {
              const from = window.location.pathname + "" + window.location.search;
              localStorage.setItem("loginstate", from);
              window.location.href = loginurl;
            }}
          >
           {t("product_contact_page.please_login_for_contact_details")}
          </Link>
          </Typography>
        )}
          </>
            </Paper>
          </Box>
          <DownloadPopup
            openDownload={openDownload}
            handleCloseDropdown={handleCloseDropdown}
            productDetails={props.product}
          />
        </React.Fragment>
      );
    }
  }

  return <></>;
};
