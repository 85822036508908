import React, { useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ICookieToggleLanguage {
  setLanguage: any;
  language: String;
}

const CookiesToggleLanguage = ({
  setLanguage,
  language,
}: ICookieToggleLanguage) => {
  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };

  return (
    <>
      <ToggleButtonGroup
        id="toggleLang"
        color="primary"
        value={language}
        exclusive
        onChange={handleChange}
        aria-label="language"
      >
        <ToggleButton value="en" aria-label="english">
          En
        </ToggleButton>
        <ToggleButton value="/" className="icon-placeholder">
          /
        </ToggleButton>
        <ToggleButton value="fr" aria-label="french">
          fr
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default CookiesToggleLanguage;
