import React, { useEffect, useRef, useState } from "react";
import "../../Utils/assets//profileButton.scss";
import {
  MenuItem,
  Button,
  Popper,
  Grow,
  MenuList,
  ClickAwayListener,
  Paper,
  Avatar,
  Link,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation } from "react-router-dom";
import { Home, ManageAccounts, Logout } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { MyConfig } from "../../API/myConfig";
import { useRelocate } from "../../relocate";

export const ProfileMenu = () => {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState<any>({});
  const anchorRef: any = useRef(null);
  const prevOpen = useRef(open);
  const { relocate, urlWithoutLocalParam } = useRelocate();
  const { t } = useTranslation("common");

  const location = useLocation();

  useEffect(() => {
    const loadConfig = async () => {
      let config = await MyConfig();
      setConfig(config);
    };
    loadConfig();
  }, [location]);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: { target: any }) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const onLogoutClick = async () => {
    handleToggle();
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    const redirectto = urlWithoutLocalParam(window.location);
    const urldata = redirectto.replaceAll("+", "*");
    const urlDataAsterisk = urldata.replaceAll("%20", "*");
    const encodeURL = encodeURIComponent(urlDataAsterisk);
    window.location.href = `${config.REACT_APP_AUTHENTICATIONURL}${
      config.REACT_APP_LOGOUT_REDIRECT
    }?logout_redirect=${encodeURL}`;
  };

  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="f-w-500 text-capitalize user-name-placeholder text-primary font-size-10 font-primary"
      >
        <Avatar>
          <PersonIcon />
        </Avatar>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper className="profile-dropdown">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>
                    <Link
                      onClick={() => relocate("/")}
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: ".875rem",
                      }}
                    >
                      <Home />
                      {t("header.profile_menu.home")}
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <Link
                      href={`${config.REACT_APP_AUTHENTICATIONURL}/user/updateAccount`}
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: ".875rem",
                      }}
                      target="_blank"
                    >
                      <ManageAccounts /> {t("header.profile_menu.myaccount")}
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => onLogoutClick()}
                    id="menuLogout"
                    style={{ fontSize: ".875rem" }}
                  >
                    <Logout />
                    {t("header.profile_menu.logout")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
