import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Stack,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { downloadFilesFromBim } from "../../API/download.api";
import { concat, find, forEach, includes, map, remove } from "lodash";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { MyConfig } from "../../API/myConfig";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useRelocate } from "../../relocate";
import { ProcessWithSecure } from "../../API/authenticate.api";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

import { encryptUserId } from "../../Utils/helper";
import { SendSalesForceData } from "../../API/salesforce.api";

export const SelectTableComponent = (props: any) => {
  const { locale, region } = useParams();
  const [dataArray, setDataArray] = useState<string[]>([]);
  const [config, setConfig] = useState<any>("");
  const { t } = useTranslation("common");
  const { login } = useRelocate();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const controllerRef = useRef<AbortController | null>();
  const { regions } = useContext(CultureContext) as ICultureContextType;
  const [downloadErrorOpen, setDownloadErrorOpen] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      let config = await MyConfig();
      setConfig(config);
    };
    fetchConfig();
  }, []);

  const toggleCheckbox = (fileId: string) => {
    if (includes(dataArray, fileId)) {
      // remove
      let dummyData = [...dataArray];
      remove(dummyData, (o) => {
        return o === fileId;
      });
      setDataArray(dummyData);
    } else {
      // add
      setDataArray(concat(dataArray, [fileId]));
    }
  };

  const toggleAllCheckbox = () => {
    if (dataArray.length === props.productDetails.fileDescriptors.length) {
      // remove
      setDataArray([]);
    } else {
      // add
      setDataArray(map(props.productDetails.fileDescriptors, "fileId"));
    }
  };

  const isChecked = (fileId: string) => {
    if (includes(dataArray, fileId)) {
      return true;
    }
    return false;
  };

  const errorCallBack = () => {
    setDownloadErrorOpen(true);
    setDownloadLoading(false);
  };

  const handleDownloadSingleFiles = async () => {
    setDownloadLoading(true);
    ProcessWithSecure(
      async () => {
        if (region && locale) {
          var filesForDownload: any = {};
          filesForDownload[props.productDetails.productId] = dataArray;
          downloadFilesFromBim(
            () => {
              sendDataToGA(props.productDetails, dataArray);
              props.productDetails.brands.map((brd: any) => {
                if (
                  brd.label === "PAM Building" &&
                  (window.location.href.indexOf("dev") > -1 ||
                    window.location.href.indexOf("localhost") > -1)
                ) {
                  SendSalesForceData([props.productDetails.productId]);
                }
              });
              // if (props.productDetails.brands[0].label == "PAM Building") {
              //   SendSalesForceData([props.productDetails.productId]);
              // }
              setDownloadLoading(false);
              props.handleCloseDropdown();
            },
            errorCallBack,
            region,
            locale,
            filesForDownload,
            controllerRef,
            regions
          );
        }
      },
      () => {
        login(window.location);
      }
    );
  };

  const handleDownloadCancel = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    props.handleCloseDropdown();
  };

  const sendDataToGA = async (productDetails: any, filesForDownload: any) => {
    const email: string | null = localStorage.getItem("userId");
    forEach(filesForDownload, (element: any) => {
      let fileObj = find(productDetails.fileDescriptors, { fileId: element });
      if (fileObj) {
        let userId = encryptUserId(email);

        sendDataToGTM({
          event: "fileDownload",
          product_id: productDetails.productId,
          product_name: productDetails.productTitle,
          file_id: fileObj.fileId,
          file_name: fileObj.fileName,
          file_format: fileObj.fileFormatLabel,
          brand: productDetails.brands[0].label,
          region: region,
          locale: locale,
          user_id: localStorage.getItem("enableGA") === "true" ? userId : "NA",
        });
      }
    });
  };

  const handleErrorDisplay = (e: any) => {
    e.target.src = "../assets/imgs/dummy-img.png";
  };

  const handleClose = () => {
    setDownloadErrorOpen(false);
  };

  return (
    <>
      <div>
        <Snackbar
          open={downloadErrorOpen}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            id="sessionCloseAlert"
          >
            {t("download.download_failed")}
            <Link
              className="font-size-16 color-secondary"
              to={`/${locale}/${region}/contactus`}
              target="_blank"
            >
              {t("download.contact_us")}
            </Link>
          </Alert>
        </Snackbar>
      </div>
      <TableContainer component={"span"} className="cart-table">
        <Table size="small" aria-label="cart table">
          <TableHead>
            <TableRow>
              <TableCell align="center" padding="checkbox" className="width-5">
                <Checkbox
                  id="tableCheckbox"
                  name="checkboxes"
                  checked={
                    dataArray.length ===
                    props.productDetails.fileDescriptors.length
                  }
                  onChange={() => toggleAllCheckbox()}
                  //indeterminate
                />
              </TableCell>

              <TableCell align="left" className="width-75" id="fileDownload">
                {t("download.file_name")}
              </TableCell>

              <TableCell align="left" className="width-20" id="formatDownload">
                {t("download.format")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productDetails.fileDescriptors.length > 0 ? (
              <>
                {props.productDetails.fileDescriptors.map((item: any) => (
                  <TableRow key={item.fileId} role="checkbox" hover>
                    <TableCell
                      align="center"
                      padding="checkbox"
                      className="table-checkbox"
                    >
                      <Checkbox
                        id={`toggleCheck- ${item.fileId}`}
                        name="DocCheckbox"
                        color="default"
                        value={item.fileId}
                        onChange={() => toggleCheckbox(item.fileId)}
                        checked={isChecked(item.fileId)}
                      />
                    </TableCell>
                    <Tooltip title={item.fileName} placement="top" arrow>
                      <TableCell align="left">{item.fileName}</TableCell>
                    </Tooltip>
                    <TableCell align="left">
                      <Typography
                        component={"div"}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={`${config.REACT_APP_BIMDATABASE_API_URL}/image/${item.fileFormatImage}?filename=${item.fileFormatImage}&size=thumbnail`}
                          alt="format"
                          loading="lazy"
                          onError={handleErrorDisplay}
                          style={{ maxWidth: "20px" }}
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            marginLeft: "5px",
                            fontFamily: `"Ubuntu", sans-serif !important`,
                          }}
                        >
                          {item.fileFormatLabel}
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  {t("common.no_data")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        spacing={2}
        direction="row"
        justifyContent={"end"}
        className="m-t-20 text-right"
      >
        <Button
          id="tableCancelButton"
          className="btn btn-primary bg-danger btn-small m-l-10"
          onClick={() => handleDownloadCancel()}
        >
          {t("common.cancle")}
        </Button>
        <LoadingButton
          variant="contained"
          className="btn btn-primary btn-small m-l-10"
          id="singleFiledown"
          onClick={() => handleDownloadSingleFiles()}
          loading={downloadLoading}
          loadingPosition="start"
          startIcon={<DownloadIcon />}
          disabled={dataArray.length === 0}
          style={{ marginRight: "10px" }}
        >
          {t("download.downloadbtn")}
        </LoadingButton>
      </Stack>
    </>
  );
};
