import axios from "axios";
import { find, map } from "lodash";
import { ListBrands } from "../../API/brand.api";
import { MyConfig } from "../../API/myConfig";
import { GetRegionOid, ListRegionByCounter } from "../../API/region.api";

type ICallback = (response: any, region: string, locale: string) => void;

interface ISearchProduct {
  region: string | undefined;
  brand: string;
  sortBy: string | undefined;
  formats: any[];
  sortDirection: string | undefined;
  criterias: any;
  locale: string | undefined;
  key: string;
}

interface ICriterias {
  checked: boolean;
  criteriaId: string;
  criteriavalues: string[];
  maxRating: number;
  minRating: number;
}

interface IDestructParams {
  format: any[];
  criteria: any[];
  page: string;
  sort: string;
  sortBy: string;
  sortDirection: string;
  formats: string[];
  criterias: ICriterias[];
  search: string[];
  brand: string;
}

export const getAllDetails = (function () {
  var allDetailsCache: any = {};
  return async function (className: string, locale: string = "en") {
    const d = new Date();
    let hour = d.getHours();
    let detailkey = `${className}_${locale}_${hour}`;
    if (!allDetailsCache[detailkey]) {
      let config = await MyConfig();
      const hostUrl =
        config.REACT_APP_BIMDATABASE_API_URL +
        "/anonymous/objects/class/" +
        className +
        "/locale/" +
        locale;
      allDetailsCache[detailkey] = fetch(hostUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          return response.json();
        })
        .catch((err: any) => {
          console.log("errors", err);
        });
    }

    return allDetailsCache[detailkey];
  };
})();

// Returns region wise avaliable products count
export const GetProductCount = (function () {
  var productCountCache: any = {};
  return async function () {
    if (!productCountCache["productcount"]) {
      let config = await MyConfig();
      const hostUrl =
        config.REACT_APP_API_URL +
        "/anonymous/product/counterByRegion?excludeEmptyRegions=true";
      productCountCache["productcount"] = await fetch(hostUrl, {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .catch((err: any) => {
          return null;
        });
    }
    return productCountCache["productcount"];
  };
})();

export const searchProduct = (function () {
  var productCache: any = {};
  return async function (callBack: ICallback, searchCriteria: ISearchProduct) {
    const {
      region = "FRANCE",
      brand,
      sortBy = "DOWNLOADS",
      formats = [],
      sortDirection = "DESC",
      criterias = [],
      locale = "en",
      key = window.location.href,
    } = searchCriteria;
    const d = new Date();
    let hour = d.getHours();

    let productkey = `${key}_${hour}`;
    if (!productCache[productkey]) {
      let config = await MyConfig();

      // list all brands
      const listBrand = await ListBrands(region, locale);

      // extract Ids of selected Brands
      // if all selected then extract all brand ids
      const regionId = await GetRegionOid(region, locale);

      let brandId = [];
      if (brand === "all") {
        brandId = map(listBrand, "brandId");
      } else {
        const brandData = find(listBrand, (e: any) => {
          return e.brandName.toLowerCase() === brand.toLowerCase();
        });
        if (brandData) {
          brandId = [brandData.brandId];
        }
      }

      if (brandId.length > 0) {
        let searchdto = {
          brandIds: brandId,
          regionId: regionId,
          sortBy: sortBy,
          format: formats,
          sortDirection: sortDirection,
          searchCriterias: criterias,
        };
        const hostUrl =
          config.REACT_APP_API_URL +
          "/anonymous/product-search?languageId=" +
          locale;

        productCache[productkey] = await fetch(hostUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(searchdto),
        })
          .then((response) => {
            return response.json();
          })
          .catch((err: any) => {
            console.log(err);
            return null;
          });
      }
    }
    callBack(productCache[productkey], region, locale);
  };
})();

//api user
export const getImage = async (imageId: string, size: string) => {
  let config = await MyConfig();
  const hostUrl =
    config.REACT_APP_API_URL + "/anonymous/image/" + size + "/" + imageId;
  return fetch(hostUrl, {
    method: "GET",
  })
    .then((response) => {
      if (response.url) {
        return response.url;
      } else {
        return "../assets/imgs/dummy-img.png";
      }
    })
    .catch(() => {
      return "../assets/imgs/dummy-img.png";
    });
};

//api bimdatabase getimage
export const getFormatImage = async (
  fileName: string,
  imageId: string,
  size: string
) => {
  let config = await MyConfig();
  const hostUrl =
    config.REACT_APP_BIMDATABASE_API_URL +
    "/image/" +
    imageId +
    "?filename=" +
    fileName +
    "&size=" +
    size;

  return fetch(hostUrl, {
    method: "GET",
  })
    .then((response) => {
      if (response.url) {
        return response.url;
      } else {
        return "../assets/imgs/dummy-img.png";
      }
    })
    .catch(() => {
      return "../assets/imgs/dummy-img.png";
    });
};

export const getProductDetails = async (
  productId: string,
  region: string,
  locale: string = "en"
) => {
  let config = await MyConfig();

  const regionByCounter = await ListRegionByCounter();
  let regionList: any = [];
  if (regionByCounter) {
    regionList = regionByCounter.map((element: any) => {
      let translation = element.translations.find(
        (trn: any) => trn.locale === locale
      );
      let entranslation = element.translations.find(
        (trn: any) => trn.locale === "en"
      );
      if (entranslation) {
        return {
          label: entranslation.label,
          oid: element.regionId,
          productCount: element.nbitems,
          translation: translation.label || entranslation.label,
        };
      }
    });
    regionList.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  const regionId = GetRegionOid(regionList, region);
  const hostUrl = `${config.REACT_APP_API_URL}/anonymous/product/by/name?languageId=${locale}&regionId=${regionId}`;
  const product = await fetch(hostUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ name: productId }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
      return null;
    });

  return { product, regionId };
};

export const getCommercialContact = async (productId: string) => {
  let config = await MyConfig();
  const hostUrl =
    config.REACT_APP_API_URL + "/anonymous/commercialContact/" + productId;
  return await axios({
    method: "GET",
    url: hostUrl,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      console.log("error is", err);
    });
};

export const destructParams = (
  searchParams: URLSearchParams
): IDestructParams => {
  const sort = searchParams.get("sort") || "DOWNLOADS";
  const format = searchParams.getAll("format") || [];
  const criteria = searchParams.getAll("criteria") || [];
  const page = searchParams.get("page") || "1";
  const search = searchParams.getAll("search") || [];
  const brand = searchParams.get("brand") || "";

  // construct format
  // each format will receive in {formatId}$bim{formatId}_{formatLabel} format
  const formats = format.map((fr: string) => {
    const formatSelected = fr.split("_")[0];
    const formatId = formatSelected.split("$bim")[0];
    return formatId;
  });

  // construct crtierias
  // each criteria will receive in {criteriaValueId}$bim{criteriaId}_{criteriaLabel} format

  let criteriaBuilder: { [key: string]: string[] } = {};
  let criterias: ICriterias[] = [];

  criteria.forEach((cr: string) => {
    const criteriaSelected = cr.split("_")[0];
    const criteriaValueId = criteriaSelected.split("$bim")[0];
    const criteriaId = criteriaSelected.split("$bim")[1];
    (criteriaBuilder[criteriaId] || (criteriaBuilder[criteriaId] = [])).push(
      criteriaValueId
    );
  });

  for (let key in criteriaBuilder) {
    let value = criteriaBuilder[key];
    if (value[0].includes("$range")) {
      const rangeValue = value[0].split("$range")[1];
      const rangeCriteriaId = value[0].split("$range")[0];

      criterias.push({
        checked: true,
        criteriaId: rangeCriteriaId,
        criteriavalues: [],
        maxRating: parseInt(rangeValue.split("-to-")[1]),
        minRating: parseInt(rangeValue.split("-to-")[0]),
      });
    } else {
      criterias.push({
        checked: true,
        criteriaId: key,
        criteriavalues: value,
        maxRating: 0,
        minRating: 0,
      });
    }
  }

  // each sort will receive in {sortBy}_{sortDirection} format
  const sortBy = sort?.split("_")[0];
  const sortDirection = sort?.split("_")[1];

  return {
    format: format,
    criteria: criteria,
    page: page,
    sort: sort,
    sortBy: sortBy === "LASTUPDATE" ? "LAST_UPDATE" : sortBy,
    sortDirection: sortDirection,
    formats: formats,
    criterias: criterias,
    search: search,
    brand: brand,
  };
};

export const updateProductViewCount = async (
  productId: string,
  regionId: string,
  locale: string
) => {
  let config = await MyConfig();
  const hostUrl =
    config.REACT_APP_API_URL + "/anonymous/product/" + productId + "/viewCount";

  return axios({
    method: "POST",
    url: hostUrl,
    params: { regionId },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      console.log("error is", err);
    });
};

export const getAllProductDetails = async (
  products: string[],
  locale: string = "en"
) => {
  let config = await MyConfig();
  let request: any = [];

  products.forEach((prd: string) => {
    if (prd) {
      request.push(
        fetch(
          `${config.REACT_APP_API_URL}/anonymous/product/${prd}?languageId=${locale}`
        ).then((res) => res.json())
      );
    }
  });

  return Promise.all(request).then((data) => {
    return data;
  });
};
