import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Slider, { CustomArrowProps } from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../../Resources/productCarousel.scss";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { getImage } from "../../../Api/Products.api";
import { resolve } from "path";

type ProductCarouselProps = {
  imageIds: string[];
};

export const ProductCarousel = (props: ProductCarouselProps) => {
  const [mainSliderNav, setMainSliderNav] = useState<Slider | null>(null);
  const [navSliderNav, setNavSliderNav] = useState<Slider | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    let isSubscribed = true;
    if (props.imageIds.length > 0) {
      getImageUrls(props.imageIds).then((imgurls: string[]) => {
        isSubscribed && setImageUrls(imgurls);
      });
    } else {
      isSubscribed && setImageUrls(["../assets/imgs/dummy-img.png"]);
    }
    // cancel subscription to useEffect
    return () => {
      isSubscribed = false;
    };
  }, [props.imageIds]);

  //Gets the images urls from images ids
  const getImageUrls = async (imageIds: string[]): Promise<string[]> => {
    let imgUrls: string[] = [];
    for (var i = 0; i < imageIds.length; i++) {
      await getImage(imageIds[i], "hq").then((data: any) => {
        if (data != null) {
          imgUrls.push(data);
        } else {
          imgUrls.push("../assets/imgs/dummy-img.png");
        }
      });
    }
    return imgUrls;
  };

  const NextArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => (
    <div {...props}>
      <ArrowForwardIosIcon className="next-slide" />
    </div>
  );
  const PreviousArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => (
    <div {...props}>
      <ArrowBackIosIcon className="prev-slide" />
    </div>
  );

  //Main carousel slider settings
  const settingsMainSlider = {
    className: "main-slider",
    initialSlide: 0,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
  };

  //Navigation slider settings
  const settingsNavSlider = {
    className: "nav-slider",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    focusOnSelect: true,
  };

  if (imageUrls.length > 0) {
    return (
      <>
        <Box className="product-slider" id="img">
          <Slider
            {...settingsMainSlider}
            asNavFor={navSliderNav ? navSliderNav : undefined}
            ref={(mainSlider: Slider) => setMainSliderNav(mainSlider)}
          >
            {imageUrls.map((url: string, index: number) => {
              return (
                <div key={index}>
                  <InnerImageZoom
                    src={url}
                    zoomType="hover"
                    hideHint={true}
                    fullscreenOnMobile={true}
                    zoomPreload={true}
                    zoomScale={3}
                  />
                </div>
              );
            })}
          </Slider>
          <Slider
            {...settingsNavSlider}
            asNavFor={mainSliderNav ? mainSliderNav : undefined}
            ref={(navSlider: Slider) => setNavSliderNav(navSlider)}
          >
            {imageUrls.map((url: string, index: number) => {
              return (
                <div key={index}>
                  <img
                    src={url}
                    alt={"carousel image " + index}
                    className="img-responsive"
                    loading="lazy"
                  />
                </div>
              );
            })}
          </Slider>
        </Box>
      </>
    );
  }

  return <></>;
};
