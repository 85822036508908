import { ListItem, Skeleton, Typography } from "@mui/material";

export const ProductCardSkeleton = ({ skey }: any) => {
  return (
    <ListItem
      className="product-card-spacing"
      style={{ flexDirection: "column" }}
      key={skey}
    >
      <Typography className="custom-grid-item" component="div">
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="60%" sx={{ m: "0px auto 10px" }} />
        <Skeleton variant="rectangular" width={"100%"} height={150} />
        <Skeleton width="40%" height={"50px"} sx={{ m: "10px auto 10px" }} />
        <Typography component="div" sx={{ width: "100%", textAlign: "center" }}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={15}
            height={15}
            sx={{ display: "inline-block" }}
          />
          <Skeleton
            animation="wave"
            variant="circular"
            width={15}
            height={15}
            sx={{ display: "inline-block", marginLeft: "15px" }}
          />
        </Typography>
      </Typography>
    </ListItem>
  );
};
