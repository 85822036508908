import React, { useContext } from "react";
import Slider from "react-slick";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import LinkIcon from "@mui/icons-material/Link";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import "../../Utils/assets/homeLearnAboutBim.scss";
import { HyperLinkedNews } from "../../API/Types/homePageNews.type";
import { getHomePageNews, getImageUrl } from "../../API/homepage.api";
import { Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

const addDefaultSrc = (ev: any) => {
  ev.target.src = "../assets/imgs/banner-placeholder.jpg";
};

interface HyperLinkedNewsDisplay extends HyperLinkedNews {
  imageUrl: string;
}

const getHyperLinkedNewsWithImages = async (
  config: any
): Promise<HyperLinkedNewsDisplay[]> => {
  let homeNews = await getHomePageNews(config);
  let output: HyperLinkedNewsDisplay[] = [];
  for (const news of homeNews.hyperLinkedNews) {
    const imageUrl = await getImageUrl(news.imageId, "default", config);
    output.push({ ...news, imageUrl });
  }
  return output;
};

export const LearnAboutBim = () => {
  const [homePageNews, setHomePageNews] = useState<HyperLinkedNewsDisplay[]>(
    []
  );

  const { config } = useContext(CultureContext) as ICultureContextType;

  const [homePageNewsSkeleton, setHomePageNewsSkeleton] =
    useState<boolean>(true);
  const [settings, setSettings] = useState({});

  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    setHomePageNewsSkeleton(true);
    if (config) {
      getHyperLinkedNewsWithImages(config)
        .then((data: HyperLinkedNewsDisplay[]) => {
          let set = settings;
          let isInfinite: boolean =
            data.filter((d: any) => d.published === true).length > 7
              ? true
              : false;
          setSettings({
            dots: false,
            //  infinite: isInfinite,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            pauseOnHover: true,
            responsive: [
              {
                breakpoint: 1367,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: true,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2,
                },
              },
            ],
          });
          setHomePageNews(data);
          setHomePageNewsSkeleton(false);
        })
        .finally(() => setHomePageNewsSkeleton(false));
    }
  }, [config]);

  return (
    <React.Fragment>
      <section className="learn-about-bim">
        <Typography component="h2" className="section-heading">
          {t("home_page.home_page_headings.learn_about_bim.title")}{" "}
        </Typography>
        {homePageNewsSkeleton && (
          <>
            <div
              style={{
                width: "25%",
                display: "inline-flex",
                flexDirection: "column",
                paddingRight: "20px",
              }}
            >
              <Skeleton variant="rectangular" width={"100%"} height={118} />
              <Skeleton variant="text" height={60} />
            </div>

            <div
              style={{
                width: "25%",
                display: "inline-flex",
                flexDirection: "column",
                paddingRight: "20px",
              }}
            >
              <Skeleton variant="rectangular" width={"100%"} height={118} />
              <Skeleton variant="text" height={60} />
            </div>

            <div
              style={{
                width: "25%",
                display: "inline-flex",
                flexDirection: "column",
                paddingRight: "20px",
              }}
            >
              <Skeleton variant="rectangular" width={"100%"} height={118} />
              <Skeleton variant="text" height={60} />
            </div>
            <div
              style={{
                width: "25%",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              <Skeleton variant="rectangular" width={"100%"} height={118} />
              <Skeleton variant="text" height={60} />
            </div>
          </>
        )}
        <Slider {...settings}>
          {homePageNews.map(
            (data) =>
              data.published && (
                <div key="{data.newsId}">
                  <Link href={data.url} target="_blank">
                    <Card
                      sx={{ maxWidth: 400 }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <CardMedia
                        component="div"
                        className="img-placeholder"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={data.imageUrl}
                          alt="BIM Library"
                          id="digitalConstructionAndBim"
                          loading="lazy"
                          onError={addDefaultSrc}
                        />
                      </CardMedia>
                      <CardContent>
                        <LinkIcon />
                        <span> {data.label} </span>
                      </CardContent>
                    </Card>
                  </Link>
                </div>
              )
          )}
        </Slider>
      </section>
      <div className="footer-section">
        <img
          src="/assets/imgs/saint-gobain-logo-new.png"
          className="img-responsive"
          alt="Saint Gobain Logo"
          style={{ width: "220px", padding: "10px", margin: "0 auto" }}
          loading="lazy"
        />
        <Typography
          variant="button"
          component="div"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            className="btn btn-big btn-discover"
            id="discoverSaintGobain"
            href="https://www.saint-gobain.com/"
            target="_blank"
          >
            {t("home_page.discover_btn")}
          </Button>
        </Typography>
      </div>
    </React.Fragment>
  );
};
