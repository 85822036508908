import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

export const DenyLocationPopup = ({
  handleCloseDenyLocationPopup,
  openDenialPopup,
}: any) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Dialog
        open={openDenialPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="roundedPopup denyLocationPopup"
        id="denyLocation"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="alert alert-info"
            style={{ paddingTop: "20px" }}
          >
            <ErrorOutlineIcon />
            {t("location_detection.warning_msg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: 0,
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <Button
            onClick={() => handleCloseDenyLocationPopup(false)}
            autoFocus
            className="btn btn-primary btn-small"
            id="regionDetect"
          >
            {t("location_detection.choose_regionbtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
