import {
  Box,
  Typography,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";

export const Licence = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <CanonicalStaticTag page="/legal-policy" />
      <Box className="main-container legal-privacy-cookie-container">
        <Typography component="h2" className="heading-two">
          {t("licence_page.title")}
        </Typography>

        <Typography component="p"> {t("licence_page.description1")}</Typography>
        <Typography component="p">{t("licence_page.to")}</Typography>
        <Typography component="p">{t("licence_page.description2")}</Typography>
        <Typography component="p">{t("licence_page.description3")}</Typography>
        <Typography component="p">{t("licence_page.description4")}</Typography>
        <Typography component="p">{t("licence_page.description5")}</Typography>
        <Typography component="p">{t("licence_page.description6")}</Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article1.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article1.description1")}{" "}
          <Link
            href="https://bimlibrary.saint-gobain.com"
            rel="noopener"
            target="_blank"
          >
            https://bimlibrary.saint-gobain.com
          </Link>{" "}
          {t("licence_page.header.article1.description1/1")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article1.description2")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article2.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article2.description1")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article2.description2")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article3.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article3.description1")}
        </Typography>
        <ul>
          <li>{t("licence_page.header.article3.list1.listItem1")}</li>
          <li>{t("licence_page.header.article3.list1.listItem2")}</li>
        </ul>
        <Typography component="p">
          {t("licence_page.header.article3.description2")}
        </Typography>
        <ul>
          <li>{t("licence_page.header.article3.list2.listItem1")}</li>
          <li>{t("licence_page.header.article3.list2.listItem2")}</li>
          <li>{t("licence_page.header.article3.list2.listItem3")}</li>
        </ul>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article4.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article4.description1")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article4.description2")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article5.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article5.description1")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article6.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article6.description1")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article6.description2")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article6.description3")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article7.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article7.description1")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article7.description2")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article8.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article8.description1")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article8.description2")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article8.description3")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article8.description4")}{" "}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article8.description5")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article8.description6")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article9.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article9.description1")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article10.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article10.description1")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article11.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article11.description1")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article12.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article12.description1")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article13.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article13.description1")}
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          {t("licence_page.header.article14.title")}
        </Typography>
        <Typography component="p">
          {t("licence_page.header.article14.description1")}
        </Typography>
      </Box>
    </>
  );
};
