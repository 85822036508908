import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import { AutoComplete } from "antd";
import FloatLabel from "../Common/FloatLabel/index";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";
import "../../Utils/assets/searchBar.scss";
import "../Common/FloatLabel/index.scss";

export const SearchBar = () => {
  const { locale, region } = useParams();
  const { allProducts } = useContext(CultureContext) as ICultureContextType;
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [searchedOptions, setSearchedoptions] = useState<any[]>();
  const [isBlur, setIsBlur] = useState<boolean>(false);
  const [optionOpen, setOptionOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const onSearch = (searchText: string) => {
    setOptionOpen(true);
    if (searchText) {
      let option = allProducts.filter((a: any) => {
        return a.label.toLowerCase().includes(searchText.toLowerCase());
      });
      let htmloption: any[] = [];
      option.forEach((op: any) => {
        let result = renderLabel(op, searchText);
        htmloption.push({
          value: op.label,
          label: result,
          key: op.oid,
        });
      });
      if (htmloption.length === 0) {
        let htmloption: any[] = [];
        htmloption.push({
          value: "",
          label: "",
        });
        setSearchedoptions(htmloption);
        setOptionOpen(false);
      } else {
        setSearchedoptions(htmloption);
      }
    } else {
      let htmloption: any[] = [];
      htmloption.push({
        value: "",
        label: "",
      });
      setSearchedoptions(htmloption);
      setOptionOpen(false);
    }
  };

  const redirectOnSearch = (newValue: any) => {
    let htmloption: any[] = [];
    htmloption.push({
      value: "",
      label: "",
    });
    setSearchedoptions(htmloption);
    setOptionOpen(false);
    if (newValue) {
      if (newValue.code === "Product") {
        navigate(`/${locale}/${region}/product/${newValue.name}`);
      } else {
        navigate(
          `/${locale}/${region}/browse?${createSearchParams({
            brand: newValue.label,
            sort: "downloads",
            order: "desc",
          })}`
        );
      }
      sendDataToGTM({
        event: "search",
        search_term: newValue.label,
        search_type: newValue.code,
        region: region,
        locale: locale,
      });
    }
  };

  const renderLabel = (item: any, searchText: string) => {
    let label: string = item.label;
    let searchString = searchText;

    if (searchString) {
      let index = label.toLowerCase().indexOf(searchString.toLowerCase());

      if (index !== -1) {
        let length = searchString.length;

        let prefix = label.substring(0, index);
        let suffix = label.substring(index + length);
        let match = label.substring(index, index + length);

        return (
          <Box
            component="li"
            sx={{
              "& > span": {
                pr: 2,
                flexShrink: 0,
                color: "var(--color-primary)",
                display: "inline-block",
                width: "25%",
                textAlign: "right",
              },
              "& > div": {
                display: "inline-block",
                width: "80%",
                textAlign: "left",
              },
            }}
            style={{
              borderBottom: "1px solid #F1F1F1",
              marginTop: 0,
              marginBottom: 0,
              width: "100%",
              display: "flex",
              textAlign: "left",
              cursor: "pointer",
              justifyContent: "space-between",
              alignItems: "top",
            }}
          >
            <div
              className="suggested-value"
              id="redirectSearch"
              onClick={() => redirectOnSearch(item)}
              style={{
                padding: "10px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              {" "}
              {prefix}
              <span
                style={{
                  fontWeight: match ? 700 : 400,
                  color: match ? "var(--color-primary)" : "",
                }}
              >
                {match}
              </span>
              {suffix}
            </div>
            <span
              className="opacity-5 suggest-code"
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              {item.code}
            </span>
          </Box>
        );
      }
    }
  };

  const onChange = (data: string) => {
    setValue(data);
    setIsBlur(false);
    setOptionOpen(true);
  };

  const onSelect = (data: string) => {
    onSearch(data);
    setSelected(true);
    setIsBlur(true);
    setOptionOpen(true);
    setValue("");
    let htmloption: any[] = [];
    htmloption.push({
      value: "",
      label: "",
    });
    setSearchedoptions(htmloption);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setIsBlur(true);
      onSearch("");

      // selectedSearch.forEach((s) => {
      searchParams.set("search", value);
      // });
      // setSelectedSearch([]);
      setValue("");
      if (searchParams.has("brand")) {
        setSearchParams(searchParams);
      } else {
        searchParams.set("brand", "all");
        searchParams.set("sort", "downloads");
        searchParams.set("order", "desc");
        navigate(`/${locale}/${region}/browse?${searchParams}`);
      }
    }
  };

  const handleBlur = () => {
    setIsBlur(true);
    // setOptionOpen(false);
  }
  return (
    <>
      <FloatLabel
        label="Search Products or Brands"
        name="Search Products or Brands"
        value={value}
      >
        <AutoComplete
          value={value}
          notFoundContent="No result found"
          open={optionOpen}
          options={searchedOptions}
          style={{ width: "100%" }}
          dropdownMatchSelectWidth={500}
          onSearch={(onSearch)}
          onSelect={onSelect}
          onKeyDown={handleKeyDown}
          getPopupContainer={(trigger) => trigger.parentElement}
          onChange={onChange}
          className={
            isBlur === true ? "search-container not" : "search-container"
          }
          // onBlur={() => handleBlur()}
          onBlur={() => setIsBlur(true)}
          onFocus={() => setIsBlur(false)}
          allowClear={true}
          backfill={true}
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        ></AutoComplete>
      </FloatLabel>
    </>
  );
};
