import React, { createContext, useEffect, useState } from "react";
import { IAuthContextType } from "../Type";
import { Outlet } from "react-router-dom";

const initialAuthContextState = {
  token: "",
  setToken: () => {},
};
export const AuthContext = createContext<IAuthContextType>(
  initialAuthContextState
);

export const AuthProvider = () => {
  const [token, setToken] = useState<string>("");
  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
