import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../../Resources/product.scss";
import { useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import {
  getProductDetails,
  updateProductViewCount,
} from "../../Api/Products.api";
import { useTranslation } from "react-i18next";
import { ProductSkeleton } from "./Product/productSkeleton";
import { ProductCarousel } from "./Product/productCarousel";
import { ProductDescription } from "./Product/productDescription";
import { useRelocate } from "../../../relocate";
import { DownloadPopup } from "./Product/downloadPopup";
import { ProfileIndex } from "../../Component/profileIndex";
import {
  ICultureContextType,
  CultureContext,
} from "../../../Context/cultureContext";

interface IProduct {
  brands: any;
  criteriaCategories: any;
  fileDescriptors: any;
  imageIds: string[];
  lastUpdated: any;
  principalRegion: string;
  productDescription: string;
  productExternalUrls: any;
  productId: string;
  productReference: any;
  productShortDescription: any;
  productSubTitle: any;
  productTitle: any;
}

interface IState {
  productData: null | IProduct;
  loading: boolean;
  imgUrls: string[];
}

const initialState = { productData: null, loading: true, imgUrls: [] };

export const EmbedEcophonProduct = () => {
  const { locale, name, region } = useParams();
  const { redirect } = useRelocate();
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState<IState>(initialState);
  const [openDownload, setOpenDownload] = useState(false);
  const { setParams } = useContext(CultureContext) as ICultureContextType;

  useEffect(() => {
    i18n.changeLanguage(locale);

    if (name && region && locale) {
      setData(initialState);
      setParams({ region, locale });
      getProductDetails(name, region, locale).then(({ product, regionId }) => {
        if (product) {
          if (!product.message) {
            updateProductViewCount(product.productId, regionId, locale);
            setData({
              productData: product,
              loading: false,
              imgUrls: product.imageIds,
            });
            return;
          }
          setData({ productData: null, loading: false, imgUrls: [] });
        }
      });
    }
  }, [locale, name, region, i18n]);

  //when product is loading, then this skeleton will be shown
  if (data.loading) {
    return <ProductSkeleton />;
  }

  // to close download popup
  const handleCloseDropdown = () => {
    setOpenDownload(false);
  };
  // to open download popup
  const handleOpenDropdown = async (product: any) => {
    if (region) {
      setOpenDownload(true);
    }
  };

  return (
    <Box className="main-container product-container embed-product-container">
      <Box className="product-nav-btn">
        <div
          className="space-from-top btn-goback-container"
          style={{ display: "inline-flex" }}
        >
          <Button
            variant="contained"
            className="btn btn-primary btn-normal btn-goback"
            onClick={() => redirect(-1)}
          >
            {t("common.goback_btn")} <ArrowBackIosNewIcon />
          </Button>
        </div>

        <ProfileIndex />
      </Box>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item lg={12} md={12}>
          <Typography className="product-heading primary-header">
            {data.productData?.productTitle}
          </Typography>
          <Typography className="product-title secondary-header">
            {data.productData?.productSubTitle}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} className="p-r-20">
          <ProductCarousel imageIds={data.imgUrls} />
          <Button
            variant="contained"
            className="btn btn-primary btn-normal btn-product-contact m-t-20"
            startIcon={<DownloadIcon sx={{ fontSize: 40 }} />}
            onClick={() => handleOpenDropdown(data.productData)}
          >
            {t("product_contact_page.download_bim_obj")}
          </Button>
          <DownloadPopup
            openDownload={openDownload}
            handleCloseDropdown={handleCloseDropdown}
            productDetails={data.productData}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12} className="p-r-10">
          {data.productData && (
            <ProductDescription product={data.productData} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
