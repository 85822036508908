import React, { useContext, useEffect, useMemo, useState } from "react";
import "../../Utils/assets//regionMenu.scss";
import {
  FormControl,
  Typography,
  MenuItem,
  Button,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import LanguageIcon from "@mui/icons-material/Language";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BimlibContext, BimlibContextType } from "../../Context/context";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

export const RegionMenu = () => {
  const { locale, region = "France" } = useParams();
  const { initBasket } = useContext(BimlibContext) as BimlibContextType;
  const { cultureLoading, regions, setParams } = useContext(
    CultureContext
  ) as ICultureContextType;
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation("common");
  let [searchParams] = useSearchParams();

  useEffect(() => {
    setParams({ region, locale });
  }, [region, locale, setParams]);

  useEffect(() => {
    if (region) {
      initBasket(region);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  const handleChange = async (event: SelectChangeEvent<any>) => {
    if (cookies.get("regionname")) {
      cookies.remove("regionname");
    }
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 100);
    cookies.set("regionname", event.target.value, {
      path: "/",
      expires: date,
    });

    if (searchParams.has("brand")) {
      navigate(
        `/${locale}/${event.target.value}/browse?${createSearchParams({
          brand: "all",
          sort: "downloads",
          order: "desc",
        })}`
      );
    } else {
      let { pathname, search } = window.location;
      let splittedPathName = pathname.split("/");
      splittedPathName[2] = event.target.value;
      const newPathName = splittedPathName.join("/");
      navigate(`${newPathName}${search}`);
    }
  };

  if (cultureLoading) {
    return (
      <FormControl
        sx={{ m: 1, minWidth: 120, mr: "20px" }}
        className="without-outline-select"
      >
        <LanguageIcon className="language-icon" />
      </FormControl>
    );
  }

  if (regions && region) {
    const userRegion =
      regions.find(
        (reg: any) => reg.label.toLowerCase() === region.toLowerCase()
      )?.translation || "";

    if (userRegion === "") {
      navigate("/pagenotfound");
    } else {
      return (
        <>
          <Typography component="h3" className="display-region">
            {userRegion}
          </Typography>
          <FormControl
            sx={{ m: 1, minWidth: 120, mr: "20px" }}
            className="without-outline-select"
          >
            <Button
              id="openRegionMenu"
              onClick={() => !open && setOpen(true)}
              className="btn-region"
            >
              <LanguageIcon className="language-icon" />
              <Typography component="div" id="chngRegion">
                {t("header.change_region")}
              </Typography>
              <ArrowDropDownCircleIcon />

              <Select
                defaultValue="France"
                value={region}
                variant="standard"
                displayEmpty
                onChange={handleChange}
                style={{ display: "none" }}
                open={open}
                onClose={() => setOpen(false)}
                MenuProps={{
                  anchorEl: document.getElementById("openRegionMenu"),
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  disableScrollLock: true,
                }}
              >
                <MenuItem disabled>{t("common.seeproduct_available")}</MenuItem>
                {regions.map((reg: any) => (
                  <MenuItem
                    key={reg.label}
                    value={reg.label}
                    className={
                      region === reg.label
                        ? "Mui-selected region-item"
                        : "region-item not-selected"
                    }
                  >
                    <span className="">{reg.translation}</span>
                    <span className="">{reg.productCount + " products"}</span>
                  </MenuItem>
                ))}
              </Select>
            </Button>
          </FormControl>
        </>
      );
    }
  }

  return <></>;
};
