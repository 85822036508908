import { useForm } from "../Components/Contact/useForm";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  FormLabel,
  CircularProgress,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Done } from "@mui/icons-material";
import Job from "../Data/job.data.json";
import "../Utils/assets/contactUs.scss";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCaptchaV2 from "react-google-recaptcha";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React from "react";
import Cookies from "universal-cookie";
import { useRelocate } from "../relocate";
import { validate } from "../Components/Contact/validation";
import { useParams, useSearchParams } from "react-router-dom";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";
import { MyConfig } from "../API/myConfig";
import { CultureContext, ICultureContextType } from "../Context/cultureContext";
import { ListAllBrands, ListAllBimLibBrands } from "../API";

export const ContactUs = () => {
  const jobList = Job; //temporary
  const [loading, setLoading] = useState(false);
  const [siteKeyURL, setSiteKeyURL] = useState<any>("");
  // const contactId: string | null = location.state;
  const { t, i18n } = useTranslation("common");
  const [refCaptcha, setRefCaptcha] = useState<any>();
  const [contactId, setContactId] = useState<any>();
  const [brands, setBrands] = useState<any[]>([]);
  const { relocate } = useRelocate();
  const { locale, region } = useParams();
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { regions } = useContext(CultureContext) as ICultureContextType;
  const {
    values,
    errors,
    statusCode,
    open,
    resetValues,
    handleChange,
    //getSiteKey,
    handleToken,
    handleExpire,
    handleSubmit,
    handleClose,
  } = useForm(login, contactId, validate);
  

  useEffect(() => {
    i18n.changeLanguage(locale);
    const cookies = new Cookies();
    if (cookies.get("commercialContactId")) {
      setContactId(cookies.get("commercialContactId"));
    }

    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, locale]);

  useEffect(() => {
    ListAllBimLibBrands().then(brandFromBimdb => {
      brandFromBimdb = brandFromBimdb
      .map((b:{brandId: string, brandName: string}) =>  {
        return {oid: b.brandId, translation: b.brandName};
      })
      .sort((a:{oid: string, translation: string}, b:{oid: string, translation: string}) => a.translation.localeCompare(b.translation));

      const allOption = { oid: 'all', translation: 'All' };
      const brandsWithAll = [allOption, ...brandFromBimdb];

      setBrands(brandsWithAll)

    });
    
  }, [locale])

   const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function login() {
    console.log("No errors, submit callback called!");
  }

  useEffect(() => {
    siteKeyUrl();
    window.scrollTo(0, 0);
  }, []);

  const coId = searchParams.get("coId");
  const brandId = searchParams.get("brandId");
  const regionId = searchParams.get("regionId");
  const disableBrandAndRegion = brandId && brandId != 'undefined' && regionId && regionId != 'undefined';



  const getSiteKey = async () => {
    let config = await MyConfig();
    return config.REACT_APP_SITE_KEY;
  };

  // get reCaptcha Sitekey
  const siteKeyUrl = async () => {
    setLoading(true);
    let siteUrl = await getSiteKey().then((url: any) => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      localStorage.setItem("siteUrl", url);
      setSiteKeyURL(url);
      return url;
    });
    return siteUrl;
  };

  const inputField = (
    value: string,
    name: string,
    label: string,
    type: string,
    required: boolean,
    multiline: boolean,
    nbRows: number,
    autoComplete: string
    // placeholder: string
  ) => {
    return (
      <FormControl fullWidth className="form-group">
        <TextField
          id="contactUsTextField"
          value={value}
          onChange={handleChange}
          label={label}
          name={name}
          InputLabelProps={{
            shrink: true,
          }}
          type={type}
          multiline={multiline}
          rows={nbRows}
          size="small"
          error={errors[name] === " "}
          {...(errors[name] && {
            error: true,
            helperText: errors[name],
          })}
          required={required}
          autoComplete={autoComplete}
          //defaultValue=" "
          // placeholder={placeholder}
        />
      </FormControl>
    );
  };



  return (
    <>
      <CanonicalStaticTag page="/contactus" />
      <Box className="main-container">
        <Box component="section" className="contact-us">
          <div
            className="space-from-top btn-goback-container"
            style={{ display: "inline-flex" }}
          >
            <Button
              variant="contained"
              className="btn btn-primary btn-normal btn-goback"
              id="goBackBtn"
              onClick={() => relocate(-1)}
            >
              {t("common.goback_btn")}
              <ArrowBackIosNewIcon />
            </Button>
          </div>
          <Typography
            component="h2"
            className="text-left section-heading"
            style={{
              display: "inline-flex",
              marginLeft: "auto",
              justifyContent: "center",
              width: "85%",
            }}
          >
            {t("contact_page.title")}
          </Typography>
          <form
            name="contactForm"
            id="submitContact"
            noValidate
            onSubmit={(e: any) => handleSubmit(e, refCaptcha,brands)}
          >
            <div className="contactus-container">
              {/* <Grid container id="salutaion-id">
                <Grid item sm={12} md={12} lg={10}>
                  <FormLabel id="salutation" className="radiocheck-label">
                    {t("contact_page.title")} *
                  </FormLabel>
                  <FormControl className="form-group">
                    <RadioGroup
                      row
                      onChange={handleChange}
                      aria-labelledby="salutation"
                      name="title"
                      defaultValue={
                        values.title ? values.title : t("contact_page.mr")
                      }
                      value={values.title ? values.title : t("contact_page.mr")}
                    >
                      <FormControlLabel
                        value={t("contact_page.miss")}
                        control={<Radio />}
                        label={t("contact_page.miss")}
                      />
                      <FormControlLabel
                        value={t("contact_page.mrs")}
                        control={<Radio />}
                        label={t("contact_page.mrs")}
                      />
                      <FormControlLabel
                        value={t("contact_page.mr")}
                        control={<Radio />}
                        label={t("contact_page.mr")}
                      />
                    </RadioGroup>
                    {errors.title && (
                      <p className="help is-danger">{errors.title}</p>
                    )}
                  </FormControl>
                </Grid>
              </Grid> */}
              <Grid
                container
                className="p-t-0"
                justifyContent={"space-between"}
                spacing={2}
              >
                <Grid item sm={12} md={6} lg={6} id="grid-full0name">
                {inputField(
                    values.fullName,
                    "fullName",
                    `${t("contact_page.full_name")}`,
                    "text",
                    true,
                    false,
                    0,
                    "off"
                    // "Enter full name"
                  )}
                </Grid>
                <Grid item sm={12} md={6} lg={6} id="grid-email">
                {inputField(
                    values.email,
                    "email",
                    `${t("contact_page.email")}`,
                    "email",
                    true,
                    false,
                    0,
                    "off"
                    // "Enter email"
                  )}
                </Grid>
                <Grid item sm={12} md={6} lg={6} id="grid-phone-number">
                {inputField(
                    values.phoneNumber,
                    "phoneNumber",
                    `${t("contact_page.phone_number")}`,
                    "phone",
                    false,
                    false,
                    0,
                    "off"
                    // "Enter phone number"
                  )}
                </Grid>
                <Grid item sm={12} md={6} lg={6} id="grid-company-name">
                {inputField(
                    values.company,
                    "company",
                    `${t("contact_page.company_name")}`,
                    "text",
                    true,
                    false,
                    0,
                    "off"
                    // "Enter company name"
                  )}
                </Grid>
                <Grid item sm={12} md={6} lg={6} id="grid-brand-name">
                  <FormControl fullWidth  className="form-group">
                    <InputLabel id="contact_page.brand-label">{`${t("contact_page.brand")}`} *</InputLabel>
                    <Select
                      labelId="contact_page.brand-label"
                      id="contact_page.brand"
                      value={values.brand}
                      name={"brand"}
                      disabled={disableBrandAndRegion}
                      required={true}
                      // label={`${t("contact_page.country")}`}
                      onChange={handleChange}
                      error={errors.brand === ""}
                      {...(errors.brand && {
                        error: true,
                        
                      })}
                    >            
                      {brands?.map(value =>{
                        return <MenuItem key={value.oid} value={value.oid}>{value.translation}</MenuItem>
                      }) }                      
                    </Select>        
                    <FormHelperText error={errors.brand !== ""}>{errors.brand}</FormHelperText>            
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6} lg={6} id="grid-country">
                {/* {inputField(
                    values.country,
                    "country",
                    `${t("contact_page.country")}`,
                    "text",
                    true,
                    false,
                    0,
                    "off"
                    // "Enter country"
                  )} */}
                  <FormControl fullWidth  className="form-group">
                    <InputLabel  id="contact_page.country-label">{`${t("contact_page.country")}`} *</InputLabel>
                    <Select
                      labelId="contact_page.country-label"
                      id="contact_page.country"
                      value={values.country}
                      // label={`${t("contact_page.country")}`}
                      onChange={handleChange}
                      disabled={disableBrandAndRegion}
                      name="country"
                      required={true}
                      error={errors.country === ""}
                      {...(errors.country && {
                        error: true,
                        
                      })}
                    >
                      {/* <MenuItem key="" value="">{""}<br/></MenuItem>           */}
                      {regions?.sort((a:{oid: string, translation: string}, b:{oid: string, translation: string}) => a.translation.localeCompare(b.translation))
                      .map(value =>{
                        return <MenuItem key={value.oid} value={value.oid}>{value.translation}</MenuItem>
                      }) }
                      
                    </Select>
                    <FormHelperText error={errors.country !== ""}>{errors.country}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} lg={12} id="grid-subject">
                {inputField(
                    values.subject,
                    "subject",
                    `${t("contact_page.subject")}`,
                    "text",
                    true,
                    false,
                    0,
                    "off"
                    // "Enter subject"
                  )}
                </Grid>
                <Grid item sm={12} md={12} lg={12} id="grid-message">
                {inputField(
                    values.message,
                    "message",
                    `${t("contact_page.message")}`,
                    "text",
                    true,
                    true,
                    6,
                    "off"
                    // "Enter message"
                  )}
                </Grid>
                
                <Grid item sm={12} md={4} lg={4} id="grid-1">
                  
                  

                  
                  {/* {inputField(
                    values.address,
                    "address",
                    `${t("contact_page.address")}`,
                    "text",
                    false,
                    false,
                    0,
                    "off"
                    // "Enter address"
                  )}
                  <FormControl fullWidth className="form-group">
                    <InputLabel id="job_select" shrink>
                      {t("contact_page.job_title")} *
                    </InputLabel>
                    <Select
                      id="job_select"
                      labelId="job_select"
                      onChange={handleChange}
                      name="jobTitle"
                      value={values.jobTitle ? values.jobTitle : ""}
                      // IconComponent={() => <ArrowDropDownCircle />}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      }}
                    >
                      <MenuItem disabled value="">
                        {t("contact_page.choose_profession")}
                      </MenuItem>
                      {jobList.map((job) => (
                        <MenuItem key={job.label} value={job.label}>
                          <Typography variant="body1">{job.label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.jobTitle && (
                      <p className="help is-danger">{errors.jobTitle}</p>
                    )}
                  </FormControl> */}
                  

                  
                </Grid>
                <Grid item sm={12} md={12} lg={12} id="grid-2">
                  

                  

                  {!loading ? (
                    <ReCaptchaV2
                      onChange={handleToken}
                      className="center"
                      // sitekey={siteKeyURL}
                      sitekey={localStorage.getItem("siteUrl") || siteKeyURL}
                      onExpired={handleExpire}
                      ref={(e) => setRefCaptcha(e)}
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress style={{ margin: "0px auto" }} />
                    </div>
                  )}
                  {errors.token && (
                    <p
                      className="help is-danger"
                      style={{ width: "304px", margin: "0 auto" }}
                    >
                      {errors.token}
                    </p>
                  )}
                  {/*TODO insert captcha*/}
                  <Box className="form-group text-center">
                    <Button
                      variant="contained"
                      className="btn btn-primary btn-normal m-t-20"
                      id="submitContactPage"
                      type={"submit"}
                      onClick={(e: any) => handleSubmit(e, refCaptcha,brands)}
                    >
                      {t("contact_page.submit_btn")} <Done />
                    </Button>
                    {statusCode === 200 ? (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          sx={{ width: "100%" }}
                        >
                          Request submitted successfully.
                        </Alert>
                      </Snackbar>
                    ) : (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          Request failed, please try again!
                        </Alert>
                      </Snackbar>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </form>
        </Box>
      </Box>
    </>
  );
};
