import * as React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { CookiesSetting } from "../CookieSetting/cookiesSetting";
import { useTranslation } from "react-i18next";

export const CookieSettingsFloating = () => {
  /** STARTS: To open Cookie setting modal popup  **/
  const [openCookie, setOpenCookie] = React.useState(false);
  const handleOpenCookiesSetting = () => {
    setOpenCookie(true);
  };
  const handleCloseCookiesSetting = () => {
    setOpenCookie(false);
  };

  const { t } = useTranslation("common");

  /** ENDS: To open Cookie setting modal popup  **/

  return (
    <>
      <div
        className="floating-container floating-settings"
        id="cookieModal"
        onClick={handleOpenCookiesSetting}
      >
        <div className="floating-icon">
          <SettingsIcon />
        </div>
        <div className="floating-text">
          {t("cookie_setting_popup.setting_btn")}
        </div>
      </div>

      <CookiesSetting
        handleCloseCookiesSetting={handleCloseCookiesSetting}
        openCookie={openCookie}
      />
    </>
  );
};
