import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { BackToTop } from "../Components/Common/backToTop";
import ScrollToTop from "../Components/Common/ScrollToTop";
import { SessionTime } from "../Components/Common/SessionTime";
import { CookiePreference } from "../Components/CookieSetting/cookiePreference";
import { ContactUSFloating } from "../Components/Footer/contactUsFloating";
import { CookieSettingsFloating } from "../Components/Footer/cookieSettingsFloating";
import { Footer } from "../Components/Footer/footer";
import { Header } from "../Components/Header/header";
import { CultureContext, ICultureContextType } from "../Context/cultureContext";

export const Layout = () => {
  const [isHide, setIsHide] = useState<boolean>(true);

  useEffect(() => {
    if (isHide) {
      const timeout = setTimeout(() => setIsHide(false), 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isHide]);

  return (
    // added style attr because, to not show <Header /> and <Footer /> component after login in IFRAME
    <div style={{ display: isHide ? "none" : "block" }}>
      <Header />
      <Outlet />
      <Footer />
      <BackToTop />
      <CookieSettingsFloating />
      <ContactUSFloating />
      <ScrollToTop />
      <SessionTime />
      <CookiePreference />
    </div>
  );
};
