import { List, Pagination, Skeleton, Typography } from "@mui/material";
import React from "react";
import "../../../Resources/products.scss";
import { SelectChangeEvent } from "@mui/material/Select";
import { chunk } from "lodash";
import { SortBar } from "./sortBar";
import ProductCard from "./productCard";

interface Props {
  products: any;
  page: number;
  changePage: any;
  sort: string;
  changeOrder: any;
  brands: any;
  loading: boolean;
  config: any;
  totalPages: number;
  totalCount: number;
  brand: string;
}

export const Products = ({
  products,
  page,
  changePage,
  sort,
  changeOrder,
  brands,
  loading,
  config,
  totalPages,
  totalCount,
  brand,
}: Props) => {
  const handleFilterChange = (event: SelectChangeEvent<any>) => {
    changeOrder(event.target.value);
  };

  // for product list section, this skeleton will be shown while on page load

  if (products) {
    return (
      <React.Fragment>
        <SortBar
          productCount={totalPages}
          totalCount={totalCount}
          selectedValue={sort}
          handleFilterChange={handleFilterChange}
        />
        {products && (
          <>
            <List className={"list-products"}>
              {products.map((data: any) => {
                return (
                  <ProductCard
                    product={data}
                    key={data.oid}
                    brands={brands}
                    config={config}
                    brand={brand}
                  />
                );
              })}
            </List>
            <Pagination
              count={totalPages}
              size="large"
              page={page}
              onChange={(e, page) => changePage(page)}
              className={"browse-pagination"}
            />
          </>
        )}
      </React.Fragment>
    );
  }

  return (
    <div style={{ margin: "0px auto", textAlign: "center" }}>
      No products for selected criteria
    </div>
  );
};
