import { SyntheticEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Link,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Cookies from "universal-cookie";
import { MarketRegionPopup } from "./marketRegionPopup";
import { useRelocate } from "../../relocate";
import { ICookieSetting } from "../CookieSetting/cookiePreference";
import CookiesToggleLanguage from "../CookieSetting/CookieToggleLanguage";
import { initializeGTM } from "../../Utils/gtmConfig";
import _, { forEach } from "lodash";
import { t } from "../../Utils/helper";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  openCookie: boolean;
  handleCloseCookiesSetting: any;
};

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const CookiesSetting = (props: Props) => {
  const [expandedAccord, setExpandedAccord] = useState<string | false>(
    "panel1"
  );
  const [customLanguage, setLanguage] = useState<string>("en");
  const [enableGoogleAnalytics, setGoogleAnalytics] = useState<boolean>(false);
  const [openRegion, setOpenRegionPopup] = useState<boolean>(false);
  const { relocate } = useRelocate();
  const cookies = new Cookies();
  const regionname = cookies.get("regionname") || "France";

  useEffect(() => {
    let foundCookies: ICookieSetting = JSON.parse(
      localStorage.getItem("cookieSetting") || "{}"
    );
    if (foundCookies.hasOwnProperty("allowCookie")) {
      const localGA: boolean = JSON.parse(
        localStorage.getItem("enableGA") || "false"
      );
      setGoogleAnalytics(localGA);
    }
  }, []);

  const handleAccordChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccord(newExpanded ? panel : false);
    };

  const onCustomChoiceClick = () => {
    localStorage.setItem("enableGA", JSON.stringify(enableGoogleAnalytics));
    localStorage.setItem(
      "cookieSetting",
      JSON.stringify({ allowCookie: true })
    );
    if (enableGoogleAnalytics) {
      // write code for consent GTM
      initializeGTM();
    } else {
      let allCookies = cookies.getAll();
      let keys = Object.keys(allCookies);
      let date = new Date();
      date.setDate(date.getDate() - 1);
      _.forEach(keys, (key) => {
        if (key.startsWith("_ga")) {
          cookies.remove(key, { path: "/", expires: date });
        }
      });
    }
    props.handleCloseCookiesSetting(false);
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0";

    // This is written to set user choice language back, which where changed becasue of
    // cookieToggleLanguage component
    const cookie = new Cookies();

    if (!cookie.get("regionname")) {
      setOpenRegionPopup(true);
    }
  };

  const handleCloseRegionPopup = (toLocation: string) => {
    setOpenRegionPopup(false);
    relocate(toLocation);
  };

  return (
    <>
      {openRegion && (
        <MarketRegionPopup
          openRegionPopup={openRegion}
          closeRegionPopup={handleCloseRegionPopup}
        />
      )}
      <Dialog
        open={props.openCookie}
        aria-labelledby="Cokkie-settings-dialog-title"
        aria-describedby="Cokkie-settings-dialog-description"
        className="CookieSettingsClass"
      >
        <DialogContent>
          <DialogContentText id="Cokkie-settings-dialog-description">
            <div className="m-b-15">
              <div className="m-b-5">
                <strong className="font-size-20 f-w-500">
                  {t("cookie_setting_popup.general_info.title", customLanguage)}
                </strong>
                <CookiesToggleLanguage
                  setLanguage={(value: string) => setLanguage(value)}
                  language={customLanguage}
                />
              </div>
              <div className="without-border">
                <Accordion
                  expanded={expandedAccord === "panel1"}
                  onChange={handleAccordChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      {t(
                        "cookie_setting_popup.general_info.question1",
                        customLanguage
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ marginBottom: "0" }}>
                      {t(
                        "cookie_setting_popup.general_info.answer1",
                        customLanguage
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedAccord === "panel2"}
                  onChange={handleAccordChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography>
                      {" "}
                      {t(
                        "cookie_setting_popup.general_info.question2",
                        customLanguage
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ marginBottom: "0" }}>
                      {t(
                        "cookie_setting_popup.general_info.answer2",
                        customLanguage
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <Link
                className="font-size-16 color-secondary"
                href={`/en/${regionname}/cookie-policy`}
                target="blank"
              >
                {t(
                  "cookie_setting_popup.general_info.moreInformation",
                  customLanguage
                )}
              </Link>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <div>
                    <div className=" m-b-5">
                      <strong className="font-size-20 f-w-500">
                        {t(
                          "cookie_setting_popup.bimlib_cookie.title",
                          customLanguage
                        )}
                      </strong>
                    </div>
                    <p className="m-t-5">
                      {t(
                        "cookie_setting_popup.bimlib_cookie.description",
                        customLanguage
                      )}
                    </p>
                    <Link
                      className="font-size-16 color-secondary"
                      href={`/en/${regionname}/cookie-policy`}
                      target="blank"
                    >
                      {t("common.learnmore", customLanguage)}
                    </Link>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup aria-label="position" row className="float-right">
                    <FormControlLabel
                      value={t(
                        "cookie_setting_popup.bimlib_cookie.always_active",
                        customLanguage
                      )}
                      control={<Checkbox />}
                      label="Always Active"
                      labelPlacement="start"
                      disabled
                      checked
                      className="disable-checkbox"
                      id="disabledCheckbox"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <div className=" m-b-5">
                      <strong className="font-size-20 f-w-500">
                        {t(
                          "cookie_setting_popup.google_analytics.title",
                          customLanguage
                        )}
                      </strong>
                    </div>
                    <p className="m-t-5">
                      {t(
                        "cookie_setting_popup.google_analytics.description",
                        customLanguage
                      )}
                    </p>
                    <Link
                      className="font-size-16 color-secondary"
                      href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                      target="blank"
                    >
                      {t("common.learnmore", customLanguage)}
                    </Link>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup aria-label="position" row className="float-right">
                    <FormControlLabel
                      value={enableGoogleAnalytics ? "Enabled" : "Disabled"}
                      onChange={() =>
                        setGoogleAnalytics(!enableGoogleAnalytics)
                      }
                      control={<Checkbox />}
                      label={enableGoogleAnalytics ? "Enabled" : "Disabled"}
                      labelPlacement="start"
                      checked={enableGoogleAnalytics}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCustomChoiceClick}
            className="btn btn-primary btn-popup bg-secondary"
            variant="contained"
          >
            {t("cookie_setting_popup.confirm_btn", customLanguage)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
