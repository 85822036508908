import React from "react";
import { Skeleton, Typography } from "@mui/material";

const SkeletonComponent = (props: any) => {
    return (
        <>
            <Typography
                style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    paddingRight: "20px",
                }}
                width={props.width}
            >
                <Skeleton variant="text" height={props.height} />
            </Typography>
        </>
    )
}

export default SkeletonComponent;