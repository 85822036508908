import reportWebVitals from "./reportWebVitals";
import en from "./Utils/i18next/en.json";
import de from "./Utils/i18next/de.json";
import fr from "./Utils/i18next/fr.json";
import ru from "./Utils/i18next/ru.json";
import it from "./Utils/i18next/it.json";
import nl from "./Utils/i18next/nl.json";
import pt from "./Utils/i18next/pt.json";
import sq from "./Utils/i18next/sq.json";
import es from "./Utils/i18next/es.json";
import zh from "./Utils/i18next/zh.json";
import hi from "./Utils/i18next/hi.json";
import "./index.scss";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Cookies from "universal-cookie";
import BasketProvider from "./Context/context";
import { Root } from "./root";
import CultureProvider from "./Context/cultureContext";

declare global {
  var bimway: any;
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <CultureProvider>
    <BasketProvider>
      <I18nextProvider i18n={i18next}>
        <Root />
      </I18nextProvider>
    </BasketProvider>
  </CultureProvider>
);

const cookies = new Cookies();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: cookies.get("language") || "en", // language to use
  resources: {
    en: {
      common: en, // 'common' is our custom namespace
      cookiesetting: en,
    },
    de: {
      common: de,
    },
    fr: {
      common: fr,
      cookiesetting: fr,
    },
    ru: {
      common: ru,
    },
    it: {
      common: it,
    },
    nl: {
      common: nl,
    },
    pt: {
      common: pt,
    },
    sq: {
      common: sq,
    },
    es: {
      common: es,
    },
    zh: {
      common: zh,
    },
    hi: {
      common: hi,
    },
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
