import React, { useEffect, useRef, useContext, useMemo } from "react";
import "../Utils/assets/browse.scss";
import { Box } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { destructParams, searchProduct } from "../API";
import BrandInfo from "../Components/Browse/brandInfo";
import { Filter } from "../Components/Browse/filter";
import { Brand } from "../Components/Browse/brand";
import { Products } from "../Components/Browse/products";
import { useTranslation } from "react-i18next";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";
import { CultureContext, ICultureContextType } from "../Context/cultureContext";
import { ModalPopup } from "../Components/Browse/modalPopup";
import BrowseProvider from "../Context/browseContext";

export const Browse = () => {
  const { locale = "en", region = "France" } = useParams();
  const { regions } = useContext(CultureContext) as ICultureContextType;
  let [searchParams, setSearchParams] = useSearchParams();

  const params = destructParams(searchParams);

  const {
    brand,
    sortBy,
    sortDirection,
    formats,
    criterias,
    page,
    format,
    criteria,
    sort,
    search,
  } = params;

  const pageTopRef = useRef<null | HTMLElement>(null);

  const deleteParamsEntry = (
    params: URLSearchParams,
    key: string,
    value: string
  ) => {
    const newEntries = Array.from(params.entries()).filter(
      ([k, v]: any) => !(k === key && v === value)
    );
    return new URLSearchParams(newEntries);
  };

  const toggleFilter = (
    key: string,
    value: string,
    isDelete: boolean = false
  ) => {
    const prsentParam = searchParams.getAll(key);
    searchParams.set("page", "1");
    /// adjustment for Range filter
    if (value.includes("$range")) {
      const takeCriteria = value.substring(0, value.indexOf("$range"));
      const newEntries = Array.from(searchParams.entries()).filter(
        ([k, v]: any) => !(k === key && v.includes(takeCriteria))
      );
      searchParams = new URLSearchParams(newEntries);
      if (!isDelete) {
        searchParams.append(key, value);
      }
    } else {
      if (prsentParam?.includes(value)) {
        searchParams = deleteParamsEntry(searchParams, key, value);
      } else {
        searchParams.append(key, value);
      }
    }
    setSearchParams(searchParams);
  };

  const changePage = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString());
    setSearchParams(searchParams);
    pageTopRef?.current?.scrollIntoView();
  };

  const changeOrder = (sortBy: string) => {
    let sortorder = "ASC";

    const currentBy = sortBy; //searchData.sort.split("_")[0];
    let currentOrder = sortDirection; //searchData.sort.split("_")[1];

    const sorttobe =
      sortBy === "TITLEA" || sortBy === "TITLED" ? "TITLE" : sortBy;

    if (currentBy === sorttobe) {
      if (currentOrder === "ASC" || sortBy === "TITLED") {
        sortorder = "DESC";
      }
    }

    if (sortBy === "TITLEA") {
      sortorder = "ASC";
    }

    if (sortBy === "TITLED") {
      sortorder = "DESC";
    }

    searchParams.set("sort", `${sorttobe}_${sortorder}`);
    setSearchParams(searchParams);
  };

  return (
    <BrowseProvider>
      <CanonicalStaticTag page={`/${window.location.search}`} />
      <Box className="main-container browse-container" ref={pageTopRef}>
        <Box component="aside" className="filter-container">
          <SimpleBarReact>
            <Box className="page-filter">
              <BrandInfo />
              <Filter />
            </Box>
          </SimpleBarReact>
        </Box>
        <Box className="brand-product-container">
          <Brand />
          <Products />
          <ModalPopup />
        </Box>
      </Box>
    </BrowseProvider>
  );
};
