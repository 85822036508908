import { forEach } from "lodash";
import { CriteriaValue, FilterCategory, FilterCriteria } from "../Types";

export const createFilters = (
  categoryList: any,
  formatList: any,
  selectedBrand: string
): FilterCategory[] | [] => {
  let responseFilter: FilterCategory[] = [];

  if (categoryList && categoryList.length > 0) {
    if (selectedBrand.toLowerCase() === "all") {
      categoryList = categoryList.filter((flt: any) => flt.principal === true);
    }
    forEach(categoryList, (flt: any) => {
      let newFilterCriteria: FilterCriteria[] = [];

      if (flt.criteriaCategoryType === "ADVANCED") {
        forEach(flt.criterias, (crt: any) => {
          if (crt.criteriaType === "RANGE") {
            let newCriteria: FilterCriteria = {
              id: crt.criteriaId,
              label: crt.label,
              type: "criteria",
              values: null,
              nbitems: crt.nbitems,
              maxValue: crt.maxValue || 0,
              minValue: crt.minValue || 0,
              unit: crt.criteriaUnit,
              filterType: "RANGE",
            };

            newFilterCriteria.push(newCriteria);
          }

          if (crt.criteriaType === "LIST") {
            let newCriteria: FilterCriteria = {
              id: crt.criteriaId,
              label: crt.label,
              type: "criteria",
              values: null,
              nbitems: crt.nbitems,
              maxValue: crt.maxValue,
              minValue: crt.minValie,
              unit: crt.criteriaUnit,
              filterType: "LIST",
            };

            let newCriteriaValues: CriteriaValue[] = [];
            forEach(crt.criteriaValues, (crtvalue: any) => {
              let newCriteriaValue: CriteriaValue = {
                id: crtvalue.id,
                label: crtvalue.label,
                nbitems: crtvalue.nbitems,
                criteriaId: crtvalue.id, //crt.criteriaId,
              };
              newCriteriaValues.push(newCriteriaValue);
            });
            newCriteria.values = newCriteriaValues;
            newFilterCriteria.push(newCriteria);
          }
        });
      }

      if (flt.criteriaCategoryType === "SIMPLE") {
        let newCriteria: FilterCriteria = {
          id: flt.id,
          label: flt.label,
          type: "criteria",
          values: null,
          nbitems: flt.nbitems,
          maxValue: 0,
          minValue: 0,
          unit: "",
          filterType: "LIST",
        };

        let newCriteriaValues: CriteriaValue[] = [];
        forEach(flt.criterias, (crtvalue: any) => {
          let newCriteriaValue: CriteriaValue = {
            id: crtvalue.criteriaId,
            label: crtvalue.label,
            nbitems: crtvalue.nbitems,
            criteriaId: crtvalue.criteriaId,
          };
          newCriteriaValues.push(newCriteriaValue);
        });
        newCriteria.values = newCriteriaValues;
        newFilterCriteria.push(newCriteria);
      }

      let newCategory: FilterCategory = {
        type: flt.criteriaCategoryType,
        label: flt.label,
        criterias: newFilterCriteria,
        id: flt.criteriaCategoryId,
        principal: flt.principal,
      };

      responseFilter.push(newCategory);
    });
  }

  if (formatList && formatList.length > 0) {
    let newFilterCriteria: FilterCriteria[] = [];

    let newCriteria: FilterCriteria = {
      id: "format-0",
      label: "Format Category",
      type: "format",
      values: null,
      nbitems: 0,
      maxValue: 0,
      minValue: 0,
      unit: "",
      filterType: "LIST",
    };

    let newCriteriaValues: CriteriaValue[] = [];
    forEach(formatList, (crtvalue: any) => {
      let newCriteriaValue: CriteriaValue = {
        id: crtvalue.formatId,
        label: crtvalue.label,
        nbitems: crtvalue.nbitems,
        criteriaId: crtvalue.formatId,
      };
      newCriteriaValues.push(newCriteriaValue);
    });
    newCriteria.values = newCriteriaValues;
    newFilterCriteria.push(newCriteria);

    let newCategory: FilterCategory = {
      type: "format",
      label: "Format Category",
      criterias: newFilterCriteria,
      id: "format-0",
      principal: true,
    };

    responseFilter.push(newCategory);
  }

  return responseFilter.length > 0 ? responseFilter : [];
};
