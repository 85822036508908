import { isExpired } from "react-jwt";
import { MyConfig } from "./myConfig";

export const SendSalesForceData = async (projectIds: any[]) => {
  let config = await MyConfig();
  const hostUrl = config.REACT_APP_API_URL + "/user/sendDataToSalesForce";
  const token: any = localStorage.getItem("token");
  const isMyTokenExpired = isExpired(token);
  if (!isMyTokenExpired) {
    // return fetch(hostUrl, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + token,
    //   },
    //   body: JSON.stringify(projectIds),
    // })
    //   .then((response) => {
    //     //return response.json();
    //     return response;
    //   })
    //   .catch((err: any) => {
    //     console.log("Could not send data to salesforce!", err);
    //   });
  }
};
