import axios from "axios";
import TagManager from "react-gtm-module";
import { isExpired } from "react-jwt";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { useRelocate } from "../relocate";
import { sendDataToGTM } from "../Utils/gtmConfig";
import { encryptUserId } from "../Utils/helper";
import { MyConfig } from "./myConfig";
import { AuthorizationCodeGrantResponse } from "./Types/authenticate.type";
import { AuthorizationCodeRequest } from "./Types/authorizationCodeRequest.type";

export const getAccessToken = async (
  authCode: string
): Promise<AuthorizationCodeGrantResponse> => {
  let cnf = await MyConfig();

  const body: AuthorizationCodeRequest = {
    authCode: authCode,
    redirectUri: cnf.REACT_APP_URL + "/authenticate",
    clientId: cnf.REACT_APP_AUTH_CLIENT_ID,
    refreshToken: "",
  };
  return axios({
    method: "POST",
    url:
      cnf.REACT_APP_AUTHENTICATION_API_ANONYMOUS +
      "/oauth/token/authenticate-by-auth-code",
    data: body,
  })
    .then((response) => {
      //localStorage.setItem("email", response.data.email);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("userId", response.data.userId);
      let email = encryptUserId(response.data.userId);
      const cookie = new Cookies();
      const regionname = cookie.get("regionname") || "NA";
      const lang = cookie.get("language") || "en";
      sendDataToGTM({
        event: "login",
        job_title:
          localStorage.getItem("enableGA") === "true"
            ? response.data.jobTitle
            : "NA",
        locale: lang,
        user_id: localStorage.getItem("enableGA") === "true" ? email : "NA",
        region: regionname,
      });

      return response.data.access_token;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};

export const getRefreshAccessToken = async (
  refreshToken: string
): Promise<string> => {
  let cnf = await MyConfig();
  const body: AuthorizationCodeRequest = {
    authCode: "",
    redirectUri: "",
    clientId: cnf.REACT_APP_AUTH_CLIENT_ID,
    refreshToken: refreshToken,
  };
  return axios({
    method: "POST",
    url:
      cnf.REACT_APP_AUTHENTICATION_API_ANONYMOUS +
      "/oauth/token/refresh-user-token",
    data: body,
  })
    .then((response: any) => {
      // localStorage.setItem("email", response.data.email);
      localStorage.setItem("userId", response.data.userId);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("token", response.data.access_token);
      return response.data.access_token;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};

type ICallback = () => void;

export const ProcessWithSecure = async (
  secureCallBack: ICallback,
  unSecureCallBack: ICallback,
  token: string | null = ""
) => {
  if (!token) {
    token = localStorage.getItem("token");
  }
  const isMyTokenExpired = isExpired(token!);
  if (isMyTokenExpired) {
    const isMyRefreshTokenExpired = await refreshTokenSliently();
    if (isMyRefreshTokenExpired) {
      unSecureCallBack();
    } else {
      secureCallBack();
    }
  }
  secureCallBack();
};

export const refreshTokenSliently = async () => {
  /// check refresh toke expired or not
  const refreshToken: any = localStorage.getItem("refresh_token");
  const isMyRefreshTokenExpired = isExpired(refreshToken);
  if (!isMyRefreshTokenExpired) {
    // get acess token using refresh token
    const accessToken = await getRefreshAccessToken(refreshToken);
    const isMyNewAccessTokenExpired = isExpired(accessToken);
    return isMyNewAccessTokenExpired;
  }
  return isMyRefreshTokenExpired;
};
