import { List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { CriteriaValue, FilterCriteria } from "../../../../Types";
import { sendDataToGTM } from "../../../../Utils/gtmConfig";

interface Props {
  criteria: FilterCriteria;
  type: string;
  selected: string[];
  toggleFilter: any;
  region: string;
  locale: string;
}

export const ListFilter = ({
  criteria,
  type,
  selected,
  toggleFilter,
  region,
  locale,
}: Props) => {
  const selectFilter = (sele: CriteriaValue, categoryType: string) => {
    toggleFilter(
      categoryType,
      sele.criteriaId,
      sele.label,
      "list",
      categoryType,
      "",
      "",
      false
    );
  };

  return (
    <React.Fragment>
      <div key={criteria.id}>
        {type.toLowerCase() === "advanced" && <h4>{criteria.label}</h4>}
        {criteria.values &&
          criteria.values.map((values: any) => {
            return (
              <List key={values.id}>
                <ListItemButton
                  className={"filter-content-item"}
                  selected={selected?.includes(
                    `${values.id}$bim${values.criteriaId}_${values.label}`
                  )}
                  onClick={() => {
                    selectFilter(values, criteria.type);
                    sendDataToGTM({
                      event: "filters",
                      category_name: criteria.label,
                      criteria_name: values.label,
                      criteria_max_value: "NA",
                      criteria_min_value: "NA",
                      criteria_type: criteria.filterType,
                      region: region,
                      locale: locale,
                    });
                  }}
                >
                  <Typography>{values.label}</Typography>
                  <Typography className="text-right">
                    [{values.nbitems}]
                  </Typography>
                </ListItemButton>
              </List>
            );
          })}
      </div>
    </React.Fragment>
  );
};
