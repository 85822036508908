import { Box, Button, Modal, Typography, Alert } from "@mui/material";
import React, { useContext } from "react";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useRelocate } from "../../relocate";
import { BrowseContext, IBrowseContextType } from "../../Context/browseContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalPopup = () => {
  let { locale, region } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { error } = useContext(BrowseContext) as IBrowseContextType;
  const navigate = useNavigate();

  const { redirect } = useRelocate();
  const redirectOnClick = () => {
    navigate(
      `/${locale}/${region}/browse?${createSearchParams({
        brand: "all",
        sort: "downloads",
        order: "desc",
      })}`
    );
  };

  return (
    <div>
      <Modal
        open={error}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roundedPopup"
      >
        <Box sx={style} className="warningpopup">
          <Typography
            id="modal-modal-description"
            sx={{ mb: 0, textAlign: "center" }}
            style={{ marginBottom: "0" }}
          >
            <Alert variant="outlined" severity="error" className="no-border" id="noErrorAlert">
              No record found for selected search
            </Alert>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => redirectOnClick()}
                className="btn btn-primary btn-small"
                id="okButton"
              >
                Ok
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
