import { MyConfig } from "./myConfig";

export const getLanguages = (function () {
  var allDetailsCache: any = {};
  return async function (locale: string = "en") {
    const d = new Date();
    let hour = d.getHours();
    let detailkey = `_${locale}_${hour}`;
    if (!allDetailsCache[detailkey]) {
      let config = await MyConfig();
      const hostUrl =
        config.REACT_APP_BIMDATABASE_API_URL +
        "/anonymous/objects/class/Language/locale/" +
        locale;
      allDetailsCache[detailkey] = fetch(hostUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          return response.json();
        })
        .catch((err: any) => {
          console.log("errors", err);
        });
    }

    return allDetailsCache[detailkey];
  };
})();
