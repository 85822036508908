import axios from "axios";
import { MyConfig } from "./myConfig";
import { HomePageNews } from "./Types/homePageNews.type";
import { HomePageRichText } from "./Types/homePageRichText";
import { axioGet } from "../lib/axio";

const Configuration = MyConfig();

export const getHomePageRichText = async (
  config: any
): Promise<HomePageRichText | string> => {
  return await axioGet(
    `${config.REACT_APP_API_URL}/anonymous/homepage-rich-text`,
    "homepageritchtext"
  ).catch((err: any) => {
    console.log("error is", err);
    return err;
  });
};

export const getHomePageBannerImageId = async (
  config: any
): Promise<string> => {
  return await axioGet(
    `${config.REACT_APP_API_URL}/anonymous/home-page-banner`,
    "homepagebanner"
  ).catch((err: any) => {
    console.log("error is", err);
    return err;
  });
};

export const getHomePageNews = async (config: any): Promise<HomePageNews> => {
  return await axioGet(
    `${config.REACT_APP_API_URL}/anonymous/home-page-news`,
    "homepagenews"
  ).catch((err: any) => {
    console.log("error is", err);
    return err;
  });
};

export const getImageUrl = async (
  imageId: string,
  size: "thumbnail" | "default" | "small" | "default" | "big" | "hq",
  config: any
): Promise<string> => {
  return `${config.REACT_APP_API_URL}/anonymous/image/${size}/${imageId}`;
};
