import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import { CookiesSetting } from "./cookiesSetting";
import Cookies from "universal-cookie";
import { useRelocate } from "../../relocate";
import { forEach } from "lodash";
import { DenyLocationPopup } from "./denyLocationPopup";
import { MarketRegionPopup } from "./marketRegionPopup";
import { initializeGTM } from "../../Utils/gtmConfig";
import CookiesToggleLanguage from "../CookieSetting/CookieToggleLanguage";
import { t } from "../../Utils/helper";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";
import { ListRegionByCounter, sortRegions } from "../../API";

export interface ICookieSetting {
  allowCookie: boolean;
}
export interface IRegion {
  label: string;
  flagImage: string;
}

export interface State extends SnackbarOrigin {
  open: boolean;
}

export const CookiePreference = () => {
  const [openCookiePreference, setopenCookiePreference] =
    useState<boolean>(false);
  const { relocate } = useRelocate();
  const [openRegion, setOpenRegionPopup] = useState<boolean>(false);
  const [cookieLoading, setLoading] = useState<boolean>(false);
  const [openDenialPopup, setOpenDenialPopup] = useState(false);
  const [locale, setLocale] = useState<string>("en");
  /** STARTS: To open Cookie setting modal popup  **/
  const [openCookie, setOpenCookie] = useState<boolean>(false);
  const [customLanguage, setLanguage] = useState<string>("en");
  const cookies = new Cookies();
  const { regions } = React.useContext(CultureContext) as ICultureContextType;

  const regionname = cookies.get("regionname") || "France";

  const handleOpenCookiesSetting = () => {
    setOpenCookie(true);
    setopenCookiePreference(false);
  };

  /** ENDS: To open Cookie setting modal popup  **/
  useEffect(() => {
    async function getData() {
      if (
        !window.location.href.includes("cookie-policy") &&
        !window.location.href.includes("embed") &&
        !window.location.href.includes("code=")
      ) {
        /// 1. check cookieseting available in localstorage
        let foundCookies: any = JSON.parse(
          localStorage.getItem("cookieSetting") || "{}"
        );

        if (!foundCookies.hasOwnProperty("allowCookie")) {
          setopenCookiePreference(true);
        }
      }
    }
    getData();
  }, [locale]);

  const handleCloseRegionPopup = (toLocation: string) => {
    setOpenRegionPopup(false);
    relocate(toLocation);
  };

  const settingCookie = (setCookie: boolean) => {
    localStorage.setItem(
      "cookieSetting",
      JSON.stringify({ allowCookie: setCookie })
    );
    if (setCookie) {
      localStorage.setItem("enableGA", JSON.stringify(true));
      initializeGTM();
    } else {
      localStorage.setItem("enableGA", JSON.stringify(false));
      let allCookies = cookies.getAll();
      let keys = Object.keys(allCookies);
      let date = new Date();
      date.setDate(date.getDate() - 1);
      forEach(keys, (key) => {
        if (key.startsWith("_ga")) {
          cookies.remove(key, { path: "/", expires: date });
        }
      });
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
    setopenCookiePreference(false);
  };

  // when user clicks on OK
  const onSuccess = async (position: any) => {
    setLoading(true);
    // find user's latitude and longitude
    let { latitude, longitude } = position.coords;
    const country = await fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=0f619f68951947f8ba0fcb9a1b929c71`
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        let allDetails = result.results[0].components;
        let { country } = allDetails;
        return country;
      });

    const regionByCounter = await ListRegionByCounter();
    const regionList = sortRegions(regionByCounter, locale);

    const regionAvailable = regionList.find(
      (reg: any) => reg.label.toLowerCase() === country.toLowerCase()
    );
    if (regionAvailable) {
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 100);
      cookies.set("regionname", regionAvailable.label, {
        path: "/",
        expires: date,
      });
      relocate("/");
    } else {
      setOpenRegionPopup(true);
    }
    setLoading(false);
  };

  // when user clicks on Cancel
  const onError = (error: any) => {
    setOpenDenialPopup(!openDenialPopup);
  };

  const toggleDenyLocation = (status: boolean) => {
    setOpenDenialPopup(status);
    handleOpenRegionPopup();
  };

  const handleOpenRegionPopup = () => {
    setOpenRegionPopup(true);
  };

  if (cookieLoading) {
    return (
      <div
        className="btn-load dsds"
        id="cookieLoad"
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "0",
          left: "0",
          zIndex: "9999",
          background: "rgba(197, 197, 197, 0.64)",
        }}
      >
        <CircularProgress style={{ margin: "0px auto" }} />
      </div>
    );
  }

  return (
    <>
      <Dialog
        open={openCookiePreference}
        aria-labelledby="Cokkie-preferences-dialog-title"
        aria-describedby="Cokkie-preferences-dialog-description"
      >
        <DialogTitle id="Cokkie-preferences-dialog-title">
          {"Set your cookie preferences"}
          <CookiesToggleLanguage
            setLanguage={(value: string) => setLanguage(value)}
            language={customLanguage}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="Cokkie-preferences-dialog-description">
            <Typography
              variant="caption"
              gutterBottom
              display="block"
              style={{ fontSize: "0.875rem" }}
            >
              {t("cookie_Popup.cp_description1", customLanguage)}
            </Typography>

            <Typography
              variant="caption"
              gutterBottom
              display="block"
              style={{ fontSize: "0.875rem" }}
            >
              {t("cookie_Popup.cp_description2", customLanguage)}
            </Typography>

            <Typography
              variant="caption"
              gutterBottom
              display="block"
              style={{ fontSize: "0.875rem" }}
            >
              {t("cookie_Popup.cp_description3", customLanguage)}{" "}
              <Link
                // href={`/${locale}/${regionname}/cookie-policy`} // For testing purpose
                href={`/${customLanguage}/${regionname}/cookie-policy`}
                className="color-secondary"
                target="_blank"
              >
                {t("cookie_Popup.link", customLanguage)}
              </Link>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              settingCookie(true);
            }}
            className="btn btn-primary btn-popup"
            id="cookieAllowBtn"
            variant="contained"
          >
            {t("cookie_Popup.allow_btn", customLanguage)}
          </Button>
          <Button
            onClick={() => settingCookie(false)}
            className="btn btn-primary btn-popup"
            id="cookieRejectBtn"
            variant="contained"
          >
            {t("cookie_Popup.reject_btn", customLanguage)}
          </Button>
          <Button
            onClick={handleOpenCookiesSetting}
            className="btn btn-primary btn-popup bg-secondary"
            id="cookieSettBtn"
            variant="contained"
          >
            {t("cookie_Popup.cookie_settings_btn", customLanguage)}
          </Button>
        </DialogActions>
      </Dialog>

      <CookiesSetting
        handleCloseCookiesSetting={setOpenCookie}
        openCookie={openCookie}
      />

      <DenyLocationPopup
        openDenialPopup={openDenialPopup}
        handleCloseDenyLocationPopup={toggleDenyLocation}
      />

      {openRegion && (
        <MarketRegionPopup
          openRegionPopup={openRegion}
          closeRegionPopup={handleCloseRegionPopup}
        />
      )}
    </>
  );
};
