import { Grid, Skeleton, Typography } from "@mui/material";
import { Layout } from "../../Pages/layout";

export const ProductSkeleton = () => {
  return (
    <Typography
      component="div"
      className="product-skeleton main-container product-container"
    >
      <Typography component="div" className="left-button-skeleton">
        <Skeleton
          width="91px"
          height="60px"
          sx={{ mb: "10px", display: "inline-block", mr: "10px" }}
        />
        <Skeleton
          width="209px"
          height="60px"
          sx={{ mb: "10px", display: "inline-block" }}
        />
      </Typography>
      <Grid container spacing={3}>
        <Grid item lg={3} md={5} sm={12} xs={12}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="330px"
            sx={{ mb: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block", ml: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block", ml: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            width="22%"
            height="60px"
            sx={{ mb: "10px", display: "inline-block", ml: "10px" }}
          />
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Skeleton variant="text" width="100%" sx={{ m: "0px" }} />
          <Skeleton variant="text" width="35%" sx={{ m: "0px" }} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="50px"
            sx={{ mb: "0px", mt: "20px" }}
          />
          <Skeleton variant="text" width="100%" sx={{ mt: "10px" }} />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="35%" />
        </Grid>
        <Grid item lg={2} md={5} sm={12} xs={12}>
          <Skeleton
            variant="rectangular"
            width="150px"
            height="70px"
            sx={{ m: "0 auto" }}
          />
          <Skeleton
            variant="text"
            width="115px"
            height="60px"
            sx={{ m: "0 auto" }}
          />
          <Skeleton
            variant="text"
            width="140px"
            height="60px"
            sx={{ m: "0 auto" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="135px"
            sx={{ m: 0 }}
          />
        </Grid>
      </Grid>
    </Typography>
  );
};
