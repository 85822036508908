import { List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { createSearchParams } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import { useRelocate } from "../../relocate";

interface Props {
  brands: any;
  selectedBrand: string;
}

export const ListBrand = ({ selectedBrand, brands }: Props) => {
  const { relocate } = useRelocate();
  brands && brands.sort((a: any, b: any) => a.label.localeCompare(b.label)); // sorting alphabetically

  return (
    <React.Fragment>
      <div>
        <SimpleBarReact style={{ maxHeight: "83vh" }}>
          {brands?.map((values: any, index: number) => {
            return (
              <List key={index}>
                <ListItemButton
                  selected={selectedBrand === values.label}
                  onClick={() => {
                    relocate({
                      pathname: "/browse",
                      search: `?${createSearchParams({
                        brand: values.label,
                        sort: "downloads",
                        order: "desc",
                      })}`,
                    });
                  }}
                >
                  <Typography>{values.label}</Typography>
                  <Typography className="text-right"></Typography>
                </ListItemButton>
              </List>
            );
          })}
        </SimpleBarReact>
      </div>
    </React.Fragment>
  );
};
