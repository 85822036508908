import axios from "axios";

export const axioGet = (function () {
  var axioGetCache: any = {};
  return async function (url: string, key: string) {
    if (!axioGetCache[key]) {
      axioGetCache[key] = await axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .catch((err: string) => {
          throw err;
        });
    }
    return axioGetCache[key];
  };
})();

export const axioPost = (function () {
  var fetchPostCache: any = {};
  return async function (url: string, body: string, key: string) {
    if (!fetchPostCache[key]) {
      fetchPostCache[key] = await axios({
        method: "POST",
        url: url,
        data: body,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err: any) => {
          throw err;
        });
    }
    return fetchPostCache[key];
  };
})();
