import * as React from "react";
import { createContext, useState, useEffect } from "react";
import { ListRegionByCounter, searchAllProducts, sortRegions } from "../API";
import { getLanguages } from "../API/language.api";
import { MyConfig } from "../API/myConfig";

interface IRegionType {
  label: string;
  oid: string;
  productCount: number;
  translation: string;
}

interface ILanguageType {
  className: string;
  longName: string;
  oid: string;
  technicalName: string;
  thumbnailUrl: string;
  translation: string;
}

export interface ICultureContextType {
  regions: IRegionType[] | null;
  languages: ILanguageType[] | null;
  allProducts: any;
  config: any;
  cultureLoading: boolean;
  error: string | null;
  loadRegions: (locale: string) => void;
  loadLanguages: (locale: string) => void;
  setParams: any;
}

export const CultureContext = createContext<ICultureContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const CultureProvider: React.FC<Props> = ({ children }) => {
  const [regions, setRegions] = useState<IRegionType[] | null>(null);
  const [languages, setLanguages] = useState<ILanguageType[] | null>(null);
  const [config, setConfig] = useState<any>(null);
  const [allProducts, setAllProducts] = useState<any>(null);
  const [error] = useState<string | null>(null);
  const [params, setParams] = useState<{
    region: string;
    locale: string;
  } | null>(null);

  useEffect(() => {
    async function loadCulture(
      region: string | undefined,
      locale: string | undefined
    ) {
      if (region && locale) {
        setRegions(null);
        setLanguages(null);
        setAllProducts(null);
        setConfig(null);

        const config = await MyConfig();
        const regionByCounter = await ListRegionByCounter();
        const regionList = sortRegions(regionByCounter, locale);
        const languagesResponse = await getLanguages(locale);
        const sortedLanguage = sortLanguage(languagesResponse.objects);

        if (sortedLanguage && regionList) {
          const allProducts = await searchAllProducts(
            region,
            locale,
            regionList,
            config
          );
          setConfig(config);
          setAllProducts(allProducts);
          setRegions(regionList);
          setLanguages(languagesResponse.objects);
        }
      }
    }

    if (params) {
      loadCulture(params.region, params.locale);
    }
  }, [params]);

  const sortLanguage = (languages: any) => {
    if (languages) {
      return languages.sort((a: { longName: string }, b: { longName: any }) =>
        a.longName.localeCompare(b.longName)
      );
    }
    return null;
  };

  const loadLanguages = async (locale: string) => {
    setLanguages(null);
    const languagesResponse = await getLanguages(locale);
    const sortedLanguage = sortLanguage(languagesResponse.objects);
    if (sortedLanguage) {
      setLanguages(languagesResponse.objects);
    }
  };

  const loadRegions = async (locale: string) => {
    setRegions(null);
    const regionByCounter = await ListRegionByCounter();
    const regionList = sortRegions(regionByCounter, locale);
    if (regionList) {
      setRegions(regionList);
    }
  };

  return (
    <CultureContext.Provider
      value={{
        regions,
        languages,
        allProducts,
        config,
        cultureLoading:
          !regions && !allProducts && !languages && !config && !error,
        error,
        loadRegions,
        loadLanguages,
        setParams,
      }}
    >
      {children}
    </CultureContext.Provider>
  );
};

export default CultureProvider;
