import { List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { FilterCriteria, CriteriaValue } from "../../Types";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import { IToggleRangeFilter, IToggleListFilter } from "./util/filterType";

interface Props {
  criteria: FilterCriteria;
  type: string;
  selected: string;
  toggleRangeFilter: (props: IToggleRangeFilter) => void;
  toggleListFilter: (props: IToggleListFilter) => void;
  region: string;
  locale: string;
}

export const ListFilter = ({
  criteria,
  type,
  selected,
  toggleListFilter,
  region,
  locale,
}: Props) => {
  const selectFilter = (sele: CriteriaValue) => { 
    if (type === "ADVANCED") {
       toggleListFilter({
        filterType: criteria.filterType,
        criteriaId: sele.criteriaId,
        tagLabel: sele.label,
         criteriaType: criteria.type,
         id: criteria.id,
        type:type,
      });
    } else {
       toggleListFilter({
      filterType: criteria.filterType,
      criteriaId: sele.criteriaId,
      tagLabel: sele.label,
      criteriaType: criteria.type,
    });
    }
  };

  return (
    <React.Fragment>
      <div key={criteria.id}>
        {type.toLowerCase() === "advanced" && <h4>{criteria.label}</h4>}
        {criteria.values &&
          criteria.values.map((values: any) => {
            return (
              <List key={values.id}>
                <ListItemButton
                  className={"filter-content-item"}
                  id={`filterList- ${values.id}`}
                  selected={values.criteriaId.length>1 ?selected.includes(values.criteriaId):selected.includes(values.label)}
                  onClick={() => {
                    selectFilter(values);
                    sendDataToGTM({
                      event: "filters",
                      category_name: criteria.label,
                      criteria_name: values.label,
                      criteria_max_value: "NA",
                      criteria_min_value: "NA",
                      criteria_type: criteria.filterType,
                      region: region,
                      locale: locale,
                    });
                  }}
                >
                  <Typography>{values.label}</Typography>
                  <Typography className="text-right">
                    [{values.nbitems}]
                  </Typography>
                </ListItemButton>
              </List>
            );
          })}
      </div>
    </React.Fragment>
  );
};
