import { List, Pagination, Skeleton, Typography } from "@mui/material";
import React, { useContext } from "react";
import "../../Utils/assets/products.scss";
import { SelectChangeEvent } from "@mui/material/Select";
import { ceil, chunk } from "lodash";
import { SortBar } from "./sortBar";
import ProductCard from "../Product/productCard";
import { ProductCardSkeleton } from "./productCardSkeleton";
import { useSearchParams } from "react-router-dom";
import { BrowseContext, IBrowseContextType } from "../../Context/browseContext";

export const Products = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedBrand: string = searchParams.get("brand") || "all";
  const pageNumber: string = searchParams.get("page") || "1";

  const { loading, products } = useContext(BrowseContext) as IBrowseContextType;

  // for product list section, this skeleton will be shown while on page load
  const prdtNo = 12;
  const productCardSkeleton = [...Array(prdtNo)].map(
    (i: any, index: number) => {
      return <ProductCardSkeleton skey={index} key={index} />;
    }
  );

  if (loading) {
    return (
      <>
        <Typography component="div" className="browse-skeleton">
          <Skeleton
            width="15%"
            sx={{ mb: "20px", display: "inline-block", mt: "14px" }}
          />
          <Skeleton
            width="15%"
            sx={{
              mb: "20px",
              display: "inline-block",
              float: "right",
              mt: "14px",
            }}
          />

          <Typography component="div" className="product-card-skeleton">
            <List className={"list-products"}>{productCardSkeleton}</List>
          </Typography>
        </Typography>
      </>
    );
  }

  const changePage = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  if (products) {
    if (products.productList.length > 0) {
      return (
        <React.Fragment>
          <SortBar productCount={products.count} />
          <List className={"list-products"}>
            {products.productList.map((data: any) => {
              return (
                <ProductCard
                  product={data}
                  key={data.productId}
                  brands={products.brandList}
                  selectedBrand={selectedBrand}
                />
              );
            })}
          </List>
          <Pagination
            count={products.totalPages}
            size="large"
            page={parseInt(pageNumber)}
            onChange={(e, page) => changePage(page)}
            className={"browse-pagination"}
            id={`productPagination- ${parseInt(pageNumber)}`}
          />
        </React.Fragment>
      );
    }
  }

  return (
    <div style={{ margin: "0px auto", textAlign: "center" }}>
      No products for selected criteria
    </div>
  );
};
