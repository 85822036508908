import React from "react";
import "../../Utils/assets/byBrands.scss";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { createSearchParams, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRelocate } from "../../relocate";
import { searchProduct } from "../../API";
import { BrandInfo } from "../../Types";
import { BrandList } from "../Common/brandList";
import SkeletonComponent from "../Common/SkeletonComponent";
import {
  ICultureContextType,
  CultureContext,
} from "../../Context/cultureContext";
import { Link } from "react-router-dom";

export interface Brand {
  Label: string;
  FlagImage: string;
  Oid: string;
}

export const ByBrands = () => {
  const { locale, region } = useParams();
  const { relocate } = useRelocate();
  const { t } = useTranslation("common");

  const { allProducts, cultureLoading, error } = React.useContext(
    CultureContext
  ) as ICultureContextType;

  // Loop through 12 times
  const n = 12;
  if (cultureLoading && !error) {
    const barndsSkeleton = [...Array(n)].map((e, i) => (
      <SkeletonComponent width="16.66%" height="90px" key={i} />
    ));
    return <BrandBar t={t}>{barndsSkeleton}</BrandBar>;
  }

  if (allProducts) {
    const brandList = allProducts.filter(
      (allp: any) => allp.code.toLowerCase() === "brand"
    );
    if (brandList) {
      return (
        <BrandBar t={t}>
          <BrandListBoard
            brands={brandList}
            locale={locale}
            region={region}
            relocate={relocate}
          />
        </BrandBar>
      );
    }
  }

  return <BrandBar t={t}>No Brands</BrandBar>;
};

const BrandBar = ({ children, t }: any) => {
  return (
    <React.Fragment>
      <section className="choose-product-brand">
        <Typography component="h2" className="section-heading">
          {t("home_page.home_page_headings.by_brands")}
        </Typography>
        <Typography component="div" className="chip-container">
          <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Typography>
      </section>
    </React.Fragment>
  );
};

interface IBrandListBoard {
  brands: BrandInfo[];
  relocate: any;
  region: string | undefined;
  locale: string | undefined;
}

const BrandListBoard = ({
  brands,
  relocate,
  region = "France",
  locale = "en",
}: IBrandListBoard) => {
  return (
    <List>
      {brands.slice(0, 11).map((filteredBrand: any, key: number) => {
        return (
          <BrandList
            {...filteredBrand}
            brandkey={`${filteredBrand.label}-${key}`}
            key={`${filteredBrand.label}-${key}`}
            locale={locale}
          />
        );
      })}

      {brands.length > 11 && (
        <ListItem disablePadding>
          <ListItemButton className="chip-brand">
            <ListItemText>
              <Link
                to={`/${locale}/${region}/browse?brand=all&sort=downloads&order=desc`}
                className="more-btn"
                id="moreBtn"
              >
                More...
              </Link>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
};
