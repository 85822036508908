import { useEffect, useState } from "react";
import * as DOMPurify from "dompurify";

DOMPurify.addHook("uponSanitizeElement", (node: any, data: any) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    if (!src.startsWith("https://www.youtube.com/embed/")) {
      return node.parentNode?.removeChild(node);
    }
    node.setAttribute("sandbox", "allow-scripts allow-same-origin");
    node.setAttribute("allowfullscreen", "true");
  }
});

// Utility to handle special characters
const preprocessHtmlContent = (htmlContent: string): string => {
  return htmlContent
    .replace(/\n\n/g, "<br><br>") // Replace \n\n with <br><br>
    .replace(/\n/g, "<br>") // Replace \n with <br>
    // .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"); // Replace \t with four spaces (&nbsp;)
};

export function useSanitizedHtml(htmlContent: string): string {
  const [html, setHtml] = useState("");

  useEffect(() => {
    const preprocessedContent = preprocessHtmlContent(htmlContent); // Preprocess content
    setHtml(getSanitizedHtmlString(preprocessedContent)); // Sanitize processed content
  }, [htmlContent]);

  return html;
}

// Sanitize HTML using DOMPurify
export const getSanitizedHtmlString = (htmlContent: string): string => {
  return DOMPurify.sanitize(htmlContent, {
    ADD_TAGS: ["iframe", "br"], // Allow <br> tags
    ADD_ATTR: ["target", "sandbox", "allowfullscreen"],
  });
};
