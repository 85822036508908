import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { map } from "lodash";
import "../../Utils/assets/filterElement.scss";
import "../../Utils/assets/rangeFilter.scss";
import { ListFilter } from "./filterList";
import { FilterCategory, FilterCriteria } from "../../Types";
import { RangeFilter } from "./filterRange";
import { IToggleRangeFilter, IToggleListFilter } from "./util/filterType";

export const FilterCategoryEle = (props: {
  category: FilterCategory;
  selectedCriteria: string;
  toggleRangeFilter: (props: IToggleRangeFilter) => void;
  toggleListFilter: (props: IToggleListFilter) => void;
  selectedFormat: string[];
  region: string;
  locale: string;
  handleChange:any;
  setExpanded: any;
  expanded: any;
}) => {
  

  return (
    <Accordion
      variant="outlined"
      className={`filter-element ${
        props.category.principal === true ? "global-filter" : ""
      }`}
      key={props.category.id}
      expanded={props.expanded === `${props.category.id}`} onChange={props.handleChange(`${props.category.id}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        {/* <ListIcon /> */}
        <Typography id={`categoryLabel-${props.category.id}`}>
          {props.category.label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="filter-content">
        {map(props.category.criterias, (criteria: FilterCriteria) => {
          return (
            <FilterCriteriaEle
              category_name={props.category.label}
              criteria={criteria}
              key={criteria.id}
              selected={props.selectedCriteria}
              toggleRangeFilter={props.toggleRangeFilter}
              toggleListFilter={props.toggleListFilter}
              type={props.category.type}
              region={props.region}
              locale={props.locale}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const FilterCriteriaEle = (props: {
  category_name: string;
  criteria: FilterCriteria;
  selected: string;
  type: string;
  region: string;
  locale: string;
  toggleRangeFilter: (props: IToggleRangeFilter) => void;
  toggleListFilter: (props: IToggleListFilter) => void;
}) => {
  switch (props.criteria.filterType) {
    case "RANGE":
      return <RangeFilter {...props} />;
    case "LIST":
      return <ListFilter {...props} />;
    default:
      return <ListFilter {...props} />;
  }
};
