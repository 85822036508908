import React, { useEffect } from "react";
import { Banner } from "../Components/Home/banner";
import { ByApplication } from "../Components/Home/byApplication";
import { ByBrands } from "../Components/Home/byBrands";
import { LearnAboutBim } from "../Components/Home/homeLearnAboutBim";
import { HomeProduct } from "../Components/Home/homeProduct";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CanonicalStaticTag } from "../Components/Header/canonicaltags";

export const HomePage = () => {
  const { locale } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);
  return (
    <>
      <CanonicalStaticTag page="" />
      <main className="main-home-container">
        <Banner />
        {/* pipeline-poc */}
        {/* <div> test for debugging</div> */}
        <ByApplication />
        <ByBrands />
        <HomeProduct />
        <LearnAboutBim />
      </main>
    </>
  );
};
