import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ProductCard } from "../Product/productCard";
import "../../Utils/assets/homeProduct.scss";
import {
  List,
  ListItem,
  Typography,
  Skeleton,
  Grid,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createSearchParams, useParams, Link } from "react-router-dom";
import { useRelocate } from "../../relocate";
import { ProductCardSkeleton } from "../Browse/productCardSkeleton";
import {
  ICultureContextType,
  CultureContext,
} from "../../Context/cultureContext";

export const HomeProduct = () => {
  const { locale, region } = useParams();
  const { relocate } = useRelocate();
  const { t } = useTranslation("common");

  const { allProducts, cultureLoading, error } = React.useContext(
    CultureContext
  ) as ICultureContextType;

  if (allProducts && !cultureLoading) {
    const productList = allProducts.filter(
      (allp: any) => allp.code.toLowerCase() === "product"
    );

    const brandList = allProducts.filter(
      (allp: any) => allp.code.toLowerCase() === "brand"
    );

    return (
      <ProductListLayout>
        <List className={"homepage-product-grid"}>
          {productList.slice(0, 6).map((pr: any) => (
            <ProductCard product={pr} key={pr.oid} brands={brandList} />
          ))}

          {productList.length > 6 && (
            <ListItem className={"flex-vertical-center"}>
              <Link
                to={`/${locale}/${region}/browse?${createSearchParams({
                  brand: "all",
                  sort: "downloads",
                  order: "desc",
                })}`}
              >
                <Button
                  className="btn btn-default btn-normal btn-more-product"
                  sx={{
                    borderRadius: "6px !important",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="text-center"
                    style={{ fontSize: "14px" }}
                    id="browseMoreProducts"
                  >
                    {t(
                      "home_page.home_page_headings.by_products.browse_productbtn"
                    )}
                  </span>{" "}
                  <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} />
                </Button>
              </Link>
            </ListItem>
          )}
        </List>
      </ProductListLayout>
    );
  }

  // product skeleton will be shown while home page is loading
  const n = 6;
  const productsSkeleton = [...Array(n)].map((e, i) => (
    <ProductCardSkeleton skey={i} key={i} />
  ));

  return (
    <ProductListLayout>
      <List className={"homepage-product-grid"}>
        {productsSkeleton}
        <ListItem
          className="product-card-spacing"
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <Skeleton width="60%" height="80px" sx={{ m: "10px auto 10px" }} />
        </ListItem>
      </List>
    </ProductListLayout>
  );
};

const ProductListLayout = ({ children }: any) => {
  const { t } = useTranslation("common");
  return (
    <React.Fragment>
      <section className="homepage-browse-products">
        <Typography component="h2" className="section-heading">
          {t("home_page.home_page_headings.by_products.title")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            {children}
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
};
