import React, { useEffect, useState, useContext } from "react";
import { Grid, Button, Box, Typography, Skeleton } from "@mui/material";
import "../../Utils/assets/banner.scss";
import { MyConfig } from "../../API/myConfig";
import {
  getHomePageBannerImageId,
  getHomePageRichText,
  getImageUrl,
} from "../../API/homepage.api";
import draftToHtml from "draftjs-to-html";
import { useTranslation } from "react-i18next";
import { sendDataToGTM } from "../../Utils/gtmConfig";
import { v4 as uuidv4 } from "uuid";
import { useParams, Link } from "react-router-dom";
import { useSanitizedHtml } from "../../Utils/hooks/useSanitizedHtml";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

export const Banner = () => {
  const { locale, region } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [bannerHeader, setBannerHeader] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState<string>("");
  const sanitizedBannerHeader: string = useSanitizedHtml(bannerHeader);
  const { config } = useContext(CultureContext) as ICultureContextType;

  const { t } = useTranslation("common");

  const onCreateAccountClick = async () => {
    let uid = uuidv4();
    window.open(
      config.REACT_APP_AUTHENTICATIONURL +
        `/createNewAccount?project=BIMLIB&guid=${uid}`,
      "_blank"
    );

    sendDataToGTM({
      event: "create_account_btnClick",
      guid: uid,
    });
  };

  useEffect(() => {
    setLoading(true);
    if (config) {
      getHomePageRichText(config).then((dataText: any) => {
        getHomePageBannerImageId(config).then((data: string) => {
          getImageUrl(data, "big", config).then((imageUrl: string) => {
            setBannerImageUrl(imageUrl);
            setBannerHeader(draftToHtml(dataText.richTextContent));
            setLoading(false);
          });
        });
      });
    }
  }, [config]);

  const addDefaultSrc = (ev: any) => {
    ev.target.src = "../assets/imgs/banner-placeholder.jpg";
  };

  if (loading) {
    return (
      <>
        <Skeleton className="skeleton-banner-background" />
        <Skeleton className="skeleton-banner-image" />
      </>
    );
  }

  if (sanitizedBannerHeader) {
    return (
      <React.Fragment>
        <Box component="section" className="discover-saint-gobain">
          <Grid container spacing={2} className="banner-section">
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              className="discover-text"
              style={{ paddingTop: "0" }}
            >
              <div
                style={{ minHeight: "285px" }}
                dangerouslySetInnerHTML={{ __html: sanitizedBannerHeader }}
              />
              <Button
                onClick={() => onCreateAccountClick()}
                id="bannerAcc"
                variant="contained"
                className="btn btn-big m-t-20 btn-create-account m-r-40"
              >
                {t("home_page.home_banner_Page.create_account_btn")}
              </Button>
              <Link
                to={`/${locale}/${region}/browse?brand=all&sort=downloads&order=desc`}
                className="btn btn-big m-t-20 btn-create-account"
                id="browseBtn"
              >
                {t("home_page.home_banner_Page.browse_btn")}
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className="banner-img"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="div"
                className="img-wrapper"
              >
                <img
                  src={bannerImageUrl}
                  className="img-responsive"
                  alt="bim-object"
                  loading="lazy"
                  onError={addDefaultSrc}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box component="section" className="discover-saint-gobain">
        <Grid container spacing={2} className="banner-section">
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className="discover-text"
            style={{ paddingTop: "0" }}
          >
            <div
              style={{ minHeight: "285px" }}
              dangerouslySetInnerHTML={{ __html: sanitizedBannerHeader }}
            />
            <Button
              id="create_account_btn"
              onClick={() => onCreateAccountClick()}
              variant="contained"
              className="btn btn-big m-t-20 btn-create-account m-r-40"
            >
              {t("home_page.home_banner_Page.create_account_btn")}
            </Button>
            <Link
              to={`/${locale}/${region}/browse/?brand=all&sort=downloads&order=desc`}
            >
              {t("home_page.home_banner_Page.browse_btn")}
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className="banner-img"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1" component="div" className="img-wrapper">
              <img
                src={bannerImageUrl}
                className="img-responsive"
                alt=""
                loading="lazy"
                onError={addDefaultSrc}
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
