import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  languageStaticObject,
  regionStaticObject,
  regionStaticObjectHavingProduct,
} from "../../Utils/helper";

//// use only for seo, link to production database
export const CanonicalStaticTag = (props: { page: string }) => {
  const { page } = props;

  const { locale, region } = useParams();
  return (
    <Helmet>
      <link
        rel="canonical"
        href={`https://bimlibrary.saint-gobain.com/${locale}/${region}${page}`}
      />

      {region &&
        languageStaticObject.map((langelement: string) => {
          const regiondetail = regionStaticObject.find((regStat: any) => {
            return regStat.longName.toLowerCase() === region.toLowerCase();
          });
          if (regiondetail) {
            return (
              <link
                key={langelement}
                rel="alternate"
                hrefLang={`${langelement}`}
                href={`https://bimlibrary.saint-gobain.com/${langelement}/${regiondetail.longName}${page}`.toLowerCase()}
              />
            );
          }
        })}
    </Helmet>
  );
};

export const CanonicalStaticTagWthoutRegion = (props: { page: string }) => {
  const { page } = props;

  const { locale } = useParams();
  return (
    <Helmet>
      <link
        rel="canonical"
        href={`https://bimlibrary.saint-gobain.com/${locale}${page}`}
      />

      {languageStaticObject.map((langelement: string) => {
        return (
          <link
            rel="alternate"
            hrefLang={`${langelement}`}
            href={`https://bimlibrary.saint-gobain.com/${langelement}${page}`.toLowerCase()}
          />
        );
      })}
    </Helmet>
  );
};
