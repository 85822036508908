import React, { useEffect, useState } from "react";
import "../../Utils/assets/cartButton.scss";
import { Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTranslation } from "react-i18next";
import { BimlibContext, BimlibContextType } from "../../Context/context";
import { useRelocate } from "../../relocate";
import { useParams } from "react-router-dom";
import { filter, map } from "lodash";
import { getAllProductDetails } from "../../API";

export const CartButton = () => {
  const { locale, region } = useParams();
  const { count } = React.useContext(
    BimlibContext
  ) as BimlibContextType;
  const { redirect } = useRelocate();
  const { t } = useTranslation("common");

  if (window.location.href.includes("cookie-policy")) {
    return <></>;
  }
  return (
    <div className="mydownloads-container">
      <span
        onClick={() => {
          redirect(`/${locale}/${region}/downloads`);
        }}
        className="text-uppercase m-b-0"
        // id="browseMyDwnlds"
        style={{ cursor: "pointer" }}
      >
        <ShoppingCartIcon />
        <Typography
          component="span"
          className="my-download-text"
          id="cartDownload"
        >
          {t("header.downloads")}
        </Typography>
        <Typography component="span" className="my-download-count">
          ({filter(count.products, { region: region }).length || 0})
        </Typography>
      </span>
    </div>
  );
};
