import { MyConfig } from "./myConfig";
import { GetRegionOid } from "./region.api";

export const ListBrands = (function () {
  var brandCache: any = {};
  return async function (regionId: string = "18", locale: string = "en") {
    // hour added hear for refresh brands data after 1 hour of cache
    const d = new Date();
    let hour = d.getHours();
    const brandKey: string = `${regionId}_${locale}_${hour}`;
    if (!brandCache[brandKey]) {
      let config = await MyConfig();
      const hostUrl = `${config.REACT_APP_API_URL}/anonymous/regions/${regionId}/brands?languageId=${locale}&sortBy=DOWNLOADS&sortDirection=DESC`;

      brandCache[brandKey] = fetch(hostUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          return res.brands;
        })
        .catch((err: any) => {
          return null;
        });
    }
    return brandCache[brandKey];
  };
})();


export const ListAllBimLibBrands = (function () {
  var brandCache: any = {};
  return async function (regionId: string = "18", locale: string = "en") {
    // hour added hear for refresh brands data after 1 hour of cache
    const d = new Date();
    let hour = d.getHours();
    const brandKey: string = `${regionId}_${locale}_${hour}`;
    if (!brandCache[brandKey]) {
      let config = await MyConfig();
      const hostUrl = `${config.REACT_APP_API_URL}/anonymous/brands?languageId=${locale}&sortBy=DOWNLOADS&sortDirection=DESC`;

      brandCache[brandKey] = fetch(hostUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          return res.brands;
        })
        .catch((err: any) => {
          return null;
        });
    }
    return brandCache[brandKey];
  };
})();

export const ListAllBrands = (function () {
  var allBrandCache: any = {};
  return async function (locale: string = "en") {
    // hour added hear for refresh brands data after 1 hour of cache
    const d = new Date();
    let hour = d.getHours();
    const brandKey: string = `${locale}_${hour}`;
    if (!allBrandCache[brandKey]) {
      let config = await MyConfig();
      const hostUrl = `${config.REACT_APP_BIMDATABASE_API_URL}/anonymous/objects/class/Brand/locale/${locale}?excludeContextAttributes=true&excludeContextObjects=true`;

      allBrandCache[brandKey] = fetch(hostUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({})
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          return res.objects;
        })
        .catch((err: any) => {
          return null;
        });
    }
    return allBrandCache[brandKey];
  };
})();
