import * as React from "react";
import { createContext, useState, useEffect, useMemo, useContext } from "react";
import {
  GetRegionOid,
  ListBrands,
  ListRegionByCounter,
  searchAllProducts,
} from "../API";
import { getLanguages } from "../API/language.api";
import { MyConfig } from "../API/myConfig";
import { useWhyDidYouUpdate } from "../Utils/hooks/useWhyDidYouUpdate";
import { map, find, concat, forEach } from "lodash";
import { axioPost } from "../lib/axio";
import { useParams, useSearchParams } from "react-router-dom";
import { CultureContext, ICultureContextType } from "./cultureContext";
import { getProductDetails, updateProductViewCount } from "../API/Products.api";
import { useImmer } from "use-immer";

export interface IBrowseContextType {
  products: any;
  loading: boolean;
  error: boolean;
  criteriaList: { [key: string]: { principal: boolean } };
}

interface IBrowseData {
  searchParams: URLSearchParams;
  regions: any;
  region: string | undefined;
  locale: string | undefined;
}

export const BrowseContext = createContext<IBrowseContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const BrowseProvider: React.FC<Props> = ({ children }) => {
  const [products, setProducts] = useState<any>(null);
  const [criteriaList, updateCriteriaList] = useImmer<{
    [key: string]: { principal: boolean };
  }>({});
  const [error, setError] = useState<boolean>(false);
  const { locale = "en", region = "France", name } = useParams();
  const { regions } = useContext(CultureContext) as ICultureContextType;

  let [searchParams, setSearchParams] = useSearchParams();

  const memoBrowseData = useMemo(() => {
    return { searchParams, regions, region, locale };
  }, [searchParams, regions, region, locale]);

  useEffect(() => {
    const filterProducts = async () => {
      try {
        setProducts(null);
        setError(false);
        if (memoBrowseData) {
          const { searchParams, regions, region, locale } = memoBrowseData;
          if (locale) {
            if (region) {
              if (regions) {
                const config = await MyConfig();
                const queryBrand = searchParams.get("brand") || "all";
                const querySort = searchParams.get("sort") || "downloads";
                const queryOrder = searchParams.get("order") || "desc";
                const page = searchParams.get("page") || "1";
                const listFilters = searchParams.getAll("fl[]") || []; //
                const advancedListFilters = searchParams.getAll("fa[]") || [];
                const formatFilters = searchParams.getAll("ff[]") || [];
                const rangeFilters = searchParams.getAll("fr[]") || [];
                const search = searchParams.getAll("search") || [];
                const regionId = GetRegionOid(regions, region);
                const brandIds = await getBrandIds(
                  regionId,
                  locale,
                  queryBrand
                );

                if (name && config) {
                  getProductDetails(name, region, regions, config, locale).then(
                    ({ product, regionId }) => {
                      if (product) {
                        if (!product.message) {
                          updateProductViewCount(
                            product.productId,
                            regionId,
                            locale
                          );

                          return;
                        } else {
                          setTrue();
                        }
                      }
                    }
                  );
                }

                let searchCriterias: any = [];
                forEach(listFilters, (listFilter, key) => {
                  const criteriaDecode = listFilter.split("+");
                  searchCriterias = searchCriterias.concat({
                    checked: true,
                    criteriaId: criteriaDecode[0],
                    criteriavalues: [criteriaDecode[0]],
                    maxRating: 0,
                    minRating: 0,
                  });
                });

                // for group filter
                forEach(advancedListFilters, (advancedList, key) => {
                  const criteriaDecode = advancedList.split("+");
                  const criteriaValuesList: any = [];
                  criteriaValuesList.push(criteriaDecode[1]);
                  searchCriterias = searchCriterias.concat({
                    checked: true,
                    criteriaId: criteriaDecode[0],
                    criteriavalues: criteriaValuesList,
                    maxRating: 0,
                    minRating: 0,
                  });
                });

                let formatCriterias: any = [];
                forEach(formatFilters, (listFilter, key) => {
                  const criteriaDecode = listFilter.split("+");
                  formatCriterias = formatCriterias.concat(criteriaDecode[0]);
                });

                //for range filter
                forEach(rangeFilters, (rangeFilter, key) => {
                  const criteriaDecode = rangeFilter.split("+");
                  searchCriterias = searchCriterias.concat({
                    checked: true,
                    criteriaId: criteriaDecode[0],
                    criteriavalues: [criteriaDecode[0]],
                    maxRating: criteriaDecode[3],
                    minRating: criteriaDecode[2],
                  });
                });

                const onePageDown: number = parseInt(page) - 1;

                let searchdto = {
                  brandIds: brandIds,
                  regionId: regionId,
                  sortBy: querySort.toUpperCase(),
                  format: formatCriterias,
                  sortDirection: queryOrder.toUpperCase(),
                  searchCriterias: searchCriterias,
                  search: search.join("&"),
                  page: onePageDown,
                  locale: locale,
                };

                const hostUrl = `${config.REACT_APP_API_URL}/anonymous/paginated-product-search?externalModule=false&languageId=${locale}&page=${onePageDown}&pageSize=30`;
                let allproducts = await axioPost(
                  hostUrl,
                  JSON.stringify(searchdto),
                  JSON.stringify(searchdto)
                ).catch((err) => {
                  setError(true);
                });

                if (allproducts) {
                  let productsPack = JSON.parse(JSON.stringify(allproducts));
                  if (productsPack.brandList && productsPack.productList) {
                    productsPack.brandList = map(
                      productsPack.brandList,
                      (brList, index) => {
                        return {
                          label: brList.brandName,
                          oid: brList.brandId,
                          code: "Brand",
                          imageId: brList.imageId,
                          thumbnailImage: brList.thumbnailImage,
                          webSiteUrl: brList.webSiteUrl,
                          pitch: brList.pitch,
                          technicalName: brList.technicalName,
                          languageTranslation:brList.languageTranslation
                        };
                      }
                    );
                    if (productsPack.productList.length > 0) {
                      productsPack.productList = map(
                        productsPack.productList,
                        (prList, index) => {
                          return {
                            label: prList.productTitle,
                            oid: prList.productId,
                            code: "Product",
                            name: prList.productName,
                            brandNames: prList.brandNames,
                            imageIds: prList.imageIds,
                          };
                        }
                      );
                    }
                    if (
                      productsPack.criteriaCategoryList &&
                      productsPack.criteriaCategoryList.length > 0
                    ) {
                      updateCriteriaList((draft) => {
                        productsPack.criteriaCategoryList.forEach(
                          (criterias: any) => {
                            const principal = criterias.principal;
                            criterias.criterias.forEach((crit: any) => {
                              if (!draft.hasOwnProperty(crit.criteriaId)) {
                                draft[crit.criteriaId] = { principal };
                              }
                            });
                          }
                        );
                      });
                    }
                  }
                  setProducts(productsPack);
                } else if (allproducts.productList.length === 0) {
                  setError(true);
                }
              }
            }
          }
        }
      } catch (ex) {}
    };
    filterProducts();
  }, [memoBrowseData]);

  const setTrue = () => {
    setError(true);
  };

  const getBrandIds = async (
    regionId: string,
    locale: string,
    selectedBrand: string
  ) => {
    let brandIds = [];
    const listBrand = await ListBrands(regionId, locale);

    if (listBrand) {
      if (selectedBrand === "all") {
        brandIds = map(listBrand, "brandId");
      } else {
        const brandData = find(listBrand, (e: any) => {
          return e.brandName.toLowerCase() === selectedBrand.toLowerCase();
        });
        if (brandData) {
          brandIds = [brandData.brandId];
        }
      }
    }
    return brandIds;
  };
  return (
    <BrowseContext.Provider
      value={{
        products,
        loading: !products && !error,
        error,
        criteriaList,
      }}
    >
      {children}
    </BrowseContext.Provider>
  );
};

export default BrowseProvider;
