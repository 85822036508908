import jwt_decode from "jwt-decode";
import TagManager from "react-gtm-module";

export const extractToken = (url: string) => {
  var pattern = "access_token=";
  if (url.indexOf(pattern) >= 0) {
    //if a pattern is not present in the source string indexOf method returns -1
    let from_token = url.substr(
      url.indexOf(pattern) + pattern.length,
      url.length
    );
    const token = from_token.split("&")[0];
    let decode_token: any = jwt_decode(token);
    const { fullName, email, userId } = decode_token;
    localStorage.setItem("username", fullName);
    // to remove existing emails from local storage
    localStorage.setItem("email", "null");
    localStorage.setItem("userId", userId);
    localStorage.setItem("token", token);
  } else {
    localStorage.setItem("username", "null");
    localStorage.setItem("token", "null");
    localStorage.setItem("email", "null");
    localStorage.setItem("userId", "null");
  }
};

export const extractAuthCode = (url: string): string | null => {
  var pattern = "code=";
  if (url.indexOf(pattern) >= 0) {
    let poundIndex: number | undefined = url.indexOf("#");
    const patIndex = url.indexOf(pattern);
    if (poundIndex === -1 || poundIndex < patIndex) {
      poundIndex = undefined;
    }
    let code = url.substring(patIndex + pattern.length, poundIndex);
    return code;
  }
  return null;
};
