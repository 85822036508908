import { Box, Chip } from "@mui/material";
import { useContext } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { BrowseContext, IBrowseContextType } from "../../Context/browseContext";
import { useRelocate } from "../../relocate";
import _ from "lodash";

interface Props {
  selectedFormat: string[];
  selectedBrand: string;
  deleteFilter: any;
  locale: string;
  region: string;
  category: any;
  selectedSearch: string[];
}

export const FilterTags = ({
  selectedFormat,
  selectedBrand,
  deleteFilter,
  category,
  locale,
  region,
}: Props) => {
  const { relocate } = useRelocate();
  let [searchParams] = useSearchParams();
  const selectedListCriteria = searchParams.getAll("fl[]") || [];
   const selectedAdvancedListCriteria = searchParams.getAll("fa[]") || [];
  const selectedFormatCriteria = searchParams.getAll("ff[]") || [];
  const selectedRangeCriteria = searchParams.getAll("fr[]") || [];
  const selectedSearch = searchParams.getAll("search") || [];

	const { products, criteriaList } = useContext(BrowseContext) as IBrowseContextType;

	const brandFreeSearchParam = () => {
		const params = createSearchParams({
			brand: "all",
			sort: "DOWNLOADS",
		});
		
		// check for principal
    selectedListCriteria
			.filter((crit) => {
				const critId = crit.split("+")[0];

				return (
					criteriaList.hasOwnProperty(critId) && criteriaList[critId].principal
				);
			})
			.forEach((crit) => params.append("fl[]", crit));


    selectedFormatCriteria
			.forEach((crit) => params.append("ff[]", crit));
		return params;
  };
  
  // unique advance filter tags
  const filterAdvancedFilter = _.uniq(selectedAdvancedListCriteria);

  // unique list filter tags
  const filterListFilter = _.uniq(selectedListCriteria);

  // unique list format tags  
  const filterFormatFilter = _.uniq(selectedFormatCriteria);

  return (
    <Box className="filter-list-chips">
         {filterAdvancedFilter &&
        filterAdvancedFilter.map((advancedFilter: string) => {
          const splitedCriteria = advancedFilter.split("+");
          let filterCriteriaId = `${splitedCriteria[1]}`;
          let filterLabel = `${splitedCriteria[2]}`;

          return (
            <Chip
              label={`${filterLabel}`}
              key={advancedFilter}
              onDelete={() => deleteFilter("list", filterCriteriaId, category[0].type)}
              className="filter-chip"
            />
          );
        })}
      
      {filterListFilter &&
        filterListFilter.map((filter: string) => {
          const splitedCriteria = filter.split("+");
          let filterCriteriaId = `${splitedCriteria[0]}`;
          let filterLabel = `${splitedCriteria[1]}`;

          return (
            <Chip
              label={`${filterLabel}`}
              key={filter}
              onDelete={() => deleteFilter("list", filterCriteriaId)}
              className="filter-chip"
              id={`delListChip-${filter}`}
            />
          );
        })}

      {selectedRangeCriteria &&
        selectedRangeCriteria.map((filter: string) => {
          let rangeLabel = `${filter.split("+")[1]}`;
          let rangeValue: string = `${filter.split("+")[2]}-${
            filter.split("+")[3]
          }`;
          if (rangeValue) {
            rangeLabel = `${rangeLabel} (${rangeValue})`;
          }

          return (
            <Chip
              label={`${rangeLabel}`}
              key={filter}
              onDelete={() => deleteFilter("range", filter)}
              className="filter-chip"
              id={`delRangeChip-${filter}`}
            />
          );
        })}
      {filterFormatFilter &&
        filterFormatFilter.map((filter: string) => {
          const splitedCriteria = filter.split("+");
          let filterCriteriaId = `${splitedCriteria[0]}`;
          let filterLabel = `${splitedCriteria[1]}`;

          return (
            <Chip
              label={filterLabel}
              key={filterLabel}
              onDelete={() => deleteFilter("format", filterCriteriaId)}
              className="filter-chip"
              id={`delFormatChip-${filterLabel}`}
            />
          );
        })}
      {selectedBrand && selectedBrand !== "all" && (
        <Chip
          label={selectedBrand}
          key={selectedBrand}
          onDelete={() => {
            relocate({
              pathname: "/browse",
              search: `?${brandFreeSearchParam()}`,
            });
          }}
          className="filter-chip"
          id={`brandChip-${selectedBrand}`}
        />
      )}
      {selectedSearch &&
        selectedSearch.map((searchValue: string) => {
          return (
            <Chip
              label={searchValue}
              key={searchValue}
              onDelete={() => deleteFilter("search", searchValue)}
              className="filter-chip"
              id={`delSearchChip-${searchValue}`}
            />
          );
        })}
    </Box>
  );
};
