
export const MyConfig = (function() {
    var envConfigResult: any;
    return function () {
        if( undefined == envConfigResult) {
          // PIPELINE-POC
          //let url0 = window.location.protocol +"//"+ window.location.hostname + ":"  + window.location.port + "/env/user-ui.env.config.json";
          let url0 = window.location.protocol +"//"+ window.location.hostname + ":"  + window.location.port + "/env.config.json";
         
          envConfigResult =  fetch(url0, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
                return response.json();
          });
        }
        return envConfigResult;
    }
    
      
  })();

export const myConfigSync = () => {
	
	var request = new XMLHttpRequest();
	
  // PIPELINE-POC
  //let url0 = window.location.protocol +"//"+ window.location.hostname + ":"  + window.location.port + "/env/user-ui.env.config.json"
  let url0 = window.location.protocol +"//"+ window.location.hostname + ":"  + window.location.port + "env.config.json"
	request.open('GET', url0, false);  // `false` makes the request synchronous
	request.send(null);
    return JSON.parse(request.responseText);
};