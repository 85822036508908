import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { createSearchParams } from "react-router-dom";
import { useRelocate } from "../../relocate";
import { useBimdatabaseImage } from "../../Utils/hooks/useBimdatabaseImage";
import { Link, useParams } from "react-router-dom";

export const BrandList = (props: any) => {
  const { relocate } = useRelocate();
  const { region, locale } = useParams();
  const imgSrc: any = useBimdatabaseImage(props.imageId, "thumbnail");

  // Add default image when there is no img returing from an API
  const addDefaultSrc = (ev: any) => {
    ev.target.src = "../assets/imgs/dummy-img.png";
  };
  return (
    <ListItem disablePadding key={props.brandkey}>
      <ListItemButton className="chip-brand">
        <ListItemText>
          <Link
            to={`/${locale}/${region}/browse?brand=${props.label}&sort=downloads&order=desc`}
          >
            <img
              src={imgSrc}
              className="img-responsive"
              id="brandLabel" 
              alt={props.label}
              onError={addDefaultSrc}
              loading="lazy"
            />
          </Link>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};
