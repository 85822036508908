import TagManager from "react-gtm-module";
import { MyConfig } from "../API/myConfig";

export const sendDataToGTM = async (DataLayerObj: any) => {
  let config = await MyConfig();
  const tagManagerArgs = {
    gtmId: config.REACT_APP_GTM_CONTAINER_ID,
    dataLayer: DataLayerObj,
  };
  TagManager.initialize(tagManagerArgs);
};

export const initializeGTM = async () => {
  let config = await MyConfig();
  const tagManagerArgs = {
    gtmId: config.REACT_APP_GTM_CONTAINER_ID,
  };
  TagManager.initialize(tagManagerArgs);
};
