import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRelocate } from "../../relocate";

export const Logout = () => {
  let [searchParams] = useSearchParams();
  const { relocate } = useRelocate();

  useEffect(() => {
    if (searchParams.get("logout_redirect")) {
      const redirectTo = decodeURIComponent(searchParams.toLocaleString()).replace("logout_redirect=", "");
      if (redirectTo) {
        const replaceAllat = redirectTo.replaceAll("*", " ");
        const replaAllAsterisk = replaceAllat.replaceAll("%20", " ");
        const finalUrl = replaAllAsterisk.replaceAll("+","%2B")
        relocate(finalUrl, { replace: true });
      } else {
        relocate("/", { replace: true });
      }
    }
  }, [searchParams, relocate]);

  return <></>;
};
