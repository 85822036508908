import "../Resources/profileButton.scss";
import { Avatar, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { LoginIframe } from "../Api/Login.api";

export const Login = ({ config }: any) => {
  const { setToken } = useContext(AuthContext);

  if (config) {
    return (
      <Button
        className="f-w-500 login font-primary"
        id="loginLoad"
        style={{ textTransform: "capitalize", float: "right" }}
        href={"#"}
        target="popup"
        onClick={(e) => {
          e.preventDefault();
          LoginIframe(setToken);
          return false;
        }}
      >
        <Avatar style={{ marginLeft: "5px" }}>
          <PersonIcon />
        </Avatar>
      </Button>
    );
  }

  return <></>;
};
