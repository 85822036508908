import React, { useState, useEffect } from "react";

import "./index.scss";

const FloatLabel = (props: any) => {
    const [focus, setFocus] = useState(false);
    const { children, label, value } = props;

    const labelClass =
        focus || (value && value.length !== 0) ? "label label-float" : "label";

    /// if value changes then set setFocus to false
    // useEffect(() => {
    //     handleBlur();
    // }, [value]);

    const handleBlur = () => {
        setFocus(false)
    }

    return (
        <div
            className="float-label"
            id="focus"
            onBlur={() => handleBlur()}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={labelClass}>{label}</label>
        </div>
    );
};

export default FloatLabel;
