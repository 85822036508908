import { useState, useEffect } from 'react';
import { MyConfig } from '../../API/myConfig';


const Configuration = MyConfig();

export function useBimdatabaseImage(imageId: string, size: string) {

    const [imgUrl, setImgUrl] = useState('../assets/imgs/dummy-img.png');

    useEffect(() => {

        Configuration.then((config: any) => {
            setImgUrl(`${config.REACT_APP_BIMDATABASE_API_URL}/image/${imageId}?filename=temp.jpg&size=${size}`)
        })

    }, [imageId, size])

    return imgUrl;

}