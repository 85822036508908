import { MyConfig } from "./myConfig";

export const sendMessageToContact = async (mailData: string[]) => {
  let config = await MyConfig();
  const hostUrl = config.REACT_APP_API_URL + "/anonymous/message";
  return fetch(hostUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(mailData),
  })
    .then((response) => {
      return response;
    })
    .catch((err: any) => {
      console.log("error is", err);
    });
};
