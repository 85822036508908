import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { SelectTableComponent } from "./selectableTable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

export const DownloadPopup = (props: {
  openDownload: boolean;
  handleCloseDropdown: React.MouseEventHandler<HTMLButtonElement> | undefined;
  productDetails: any;
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.openDownload}
        className="dialog-primary paperWidthLg"
        id="downloadDialogBox"
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          {t("download.downloadbtn")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DialogContentText id="Download-description">
            <SelectTableComponent
              productDetails={props.productDetails}
              handleCloseDropdown={props.handleCloseDropdown}
            />
          </DialogContentText>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
