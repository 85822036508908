import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useRef, useState, useContext } from "react";
import "../Utils/assets/download.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { downloadFilesFromBim } from "../API/download.api";
import { filter, includes, map, some, reject, remove } from "lodash";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { useRelocate } from "../relocate";
import { useTranslation } from "react-i18next";
import { BimlibContext, BimlibContextType } from "../Context/context";
import { Link, useParams } from "react-router-dom";
import { getAllProductDetails } from "../API";
import { Delete, FileDownload } from "@mui/icons-material";
import { Layout } from "./layout";
import { ProcessWithSecure } from "../API/authenticate.api";
import { sendDataToGTM } from "../Utils/gtmConfig";
import { CultureContext, ICultureContextType } from "../Context/cultureContext";
import { encryptUserId } from "../Utils/helper";
import { SendSalesForceData } from "../API/salesforce.api";

export const Download = () => {
  const {
    basket: { products, downloadedFiles },
    removeBasketProduct,
    addDownloadedFiles,
    removeProduct,
    getAllProduct,
    openDB,
    count,
    updateCount,
    getAllDownloadedFiles,
  } = React.useContext(BimlibContext) as BimlibContextType;

  const { locale, region } = useParams();
  const [productsData, setProductsData] = useState<any>([]);
  const [selectedFiles, selectFiles] = useState<string[]>([]);
  const [buttonLoading, setButtonLoading] = useState<string>("");
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<string | null>(null);
  const controllerRef = useRef<AbortController | null>();
  const [productDetail, setProductsDetail] = useState<string[]>([]);
  const [downloadErrorOpen, setDownloadErrorOpen] = useState(false);

  const idb = window.indexedDB;

  const { relocate, login } = useRelocate();
  const { t, i18n } = useTranslation("common");
  const { regions } = useContext(CultureContext) as ICultureContextType;

  useEffect(() => {
    i18n.changeLanguage(locale);

    const loadProductDetails = async () => {
      const db = await openDB();
      const productDetail = await getAllProduct(db);
      if (productDetail && locale) {
        setLoading(true);
        let cartData: any = [];
        if (productDetail.length > 0) {
          let product = filter(productDetail, { region: region });
          let productId: any = map(product, "productId");
          const downloadedFiles = await getAllDownloadedFiles(db);
          const response = await getAllProductDetails(productId, locale);
          if (response) {
            if (response.length > 0) {
              response.forEach((productRow: any) => {
                productRow.fileDescriptors.length > 0
                  ? productRow.fileDescriptors.forEach((fileRow: any) => {
                      if (
                        !(downloadedFiles ?? []).find(
                          (don: any) =>
                            don?.productId === productRow.productId &&
                            don?.fileId === fileRow.fileId &&
                            don?.region === region
                        )
                      ) {
                        cartData.push({
                          fileName: fileRow.fileName,
                          productTitle: productRow.productTitle,
                          fileFormatLabel: fileRow.fileFormatLabel,
                          brandName: productRow.brands[0].label,
                          fileId: fileRow.fileId,
                          productId: productRow.productId,
                        });
                      }
                    })
                  : !(downloadedFiles ?? []).find(
                      (don: any) =>
                        don?.productId === productRow.productId &&
                        don?.fileId === productRow.productId &&
                        don?.region === region
                    ) &&
                    cartData.push({
                      fileName: "---",
                      productTitle: productRow.productTitle,
                      fileFormatLabel: "---",
                      brandName: productRow.brands[0].label,
                      fileId: productRow.productId,
                      productId: productRow.productId,
                    });
              });
            }
          }

          /// check all files of product dowloaded
          const downloadedProducts = products.filter((pr: any) => {
            if (
              cartData.filter((crt: any) => crt.productId === pr).length === 0
            ) {
              return true;
            }
            return false;
          });
          if (downloadedProducts.length > 0) {
            removeBasketProduct(downloadedProducts);
          }
          debugger;
          setProductsData(cartData);
          setLoading(false);
        } else {
          setProductsData([]);
          selectFiles([]);
          setLoading(false);
        }
      }
    };

    loadProductDetails();
  }, [
    locale,
    products,
    downloadedFiles,
    removeBasketProduct,
    i18n,
    productDetail,
    //  allDownloadedFiles,
  ]);

  const toggleCheckbox = (fileId: string) => {
    if (includes(selectedFiles, fileId)) {
      selectFiles((prevFiles) =>
        prevFiles.filter((fid: string) => fid !== fileId)
      );
    } else {
      selectFiles((prevFiles) => [...prevFiles, fileId]);
    }
  };

  const toggleAllCheckbox = () => {
    if (selectedFiles.length === productsData.length) {
      // remove
      selectFiles([]);
    } else {
      // add
      selectFiles(map(productsData, "fileId"));
    }
  };

  // const getAllFiles = (productId: string, counter: any) => {
  //   const dbPromise = idb.open("BimLibrary", 2);
  //   dbPromise.onsuccess = () => {
  //     const db = dbPromise.result;
  //     const transactionFiles = db.transaction("Files", "readonly");
  //     const filesData = transactionFiles.objectStore("Files");
  //     const files = filesData.getAll();
  //     files.onsuccess = async (query: any) => {
  //       query.target?.result.forEach((file: any) => {
  //         if (file.productId === productId && file.fileLength === counter) {
  //           removeFiles(file.productId, region);
  //           removeProduct(db, file.productId, region);
  //         }
  //       });
  //     };
  //     files.onerror = (event) => {
  //       console.log("error occured");
  //     };
  //     transactionFiles.oncomplete = () => {
  //       db.close();
  //     };
  //   };
  // };

  const removeFileFromProductData = (fileId: string) => {
    let productData = [...productsData];
    const filteredIndex = productData.findIndex((r) => r.fileId === fileId);
    productData.splice(filteredIndex, 1);
    setProductsData(productData);
    return productData;
  };

  const refereshSelectedFiles = (fileId: string) => {
    let remainingSelectedFiles = reject(selectedFiles, function (n: any) {
      return n == fileId;
    });
    selectFiles(remainingSelectedFiles);
  };

  const handleDelete = async (productId: string, fileId: string) => {
    debugger;
    const db = await openDB();
    const productData = removeFileFromProductData(fileId);
    refereshSelectedFiles(fileId);
    const productHasFiles = productData.filter(
      (file: any) => file.productId === productId
    ).length;
    if (!productHasFiles) {
      removeProduct(db, [productId], region);
      await updateCount(region);
    }
  };

  const handleDownloadSingleFiles = async (rowData: any) => {
    setButtonLoading(rowData.fileId);
    ProcessWithSecure(
      async () => {
        if (region && locale) {
          const filesTobeDownloaded = { [rowData.productId]: [rowData.fileId] };
          downloadFilesFromBim(
            () => {
              sendDataToGA(rowData);

              if (
                rowData.brandName == "PAM Building" &&
                (window.location.href.indexOf("dev") > -1 ||
                  window.location.href.indexOf("localhost") > -1)
              ) {
                SendSalesForceData([rowData.productId]);
              }
              handleDelete(rowData.productId, rowData.fileId);
              setButtonLoading("");
            },
            errorCallBack,
            region,
            locale,
            filesTobeDownloaded,
            controllerRef,
            regions
          );
        }
      },
      () => {
        login(window.location);
      }
    );
  };

  const errorCallBack = () => {
    console.log("called callback");
    setDownloadErrorOpen(true);
    setDownloadLoading(false);
    setButtonLoading("");
  };

  const handleDownloadZipFiles = async () => {
    setDownloadLoading(true);
    ProcessWithSecure(
      async () => {
        if (region && locale) {
          const selectedData: any = filter(productsData, (crt) => {
            return includes(selectedFiles, crt.fileId);
          });

          const groupByProducts = selectedData.reduce(function (
            rv: any,
            x: any
          ) {
            (rv[x.productId] = rv[x.productId] || []).push(x.fileId);
            return rv;
          },
          {});

          downloadFilesFromBim(
            async () => {
              let sfData: string[] = [];
              selectedData.forEach((sl: any) => {
                sendDataToGA(sl);
                if (
                  sl.brandName == "PAM Building" &&
                  !sfData.includes(sl.productId)
                ) {
                  sfData.push(sl.productId);
                }
              });

              if (some(selectedData, { brandName: "PAM Building" })) {
                //SendSalesForceData(sfData);
              }

              const produdctsData = [...productsData];
              debugger;

              /// filter produdctsData based on productId and array of productId from selectedData
              const productIds = selectedData.map((sl: any) => sl.productId);
              const notDownloadedProducts = produdctsData.filter(
                (prd: any) => !productIds.includes(prd.productId)
              );

              setProductsData(notDownloadedProducts);
              const db = await openDB();
              // remove selected files from cart
              await removeProduct(db, productIds, region);
              await updateCount(region);
              // handleDelete(
              //   selectedData.map((sl: any) => {
              //     return { productId: sl.productId };
              //   }),
              //   selectedData.map((s2: any) => {
              //     return { fileId: s2.fileId };
              //   })
              // );
              selectFiles([]);
              setDownloadLoading(false);
            },
            errorCallBack,
            region,
            locale,
            groupByProducts,
            controllerRef,
            regions
          );
        }
      },
      () => {
        login(window.location);
      }
    );
  };

  const handleClose = () => {
    setDownloadErrorOpen(false);
  };

  const sendDataToGA = async (data: any) => {
    const email = localStorage.getItem("email");
    let userId = encryptUserId(email);

    sendDataToGTM({
      event: "fileDownload",
      product_id: data.productId,
      product_name: data.productTitle,
      file_id: data.fileId,
      file_name: data.fileName,
      file_format: data.fileFormatLabel,
      brand: data.brandName,
      region: region,
      locale: locale,
      user_id: localStorage.getItem("enableGA") === "true" ? userId : "NA",
    });
  };

  if (regions) {
    return (
      <>
        <div>
          <Snackbar
            open={downloadErrorOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
              id="sessionCloseAlert"
            >
              {t("download.download_failed")}
              <Link
                className="font-size-16 color-secondary"
                to={`/${locale}/${region}/contactus`}
                target="_blank"
              >
                {t("download.contact_us")}
              </Link>
            </Alert>
          </Snackbar>
        </div>
        <Box className="main-container download-container">
          <div
            className="space-from-top btn-goback-container"
            style={{ display: "inline-flex" }}
          >
            <Button
              variant="contained"
              className="btn btn-primary btn-normal btn-goback"
              id="downloadGoBack"
              onClick={() => relocate(-1)}
            >
              {t("common.goback_btn")} <ArrowBackIosNewIcon />
            </Button>
          </div>
          <Typography
            variant="h2"
            className="text-left section-heading"
            style={{ padding: "0 0 10px 0" }}
          >
            {t("header.downloads")}
          </Typography>
          {/* <Typography className="text-left color-secondary f-s-italic">
          {t("download.selected_files")} ({selectedFiles.length})
        </Typography> */}
          <TableContainer component={Paper} className="cart-table">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="cart table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    padding="checkbox"
                    className="width-5 thead-checkbox"
                  >
                    <Checkbox
                      indeterminate={
                        selectedFiles.length > 0 &&
                        selectedFiles.length < productsData.length
                      }
                      checked={
                        selectedFiles.length === productsData.length &&
                        selectedFiles.length > 0 &&
                        productsData.length > 0
                      }
                      onChange={() => toggleAllCheckbox()}
                      id="parentCheckbox"
                    />
                  </TableCell>
                  <TableCell align="left" className="width-25">
                    {t("download.product")}
                  </TableCell>
                  <TableCell align="left" className="width-40">
                    {t("download.file_name")}
                  </TableCell>
                  <TableCell align="left" className="width-10">
                    {t("download.brand")}
                  </TableCell>
                  <TableCell align="left" className="width-10">
                    {t("download.format")}
                  </TableCell>
                  <TableCell align="left" className="width-10">
                    {t("download.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {productsData.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      <Typography>
                        {" "}
                        {t("download.noproduct_addedcart")}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {productsData.length > 0 &&
                    productsData.map((prdData: any) => {
                      return (
                        <TableRow
                          key={prdData.fileId}
                          selected={selectedFiles.includes(prdData.fileId)}
                          role="checkbox"
                          hover
                        >
                          <TableCell
                            align="center"
                            padding="checkbox"
                            className="table-checkbox"
                          >
                            <Checkbox
                              checked={selectedFiles.includes(prdData.fileId)}
                              onChange={() => toggleCheckbox(prdData.fileId)}
                              id={`childCheckbox-${prdData.fileId}`}
                            />
                          </TableCell>
                          <Tooltip
                            title={prdData.productTitle}
                            placement="top"
                            arrow
                          >
                            <TableCell align="left">
                              {prdData.productTitle}
                            </TableCell>
                          </Tooltip>
                          <Tooltip
                            title={prdData.fileName}
                            placement="top"
                            arrow
                          >
                            <TableCell
                              align="left"
                              className="download-product-file"
                            >
                              {prdData.fileName}
                            </TableCell>
                          </Tooltip>
                          <TableCell align="left">
                            {prdData.brandName}
                          </TableCell>
                          <TableCell align="left">
                            {prdData.fileFormatLabel}
                          </TableCell>
                          <TableCell align="left">
                            {buttonLoading === prdData.fileId ? (
                              <IconButton
                                onClick={(e) =>
                                  handleDownloadSingleFiles(prdData)
                                }
                              >
                                <LoadingButton
                                  loading
                                  style={{ minWidth: "auto" }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                id="downBtn"
                                onClick={(e) =>
                                  handleDownloadSingleFiles(prdData)
                                }
                              >
                                <FileDownload className="cart-fd-icon" />
                              </IconButton>
                            )}

                            <IconButton
                              className={
                                loadingButton !== null
                                  ? "disabled-delete-btn"
                                  : ""
                              }
                              id="delproduct"
                              onClick={(event) => {
                                handleDelete(prdData.productId, prdData.fileId);

                                sendDataToGTM({
                                  event: "remove_from_cart",
                                  product_id: prdData.productId,
                                  product_name: prdData.productTitle,
                                  file_name: prdData.fileName,
                                  file_format: prdData.fileFormatLabel,
                                  brand: prdData.brandName,
                                  region: region,
                                  locale: locale,
                                });
                              }}
                              disabled={loadingButton !== null}
                            >
                              {loadingButton === prdData.fileId ? (
                                <LoadingButton
                                  loading
                                  style={{ minWidth: "auto" }}
                                />
                              ) : (
                                <Delete className="cart-delete-icon" />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box style={{ justifyContent: "center", display: "flex" }}>
            <LoadingButton
              variant="contained"
              className="btn btn-primary btn-normal m-t-20"
              id="downloadZip"
              loading={downloadLoading}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              disabled={selectedFiles.length === 0}
              onClick={() => handleDownloadZipFiles()}
              style={{ marginRight: "10px" }}
            >
              {t("download.downloadbtn")}
            </LoadingButton>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <div>
        <Snackbar
          open={downloadErrorOpen}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            id="sessionCloseAlert"
          >
            {t("download.download_failed")}
            <Link
              className="font-size-16 color-secondary"
              to={`/${locale}/${region}/contactus`}
              target="_blank"
            >
              {t("download.contact_us")}
            </Link>
          </Alert>
        </Snackbar>
      </div>
      <Box className="main-container sitemap-container">
        <div style={{ width: "0px", margin: "50px auto" }}>
          <CircularProgress style={{ margin: "0px auto" }} />
        </div>
      </Box>
    </>
  );
};
