import React, { useEffect, useState } from "react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  Box,
} from "@mui/material";
import "../../Utils/assets/marketRegionPopup.scss";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from '@mui/icons-material/Search';
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { t } from "../../Utils/helper";
import { FlagMenu } from "../Header/languageMenu";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

import {
  ILanguageContextType,
  LanguageContext,
} from "../../Context/languageContext";
import { ConfigContext, IConfigContextType } from "../../Context/configContext";

export interface MarketRegionProps {
  openRegionPopup: boolean;
  closeRegionPopup: any;
}

export const MarketRegionPopup = ({
  openRegionPopup,
  closeRegionPopup,
}: MarketRegionProps) => {
  const cookies = new Cookies();
  const [showButtons, setShowButtons] = React.useState(false);
  const [marketRegion, setMarketRegion] = useState<any>("");
  const [locale, setLocale] = useState<string>("en");
  const {
    regions,
    languages,
    cultureLoading,
    loadRegions,
    config,
    loadLanguages,
  } = React.useContext(CultureContext) as ICultureContextType;

  useEffect(() => {
    let lang = cookies.get("language");
    setLocale(lang);
    loadLanguages("en");
  }, []);

  const handleChange = (event: any) => {
    if (regions) {
      const regionAvailable = regions.find(
        (reg: any) =>
          reg.label.toLowerCase() === event.target.value.toLowerCase()
      );
      if (regionAvailable) {
        let date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 100);
        cookies.set("regionname", regionAvailable.label, {
          path: "/",
          expires: date,
        });
        setMarketRegion(regionAvailable.label);
      }
      setShowButtons(true); // to show browse and home buttons
    }
  };

  const handleLangChange = (url: string, languagecode: string) => {
    setLocale(languagecode);
    loadLanguages(languagecode);
    loadRegions(languagecode);
  };

  if (!openRegionPopup || cultureLoading) {
    return <></>;
  }

  if (regions) {
    return (
      <>
        <Dialog
          open={openRegionPopup}
          className="roundedPopup marketRegionPopup"
          id="regionPop"
        >
          <DialogTitle>
            {t("location_detection.title", locale)}
            <Typography className="lang-container">
              {/* <label>Choose a Language:</label> */}
              <FlagMenu
                languages={languages}
                locale={locale}
                changeLanguage={handleLangChange}
                config={config}
                region={""}
              />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <Select
                displayEmpty
                onChange={handleChange}
                IconComponent={() => <ArrowDropDownCircleIcon />}
                className="selectbox"
                id="regionSelect"
                placeholder={t(
                  "location_detection.choose_region_popupbtn",
                  locale
                )}
                value={marketRegion}
              >
                <MenuItem disabled value="">
                  {t("location_detection.choose_region_popupbtn", locale)}
                </MenuItem>
                {regions.map((r1: any) => (
                  <MenuItem key={r1.label} value={r1.label}>
                    <Typography variant="body1">{r1.translation}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showButtons ? (
              <Box className="browse-home-btn-container">
                <Button
                  className="btn btn-primary btn-small m-l-10"
                  id="closeRegionBrowseBtn"
                  onClick={() => {
                    closeRegionPopup(
                      "/browse?brand=all&sort=downloads&order=desc"
                    );
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button
                  className="btn btn-primary btn-small m-l-10"
                  id="closeRegionHomeBtn"
                  onClick={() => {
                    closeRegionPopup("/");
                  }}
                >
                <HomeIcon />
                </Button>
              </Box>
            ) : null}
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return <></>;
};
